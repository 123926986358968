import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const EditNotificationModal = ({
  isOpen,
  toggleModal,
  notificationToEdit,
  changeNotificationToEditProp,
  reloadData,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [scenarios, setScenarios] = useState([])
  const [alternatives, setAlternatives] = useState([])
  const [notificationRoles, setNotificationRoles] = useState([])
  const [notificationFields, setNotificationFields] = useState([])
  const [notificationTriggers, setNotificationTriggers] = useState([])
  const [formErrorFields, setFormErrorFields] = useState([])

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let nr = await api.getList(currentUser.userID, "NotificationRole")
    setNotificationRoles(nr)
    await loadScenarios()
    await loadAlternatives(notificationToEdit.scenarioID)
    await loadNotificationFields(
      notificationToEdit.scenarioID,
      notificationToEdit.item
    )
    await loadNotificationTriggers(
      notificationToEdit.scenarioID,
      notificationToEdit.fieldName
    )
    setIsLoading(false)
  }

  const loadScenarios = async () => {
    let s = await api.getScenariosByUser(currentUser.userID, 0)
    setScenarios(s)
  }

  const loadAlternatives = async scenarioId => {
    if (scenarioId == 0) {
      setAlternatives([])
    } else {
      let a = await api.getAlternatives(currentUser.userID, scenarioId)
      setAlternatives(a)
    }
  }

  const loadNotificationFields = async (scenarioId, item) => {
    let nf = await api.getNotificationRuleFields(
      scenarioId,
      currentUser.userID,
      item
    )
    setNotificationFields(nf)
  }

  const loadNotificationTriggers = async (scenarioId, fieldName) => {
    let nt = await api.getNotificationTriggers(scenarioId, fieldName)
    setNotificationTriggers(nt)
  }

  const save = async () => {
    
    if (
      notificationToEdit.fieldName == undefined ||
      notificationToEdit.fieldName == null ||
      notificationToEdit.fieldName == "0"
    ) {
      setFormErrorFields(["fieldName"])
    } else {
      setFormErrorFields([])
      if (notificationToEdit.notificationRuleID > -1) {
        await api.updateNotificationRule(currentUser.userID, notificationToEdit)
      } else {
        await api.createNotificationRule(currentUser.userID, notificationToEdit)
      }
      toast.success("Notification Rule saved successfuly")
      toggleModal()
      reloadData()
    }
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit Notification
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {!isLoading && notificationToEdit != null && (
          <>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Notify me</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={notificationToEdit.delivery}
                    onChange={e => {
                      changeNotificationToEditProp("delivery", e.target.value)
                    }}
                  >
                    <option value="Immediately">Immediately</option>
                    <option value="Nightly">Nightly</option>
                  </select>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">about</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={notificationToEdit.item}
                    onChange={e => {
                      changeNotificationToEditProp("item", e.target.value)
                      loadNotificationFields(
                        notificationToEdit.scenarioID,
                        e.target.value
                      )
                    }}
                  >
                    <option value="Action Item">Action Item</option>
                    <option value="Alternative">Alternative</option>
                    <option value="Change Request">Change Request</option>
                    <option value="Issue">Issue</option>
                    <option value="Project Milestone">Project Milestone</option>
                    <option value="Project Task">Project Task</option>
                    <option value="Resource">Resource</option>
                    <option value="Risk">Risk</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label">for decision</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={notificationToEdit.scenarioID}
                    onChange={e => {
                      changeNotificationToEditProp("scenarioID", e.target.value)
                      loadAlternatives(e.target.value)
                      loadNotificationFields(
                        e.target.value,
                        notificationToEdit.item
                      )
                      loadNotificationTriggers(
                        e.target.value,
                        notificationToEdit.fieldName
                      )
                    }}
                  >
                    <option value="0">All Decisions</option>
                    {scenarios.map((s, i) => (
                      <option key={i} value={s.scenarioID}>
                        {s.scenarioName}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label">specifically</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={notificationToEdit.alternativeID}
                    onChange={e => {
                      changeNotificationToEditProp(
                        "alternativeID",
                        e.target.value
                      )
                    }}
                  >
                    <option value="0">Any Alternative</option>
                    {alternatives.map((s, i) => (
                      <option key={i} value={s.alternativeID}>
                        {s.alternativeNum}-{s.alternativeName}
                        {s.inRevision && <span> -Baseline</span>}
                        {s.revisionAltID > 0 && <span> -Revision</span>}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label">where my role is</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={notificationToEdit.triggerRole}
                    onChange={e => {
                      changeNotificationToEditProp(
                        "triggerRole",
                        e.target.value
                      )
                    }}
                  >
                    {notificationRoles.map((s, i) => (
                      <option key={i} value={s.listItemName}>
                        {s.listItemName}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">and</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={notificationToEdit.fieldName}
                    onChange={e => {
                      changeNotificationToEditProp("fieldName", e.target.value)
                      loadNotificationTriggers(
                        notificationToEdit.scenarioID,
                        e.target.value
                      )
                    }}
                  >
                    <option value="0">Select...</option>
                    {notificationFields.map((s, i) => (
                      <option key={i} value={s.listItemName}>
                        {s.listItemName}
                      </option>
                    ))}
                  </select>
                  {formErrorFields.find(x => x == "fieldName") !==
                    undefined && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Field name is required
                    </div>
                  )}
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">changes to</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={notificationToEdit.triggerType}
                    onChange={e => {
                      changeNotificationToEditProp(
                        "triggerType",
                        e.target.value
                      )
                    }}
                  >
                    {notificationTriggers.map((s, i) => (
                      <option key={i} value={s.listItemName}>
                        {s.listItemName}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  className="mb-3"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Input
                    type="checkbox"
                    id="inactive"
                    checked={notificationToEdit.inactive}
                    onClick={() => {
                      changeNotificationToEditProp(
                        "inactive",
                        !notificationToEdit.inactive
                      )
                    }}
                  />
                  <Label style={{ marginLeft: "10px" }} for="inactive">
                    Inactive
                  </Label>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default EditNotificationModal
