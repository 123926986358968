import React, { useEffect, useState } from "react"
import { Col, Label, Row } from "reactstrap"
import api from "services/api.service"
import anychart from "anychart"
import Widgets from "components/Widgets.js"

const ResultSection = ({ activeTab, alternativeId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [summary, setSummary] = useState(null)
  const [costBenefitChartData, setCostBenefitChartData] = useState(null)
  const [costChartData, setCostChartData] = useState([])
  const [benefitChartData, setBenefitChartData] = useState([])
  const [returnChartData, setReturnChartData] = useState([])
  const [paybackChartData, setPaybackChartData] = useState([])
  const [widgets, setWidgets] = useState([])

  useEffect(() => {
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
  }, [])

  useEffect(() => {
    if (activeTab == "Results") {
      loadData()
    }
  }, [activeTab, alternativeId])

  const loadData = async () => {
    let sum = await api.getBCSummary(alternativeId)
    setSummary(sum)

    let w = await api.getWidgetsByView("ResultSection", alternativeId)
    setWidgets(w)

    await loadCostBenefitChart()
    await loadCostChart()
    await loadBenefitChart()
    await loadReturnChart()
    await loadPaybackChart()
  }

  const loadCostBenefitChart = async () => {
    let data = await api.getAlternativeChart(alternativeId, "BC_CostBenefit")
    document.getElementById("costbenefitchart").innerHTML = ""
    var chart = anychart.column()

    let dataArray = JSON.parse(data.chartArray.replaceAll("'", '"'))
    let chartData = anychart.data.set(dataArray)
    chart.background().stroke(".5 gray")
    chart.animation(true)
    chart.interactivity().hoverMode("single")

    var seriesData_1 = chartData.mapAs({ x: 0, value: 1, fill: 4 })
    var seriesData_2 = chartData.mapAs({ x: 0, value: 2, fill: 5 })
    var seriesData_3 = chartData.mapAs({ x: 0, value: 3, fill: 6 })

    var series1 = chart.column(seriesData_1)
    var series2 = chart.column(seriesData_2)
    var series3 = chart.column(seriesData_3)

    series1.name("Total Cost")
    series2.name("Total Benefit")
    series3.name("Return")

    series1.normal().fill("#006600", 1.0)
    series2.normal().fill("#000066", 1.0)
    series3.normal().fill("#ffffcc", 1.0)

    series1.normal().stroke("#1b1c1b", 0.3)
    series2.normal().stroke("#1b1c1b", 0.3)
    series3.normal().stroke("#1b1c1b", 0.3)

    chart.title({
      text: "Cost vs. Benefit",
      fontWeight: "bold",
      padding: "5px",
    })

    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return "$" + anychart.format.number(this.value, 2, ".", ",")
    })

    var clabels = chart.labels()
    clabels.format(function () {
      return "$" + anychart.format.number(this.value, 0, ".", ",")
    })

    chart.xAxis().stroke(null)
    chart.xAxis(1).stroke(null).orientation("top")
    chart.yAxis().title("")

    var zeroMarker = chart.lineMarker(0)
    zeroMarker.stroke("#ddd")
    zeroMarker.scale(chart.yScale())
    zeroMarker.value(0)

    chart.labels(true)
    chart
      .tooltip()
      .title(false)
      .separator(false)
      .format(function () {
        return "$" + anychart.format.number(this.value, 0, ".", ",")
      })
    chart.legend().enabled(true).padding([0, 20, 20, 0])
    chart.xGrid().stroke("#ddd").drawLastLine(false)
    chart.barsPadding(0.1).barGroupsPadding(0.9)
    chart.container("costbenefitchart")
    chart.credits().enabled(false)

    let labels = chart.getSeries(0).labels()
    labels.enabled(true)
    labels.position("center-top")

    let labels1 = chart.getSeries(1).labels()
    labels1.enabled(true)
    labels1.position("center-top")

    let labels2 = chart.getSeries(2).labels()
    labels2.enabled(true)
    labels2.position("center-top")

    yLabels.fontFamily("Inter")
    clabels.fontFamily("Inter")
    chart.title().fontFamily("Inter")
    labels.fontFamily("Inter")
    labels1.fontFamily("Inter")
    labels2.fontFamily("Inter")
    chart.legend().fontFamily("Inter")

    chart.draw()
  }

  const loadCostChart = async () => {
    let data = await api.getAlternativeChart(alternativeId, "BC_Cost")
    document.getElementById("costchart").innerHTML = ""

    let dataArray = JSON.parse(data.chartArray.replaceAll("'", '"'))
    let chartData = anychart.data.set(dataArray)
    var chart = anychart.column()

    chart.background().stroke(".5 gray")
    chart.animation(true)
    chart.interactivity().hoverMode("single")

    var seriesData_1 = chartData.mapAs({ x: 0, value: 1, fill: 4 })
    var seriesData_2 = chartData.mapAs({ x: 0, value: 2, fill: 5 })
    var seriesData_3 = chartData.mapAs({ x: 0, value: 3, fill: 6 })

    var series1 = chart.column(seriesData_1)
    var series2 = chart.column(seriesData_2)
    var series3 = chart.column(seriesData_3)

    series1.name("Total Post-Project")
    series2.name("Total Project")
    series3.name("Total Pre-Project")

    series1.normal().fill("#ccffcc", 1.0)
    series2.normal().fill("#339966", 1.0)
    series3.normal().fill("#006600", 1.0)

    series1.normal().stroke("#1b1c1b", 0.3)
    series2.normal().stroke("#1b1c1b", 0.3)
    series3.normal().stroke("#1b1c1b", 0.3)

    chart.title({ text: "Cost $", fontWeight: "bold", padding: "5px" })
    chart.yScale().stackMode("value")

    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return "$" + anychart.format.number(this.value, 2, ".", ",")
    })

    var clabels = chart.labels()
    clabels.format(function () {
      return "$" + anychart.format.number(this.value, 0, ".", ",")
    })

    chart.xAxis().stroke(null)
    chart.xAxis(1).stroke(null).orientation("top")
    chart.yAxis().title("")

    var zeroMarker = chart.lineMarker(0)
    zeroMarker.stroke("#ddd")
    zeroMarker.scale(chart.yScale())
    zeroMarker.value(0)

    chart.labels(true)
    chart
      .tooltip()
      .title(false)
      .separator(false)
      .format(function () {
        return "$" + anychart.format.number(this.value, 0, ".", ",")
      })
    chart.legend().enabled(true)
    chart.xGrid().stroke("#ddd").drawLastLine(false)
    chart.barsPadding(0.1).barGroupsPadding(0.9)
    chart.container("costchart")
    chart.credits().enabled(false)

    yLabels.fontFamily("Inter")
    clabels.fontFamily("Inter")
    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")

    chart.draw()
  }

  const loadBenefitChart = async () => {
    let data = await api.getAlternativeChart(alternativeId, "BC_Benefit")
    document.getElementById("benefitchart").innerHTML = ""

    let dataArray = JSON.parse(data.chartArray.replaceAll("'", '"'))
    let chartData = anychart.data.set(dataArray)
    var chart = anychart.column()

    chart.background().stroke(".5 gray")
    chart.animation(true)
    chart.interactivity().hoverMode("single")

    var seriesData_1 = chartData.mapAs({ x: 0, value: 1, fill: 4 })
    var seriesData_2 = chartData.mapAs({ x: 0, value: 2, fill: 5 })
    var seriesData_3 = chartData.mapAs({ x: 0, value: 3, fill: 6 })

    var series1 = chart.column(seriesData_1)
    var series2 = chart.column(seriesData_2)
    var series3 = chart.column(seriesData_3)

    series1.name("Total Cost Avoidance")
    series2.name("Total Cost Reduction")
    series3.name("Total Revenue")

    series1.normal().fill("#ccecff", 1.0)
    series2.normal().fill("#0066cc", 1.0)
    series3.normal().fill("#000066", 1.0)

    series1.normal().stroke("#1b1c1b", 0.3)
    series2.normal().stroke("#1b1c1b", 0.3)
    series3.normal().stroke("#1b1c1b", 0.3)

    chart.title({ text: "Benefit $", fontWeight: "bold", padding: "5px" })
    chart.yScale().stackMode("value")
    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return "$" + anychart.format.number(this.value, 2, ".", ",")
    })

    var clabels = chart.labels()
    clabels.format(function () {
      return "$" + anychart.format.number(this.value, 0, ".", ",")
    })

    chart.xAxis().stroke(null)
    chart.xAxis(1).stroke(null).orientation("top")
    chart.yAxis().title("")

    var zeroMarker = chart.lineMarker(0)
    zeroMarker.stroke("#ddd")
    zeroMarker.scale(chart.yScale())
    zeroMarker.value(0)

    chart.labels(true)
    chart
      .tooltip()
      .title(false)
      .separator(false)
      .format(function () {
        return "$" + anychart.format.number(this.value, 0, ".", ",")
      })
    chart.legend().enabled(true)
    chart.xGrid().stroke("#ddd").drawLastLine(false)
    chart.barsPadding(0.1).barGroupsPadding(0.9)
    chart.container("benefitchart")
    chart.credits().enabled(false)

    yLabels.fontFamily("Inter")
    clabels.fontFamily("Inter")
    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")

    chart.draw()
  }

  const loadReturnChart = async () => {
    let data = await api.getAlternativeChart(alternativeId, "BC_Return")
    document.getElementById("returnchart").innerHTML = ""
    let dataArray = JSON.parse(data.chartArray.replaceAll("'", '"'))
    let chartData = anychart.data.set(dataArray)
    // create column chart
    var chart = anychart.column()

    // set chart data
    //chart.data(chartData)
    chart.background().stroke(".5 gray")
    chart.animation(true)
    chart.interactivity().hoverMode("single")

    var seriesData_1 = chartData.mapAs({ x: 0, value: 1 })
    var series1 = chart.column(seriesData_1)
    series1.name("Return")
    series1.normal().fill("#FFFFCC", 1.0)
    series1.normal().stroke("#1b1c1b", 0.3)
    chart.title({ text: "Return $", fontWeight: "bold", padding: "5px" })
    chart.yScale().stackMode("value")
    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return "$" + anychart.format.number(this.value, 2, ".", ",")
    })

    var clabels = chart.labels()
    clabels.format(function () {
      return "$" + anychart.format.number(this.value, 0, ".", ",")
    })
    chart.xAxis().stroke(null)
    chart.xAxis(1).stroke(null).orientation("top")
    chart.yAxis().title("")
    var zeroMarker = chart.lineMarker(0)
    zeroMarker.stroke("#ddd")
    zeroMarker.scale(chart.yScale())
    zeroMarker.value(0)

    chart.labels(true)
    chart
      .tooltip()
      .title(false)
      .separator(false)
      .format(function () {
        return "$" + anychart.format.number(this.value, 0, ".", ",")
      })
    chart.legend().enabled(true)
    chart.xGrid().stroke("#ddd").drawLastLine(false)
    chart.barsPadding(0.1).barGroupsPadding(0.9)
    chart.container("returnchart")
    chart.credits().enabled(false)

    yLabels.fontFamily("Inter")
    clabels.fontFamily("Inter")
    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")

    chart.draw()
  }

  const loadPaybackChart = async () => {
    let data = await api.getAlternativeChart(alternativeId, "BC_Payback")
    document.getElementById("paybackchart").innerHTML = ""
    let dataArray = JSON.parse(data.chartArray.replaceAll("'", '"'))
    let dataSet = anychart.data.set(dataArray)

    var seriesData_1 = dataSet.mapAs({ x: 0, value: 1 })
    var chart = anychart.line()
    chart.background().stroke(".5 gray")
    // turn on chart animation
    chart.animation(true)

    // set chart padding
    chart.padding([10, 20, 5, 20])

    // turn on the crosshair
    chart.crosshair().enabled(true).yLabel(false).yStroke(null)

    // set tooltip mode to point
    chart.tooltip().positionMode("point")
    chart.title({
      text: "Payback Period (in Years)",
      fontWeight: "bold",
      padding: "5px",
    })
    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return "$" + anychart.format.number(this.value, 2, ".", ",")
    })

    var clabels = chart.labels()
    clabels.format(function () {
      return "$" + anychart.format.number(this.value, 0, ".", ",")
    })

    // create first series with mapped data
    var series_1 = chart.line(seriesData_1)
    series_1.name("Payback")
    series_1.stroke("5 red")
    series_1.hovered().markers().enabled(true).type("circle").size(4)
    series_1
      .tooltip()
      .position("right")
      .anchor("left-center")
      .offsetX(5)
      .offsetY(5)

    series_1.tooltip().format(function () {
      return "$" + anychart.format.number(this.value, 0, ".", ",")
    })
    chart.yGrid().enabled(true)
    chart.legend().enabled(true)
    chart.container("paybackchart")
    chart.credits().enabled(false)

    yLabels.fontFamily("Inter")
    clabels.fontFamily("Inter")
    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")

    chart.draw()
  }

  let stage = anychart.graphics.create()
  let chart1 = anychart.line([1, 2, 3])
  chart1.bounds(0, 0, "100%", "50%")
  let chart2 = anychart.column()
  chart2.column([3, 2, 1])
  chart2.line([3, 5, 6])
  chart2.bounds(0, "50%", "100%", "50%")

  return (
    <React.Fragment>
      <Widgets widgets={widgets} />

      <Row style={{ marginTop: "20px" }}>
        <Col>
          <div id="costbenefitchart" style={{ height: "500px" }} />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div id="costchart" style={{ height: "500px" }} />
        </Col>
        <Col md="6">
          <div id="benefitchart" style={{ height: "500px" }} />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <div id="returnchart" style={{ height: "500px" }} />
        </Col>
        <Col md="6">
          <div id="paybackchart" style={{ height: "500px" }} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ResultSection
