import React from "react"
import { createRoot } from "react-dom/client"
import { ReactTabulator } from "react-tabulator"

const FormFieldTable = ({ formFields, editFormField, updateFormFields }) => {
  const deleteFormField = formField => {
    let copy = [...formFields]
    let existingFormField = copy.find(
      x => x.formFieldId == formField.formFieldId
    )
    copy.splice(copy.indexOf(existingFormField), 1)
    updateFormFields(copy)
  }

  const columns = [
    {
      rowHandle: true,
      formatter: "handle",
      headerSort: false,
      frozen: true,
      width: 50,
    },
    {
      title: "Control Label",
      field: "formFieldLabel",
      headerSort: false,
      formatter: (cell, formatterParams, onRendered) => {
        const renderFn = () => {
          const val = cell.getValue()
          const cellEl = cell.getElement()
          if (cellEl) {
            const formatterCell = cellEl.querySelector(".formatterCell")
            if (formatterCell) {
              const CompWithMoreProps = React.cloneElement(
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    editFormField(cell.getRow().getData())
                  }}
                >
                  {val}
                </a>,
                { cell }
              )
              createRoot(cellEl.querySelector(".formatterCell")).render(
                CompWithMoreProps
              )
            }
          }
        }

        onRendered(renderFn) // initial render only.

        setTimeout(() => {
          renderFn() // render every time cell value changed.
        }, 0)
        return '<div class="formatterCell"></div>'
      },
    },
    {
      title: "Type",
      field: "formFieldType.type",
      headerSort: false,
    },
    {
      title: "Order",
      field: "orderId",
      headerSort: false,
    },
    {
      field: "",
      headerSort: false,
      width: 50,
      formatter: (cell, formatterParams, onRendered) => {
        const renderFn = () => {
          const cellEl = cell.getElement()
          if (cellEl) {
            const formatterCell = cellEl.querySelector(".formatterCell")
            if (formatterCell) {
              const CompWithMoreProps = React.cloneElement(
                <i
                  className="fas fa-trash-alt"
                  style={{ color: "red" }}
                  onClick={() => deleteFormField(cell.getRow().getData())}
                ></i>,
                { cell }
              )
              createRoot(cellEl.querySelector(".formatterCell")).render(
                CompWithMoreProps
              )
            }
          }
        }

        onRendered(renderFn)

        setTimeout(() => {
          renderFn()
        }, 0)
        return '<div class="formatterCell"></div>'
      },
    },
  ]

  return (
    <>
      {formFields.length > 0 && (
        <ReactTabulator
          //   onRef={r => (tabulatorRef.current = r.current)}
          columns={columns}
          data={formFields}
          options={{
            movableRows: true,
            layout: "fitColumns",
            cssClass: "table-borderless",
          }}
          events={{
            rowMoved: function (row) {
              let list = row.getTable().getData()
              list = list.map((l, idx) => {
                l.orderId = idx + 1
                return l
              })
              updateFormFields(list)
            },
          }}
        />
      )}
    </>
  )
}

export default FormFieldTable
