import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import EditUserChartModal from "./EditUserChartModal"

const UserCharts = props => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [charts, setCharts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [chartToEdit, setChartToEdit] = useState(null)

  const [showEditModal, setShowEditModal] = useState(false)
  const toggleEditModal = () => setShowEditModal(!showEditModal)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "UserCharts",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "UserCharts",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    await loadCharts()
    setIsLoading(false)
  }

  const loadCharts = async () => {
    let ch = await api.getUserCharts(currentUser.userID)
    setCharts(ch)
  }

  const createChart = () => {
    setChartToEdit({
      userID: currentUser.userID,
      chartTitle: "",
      alternativeLogic: "BB*",
      // valueItem: "Cost",
      userChartID: -1,
      scenarioID: 0,
      publicChart: false,
      inactive: false,
      description: "",
      // chartType: "Pie",
      chartName: "",
      chartFilters: [],
      chartTable: [],
      generalChartScope: "Scenario",
      baseColor: colors.primary,
    })
    toggleEditModal()
  }

  const editChart = async chartId => {
    await loadChart(chartId)
    toggleEditModal()
  }

  const loadChart = async chartId => {
    let ch = await api.getUserChart(chartId)
    setChartToEdit(ch)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col>
                  <button className="btn btn-primary" onClick={createChart}>
                    <i className="fas fa-plus"></i> Create Chart
                  </button>
                </Col>
              </Row>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <table
                  className="table table-bordered low-padding-table"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>Chart</th>
                      <th style={{ width: "20%" }}>Description</th>
                      <th>Grouped By</th>
                      <th>Value</th>
                      <th>Chart Type</th>
                      <th style={{ width: "20%" }}>Decision</th>
                      <th>Public</th>
                      <th>Inactive</th>
                    </tr>
                  </thead>
                  <tbody>
                    {charts.map((c, idx) => (
                      <tr key={idx}>
                        <td>
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault()
                              editChart(c.userChartID)
                            }}
                          >
                            {c.chartName}
                          </a>
                        </td>
                        <td>{c.description}</td>
                        <td>{c.groupField}</td>
                        <td>{c.valueItem}</td>
                        <td>
                          <i
                            className={
                              c.chartType == "Column"
                                ? "mdi mdi-chart-bar"
                                : c.chartType == "Pie"
                                ? "mdi mdi-chart-pie"
                                : c.chartType == "Table"
                                ? "mdi mdi-table-large"
                                : c.chartType == "Line"
                                ? "mdi mdi-chart-line"
                                : "mdi mdi-chart-gantt"
                            }
                          ></i>{" "}
                          {c.chartType}
                        </td>
                        <td>{c.scenarioName}</td>
                        <td>
                          {c.publicChart ? (
                            <span style={{ color: "red" }}>
                              <b>Public</b>
                            </span>
                          ) : (
                            " - "
                          )}
                        </td>
                        <td>
                          {c.inactive ? (
                            <span style={{ color: "black" }}>
                              <b>Inactive</b>
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <EditUserChartModal
        isOpen={showEditModal}
        toggleModal={toggleEditModal}
        chartToEdit={chartToEdit}
        setChartToEdit={setChartToEdit}
        reloadData={chart => {
          loadCharts()
          if (chart != undefined) {
            loadChart(chart.userChartID)
          }
        }}
      />
    </React.Fragment>
  )
}

export default UserCharts
