import { Tooltip } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useSelector } from "react-redux"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import OptimizationAlert from "./Components/optimizationAlert"
import CustomTable from "components/custom/customTable"
import CurrencyInput from "react-currency-input-field"

const GroupConstraints = ({ activeTab, scenario }) => {
  const currentUser = userService.getLoggedInUser()
  const [groups, setGroups] = useState([])
  const [groupToEdit, setGroupToEdit] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [alternatives, setAlternatives] = useState([])
  const [selectedAlternativeIds, setSelectedAlternativeIds] = useState([])
  const [optModel, setOptModel] = useState(null)
  const { optObjFin } = useSelector(state => ({
    optObjFin: state.optObjFin.optObjFin,
  }))
  const toggleEditModal = () => setShowEditModal(!showEditModal)

  useEffect(() => {
    if (activeTab == "GroupConstraints" && scenario !== null) {
      loadData()
    }
  }, [activeTab, scenario])

  const loadData = async () => {
    try {
      setIsLoading(true)
      await loadGroups()
      await loadAlternatives()
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadGroups = async () => {
    let data = await api.getOptModelGroups(
      optObjFin != null && optObjFin.opt != null
        ? optObjFin.opt.optModelID
        : scenario.currentOptModelID
    )
    setGroups(data)
  }

  const loadAlternatives = async () => {
    let om = await api.getOptModel(
      optObjFin != null && optObjFin.opt != null
        ? optObjFin.opt.optModelID
        : scenario.currentOptModelID
    )
    setOptModel(om)
    let optModels = await api.getOptModelAlternatives(
      scenario.scenarioID,
      om?.optModelID ?? 0,
      om?.objModelID ?? 0,
      om?.finModelID ?? 0
    )
    setAlternatives(optModels)
  }

  const loadSelectedAlternativeIds = async optModelGroupId => {
    try {
      setIsModalLoading(true)
      let ids = await api.getOptModelGroupAlternativeIds(optModelGroupId)
      if (ids.length > 0) {
        ids = ids.map(x => x.alternativeID)
      }
      let altsCopy = alternatives.map(a => {
        if (ids.indexOf(a.alternativeID) > -1) {
          a.isSelected = true
        } else {
          a.isSelected = false
        }
        a.isDirty = false

        return a
      })

      setAlternatives(altsCopy)
      setIsModalLoading(false)
    } catch (err) {
      setIsModalLoading(false)
    }
  }

  const addNewGroup = () => {
    setGroupToEdit({
      optModelGroupID: -1,
      minSelections: 0,
      maxSelections: 0,
      minCost: 0,
      maxCost: 0,
      groupName: "",
      groupAbb: "",
      active: true,
      optModelID:
        optObjFin != null && optObjFin.opt != null
          ? optObjFin.opt.optModelID
          : scenario.currentOptModelID,
    })
    setAlternatives(
      alternatives.map(x => {
        x.isSelected = false
        x.isDirty = false
        return x
      })
    )
    toggleEditModal()
  }

  const editGroup = group => {
    loadSelectedAlternativeIds(group.optModelGroupID)
    setGroupToEdit(group)
    toggleEditModal()
  }

  const deleteGroup = group => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this group?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteOptModelGroup(
            currentUser.userID,
            group.optModelGroupID
          )
          loadData()
        }
      })
  }

  const saveGroup = async () => {
    if (groupToEdit.groupName !== "" || groupToEdit.groupAbb !== "") {
      if (groupToEdit.optModelGroupID > -1) {
        await api.updateOptModelGroup(currentUser.userID, groupToEdit)
        let altsToUpdate = alternatives.filter(x => x.isDirty)
        if (altsToUpdate.length > 0) {
          let altsToUpdateTasks = altsToUpdate.map(x => async () => {
            return await api.updateOptModelGroupAlternativeIds(
              groupToEdit.optModelGroupID,
              x.alternativeID,
              x.isSelected
            )
          })
          await Promise.all(altsToUpdateTasks.map(t => t()))
        }
      } else {
        let id = await api.createOptModelGroup(currentUser.userID, groupToEdit)
        let altsToUpdate = alternatives.filter(x => x.isDirty)
        if (altsToUpdate.length > 0) {
          let altsToUpdateTasks = altsToUpdate.map(x => async () => {
            return await api.updateOptModelGroupAlternativeIds(
              id,
              x.alternativeID,
              x.isSelected
            )
          })
          await Promise.all(altsToUpdateTasks.map(t => t()))
        }
      }
      toggleEditModal()
      loadGroups()
    }
  }

  const changeGroupProp = (prop, value) => {
    setGroupToEdit({
      ...groupToEdit,
      [prop]: value,
    })
  }

  const columns = [
    {
      title: "Name",
      field: "groupName",
      thStyle: { width: "10%" },
      sort: true,
    },
    {
      title: "Short Name",
      field: "groupAbb",
      thStyle: { width: "10%" },
      sort: true,
    },
    {
      title: "Group",
      field: "altNumList",
      thStyle: { width: "20%" },
      sort: true,
    },
    {
      title: "Minimum Selections",
      field: "minSelections",
      thStyle: { textAlign: "center", width: "12%" },
      tdStyle: { textAlign: "center" },
      sort: true,
    },
    {
      title: "Maximum Selections",
      field: "maxSelections",
      thStyle: { textAlign: "center", width: "12%" },
      tdStyle: { textAlign: "center" },
      sort: true,
    },
    {
      title: "Minimum Cost",
      field: "minCost",
      thStyle: { textAlign: "center", width: "12%" },
      tdStyle: { textAlign: "end" },
      sort: true,
      formatter: row => {
        return currencyFormatter.format(row.minCost)
      }
    },
    {
      title: "Maximum Cost",
      field: "maxCost",
      thStyle: { textAlign: "center", width: "12%" },
      tdStyle: { textAlign: "end" },
      sort: true,
      formatter: row => {
        return currencyFormatter.format(row.maxCost)
      }
    },
    {
      title: "Active",
      field: "active",
      thStyle: { textAlign: "center", width: "7%" },
      tdStyle: { textAlign: "center" },
      sort: true,
      formatter: row => {
        return <Input type="checkbox" readOnly="true" checked={row.active} />
      },
    },
    {
      title: "",
      field: "active",
      thStyle: { width: "5%" },
      tdStyle: { textAlign: "center" },
      formatter: row => {
        return (
          <i
            className="fas fa-edit"
            style={{ color: colors.primary, cursor: "pointer" }}
            onClick={() => editGroup(row)}
          ></i>
        )
      },
    },
    {
      title: "",
      field: "active",
      thStyle: { width: "5%" },
      tdStyle: { textAlign: "center" },
      formatter: row => {
        return (
          <i
            className="fas fa-trash"
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => deleteGroup(row)}
          ></i>
        )
      },
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <Col>
          <button className="btn btn-primary" onClick={addNewGroup}>
            + Add Group
          </button>
        </Col>
      </Row>
      <Row>
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
      </Row>
      <OptimizationAlert optModel={optModel} />
      {!isLoading && (
        <Row style={{ marginTop: "10px" }}>
          <Col>
            <CustomTable
              items={groups}
              setItems={setGroups}
              columns={columns}
            />
          </Col>
        </Row>
      )}

      <Modal
        backdrop="static"
        isOpen={showEditModal}
        size="xl"
        toggle={() => {
          toggleEditModal()
        }}
      >
        {groupToEdit !== null && (
          <>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {groupToEdit.optModelGroupID == -1 ? "Add" : "Edit"} Group
              </h5>
              <button
                type="button"
                onClick={() => {
                  toggleEditModal()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <Label className="form-label">Group Name</Label>
                <Input
                  type="text"
                  onChange={e => changeGroupProp("groupName", e.target.value)}
                  value={groupToEdit.groupName}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Short Name</Label>
                <Input
                  type="text"
                  onChange={e => changeGroupProp("groupAbb", e.target.value)}
                  value={groupToEdit.groupAbb}
                />
              </div>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">Minimum Selections</Label>
                    <Input
                      type="number"
                      onChange={e =>
                        changeGroupProp("minSelections", e.target.value)
                      }
                      value={groupToEdit.minSelections}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">Maximum Selections</Label>
                    <Input
                      type="number"
                      onChange={e =>
                        changeGroupProp("maxSelections", e.target.value)
                      }
                      value={groupToEdit.maxSelections}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">Minimum Cost</Label>
                    <CurrencyInput
                      className="form-control"
                      value={groupToEdit.minCost}
                      onValueChange={(value, name, values) => {
                        changeGroupProp("minCost", value)
                      }}
                      prefix={"$"}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">Maximum Cost</Label>
                    <CurrencyInput
                      className="form-control"
                      value={groupToEdit.maxCost}
                      onValueChange={(value, name, values) => {
                        changeGroupProp("maxCost", value)
                      }}
                      prefix={"$"}
                    />
                  </div>
                </Col>
              </Row>

              <div className="mb-3">
                <Input
                  id="activeNotActive"
                  type="checkbox"
                  checked={groupToEdit.active}
                  onClick={() => changeGroupProp("active", !groupToEdit.active)}
                />
                <Label
                  className="form-check-label"
                  for="activeNotActive"
                  style={{ marginLeft: "10px" }}
                >
                  Active
                </Label>
              </div>
              <Row>
                <Col>
                  {isModalLoading && (
                    <Loader
                      type="line-scale-pulse-out"
                      color={colors.primary}
                      style={{ textAlign: "center" }}
                    />
                  )}
                  {!isModalLoading && alternatives.length > 0 && (
                    <table
                      className="table table-bordered low-padding-table"
                      style={{ backgroundColor: "white" }}
                    >
                      <thead>
                        <th style={{ width: "3%" }}></th>
                        <th style={{ width: "3%" }}>ID</th>
                        <th style={{ width: "25%" }}>Project</th>
                        <th style={{ width: "21%" }}>Planning Category</th>
                        <th style={{ width: "13%" }}>Benefit Score</th>
                        <th style={{ width: "10%" }}>Group Rating</th>
                        <th style={{ width: "10%" }}>Cost</th>
                        <th style={{ width: "5%" }}>Must</th>
                        <th style={{ width: "5%" }}>Must Not</th>
                        <th style={{ width: "5%" }}>Allow Partial</th>
                      </thead>
                      <tbody>
                        {alternatives.map((d, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <Input
                                  type="checkbox"
                                  checked={d.isSelected}
                                  onClick={() => {
                                    let copy = [...alternatives]
                                    let item = copy.find(
                                      x => x.alternativeID == d.alternativeID
                                    )
                                    item.isSelected = !item.isSelected
                                    item.isDirty = true
                                    setAlternatives(copy)
                                  }}
                                />
                              </td>
                              <td>{d.alternativeNum}</td>
                              <td>
                                <div className="one-line-elipsis">
                                  <Tooltip title={<h5>{d.alternativeName}</h5>}>
                                    <span> {d.alternativeName}</span>
                                  </Tooltip>
                                </div>
                              </td>

                              <td>
                                <div className="one-line-elipsis">
                                  <Tooltip
                                    title={<h5>{d.planningCategory}</h5>}
                                  >
                                    <span> {d.planningCategory}</span>
                                  </Tooltip>
                                </div>
                              </td>
                              <td>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: d.benefitScore,
                                  }}
                                ></div>
                              </td>
                              <td>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: d.groupRating,
                                  }}
                                ></div>
                              </td>
                              <td style={{ textAlign: "end" }}>
                                {currencyFormatter.format(d.cost)}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <Input
                                  type="checkbox"
                                  checked={d.mustHave}
                                  readOnly={true}
                                />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <Input
                                  type="checkbox"
                                  checked={d.cantHave}
                                  readOnly={true}
                                />
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <Input
                                  type="checkbox"
                                  checked={d.partial}
                                  readOnly={true}
                                />
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  )}
                </Col>
              </Row>
            </div>

            <div className="modal-footer">
              <div>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={toggleEditModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary save-user"
                  onClick={saveGroup}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default GroupConstraints
