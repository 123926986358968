import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const NoteDrawer = ({ activeDrawer, alternative }) => {
  const currentUser = userService.getLoggedInUser()
  const [notes, setNotes] = useState([])
  const [noteSummary, setNoteSummary] = useState([])
  const [reviewerRoles, setReviewerRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState("All")
  const [isLoading, setIsLoading] = useState(false)
  const [isReviewing, setIsReviewing] = useState(false)
  const [noteToEdit, setNoteToEdit] = useState(null)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)

  const toggleEditModal = () => setIsEditModalVisible(!isEditModalVisible)

  useEffect(() => {
    if (activeDrawer == "Note") {
      loadData()
    }
  }, [activeDrawer, alternative])

  const loadData = async () => {
    try {
      setIsLoading(true)
      api
        .getEnterpriseList(currentUser.enterpriseID, "ReviewerRole")
        .then(data => {
          setReviewerRoles(data)
        })
      loadNoteReviewSummary(alternative.alternativeID)
      await loadNotes()
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadNoteReviewSummary = altId => {
    api.getNoteReviewSummary(altId, true).then(data => {
      setNoteSummary(data)
    })
  }

  const loadNotes = async () => {
    try {
      let list = await api.getNotes(alternative.alternativeID, 0, "Alternative")

      let noteList = []
      for (let i = 0; i < list.length; i++) {
        let note = list[i]
        populateWithChildren(note, list)
        noteList.push(note)
      }
      noteList = noteList.filter(
        x => list.find(n => n.noteID == x.parentNoteID) == undefined
      )

      setNotes(noteList)
    } catch (err) {
      console.log(err)
    }
  }

  const populateWithChildren = (note, noteList) => {
    note.children = []
    let children = noteList.filter(x => x.parentNoteID == note.noteID)
    if (children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        let child = children[i]
        populateWithChildren(child, noteList)

        note.children.push(child)
      }
    }
  }

  const renderChildren = note => {
    if (note.children.length > 0) {
      return note.children.map((n, idx) => {
        return (
          <>
            <div
              key={idx}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: "15px",
              }}
            >
              <div>
                <p style={{ marginBottom: "0px" }}>
                  <i className="fas fa-arrow-right"></i>{" "}
                  <b>
                    {n.userName} - {moment(n.noteDT).format("MM/DD/YYYY")}
                  </b>
                </p>
                <p>{n.noteText}</p>
              </div>
              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <i
                  className="fas fa-edit"
                  style={{ cursor: "pointer" }}
                  onClick={() => editNote(n)}
                ></i>
                <i
                  className="fas fa-reply"
                  style={{ marginTop: "5px", cursor: "pointer" }}
                  onClick={() => replyToNote(n)}
                ></i>
              </div>
            </div>
            {renderChildren(n)}
          </>
        )
      })
    }
  }

  const addNote = () => {
    setNoteToEdit({
      noteID: -1,
      noteText: "",
      reviewerRoleID: 0,
      parentNoteID: 0,
      itemID: alternative.alternativeID,
      itemType: "Alternative",
    })
    toggleEditModal()
  }

  const editNote = note => {
    setNoteToEdit(note)
    toggleEditModal()
  }

  const replyToNote = note => {
    setNoteToEdit({
      noteID: -1,
      noteText: "",
      reviewerRoleID: 0,
      parentNoteID: note.noteID,
      itemID: alternative.alternativeID,
      itemType: "Alternative",
    })
    toggleEditModal()
  }

  const saveNote = async () => {
    if (noteToEdit.noteID > -1) {
      await api.updateNote(currentUser.userID, noteToEdit)
      loadNotes()
      loadNoteReviewSummary(alternative.alternativeID)
      toggleEditModal()
    } else {
      await api.createNote(currentUser.userID, noteToEdit)
      loadNotes()
      loadNoteReviewSummary(alternative.alternativeID)
      toggleEditModal()
    }
  }

  const deleteNote = async () => {
    await api.deleteNote(currentUser.userID, noteToEdit.noteID)
    loadNotes()
    toggleEditModal()
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          <button
            className="btn btn-primary"
            style={{ marginTop: "10px" }}
            onClick={() => {
              if (isReviewing) {
                setSelectedRole("All")
              } else {
                loadNoteReviewSummary(alternative.alternativeID)
              }
              setIsReviewing(!isReviewing)
            }}
          >
            {isReviewing ? "Stop Review" : "Review"}
          </button>
          <button
            className="btn btn-primary save-user"
            style={{ marginTop: "10px" }}
            onClick={addNote}
          >
            + Add Comment
          </button>
          <button
            className="btn btn-primary save-user"
            style={{ marginTop: "10px" }}
            onClick={() => {
              loadNotes()
              loadNoteReviewSummary(alternative.alternativeID)
            }}
          >
            Refresh
          </button>
          {isReviewing && noteSummary.length > 0 && (
            <div
              style={{
                marginTop: "15px",
                border: "1px solid lightgray",
                padding: "3px",
                borderRadius: "5px",
              }}
            >
              <p style={{ marginBottom: "0px" }}>
                <b>Review Summary</b>
              </p>
              {noteSummary.map((ns, idx) => (
                <div
                  key={idx}
                  style={{
                    width: "50%",
                    display: "inline-block",
                  }}
                >
                  <span
                    style={{
                      cursor: "pointer",
                      color:
                        selectedRole == ns.reviewerRole
                          ? colors.primary
                          : "black",
                    }}
                    onClick={() => setSelectedRole(ns.reviewerRole)}
                  >
                    <b>
                      {ns.reviewerRole} - {ns.numNotes}
                    </b>
                  </span>
                </div>
              ))}
            </div>
          )}
          {!isLoading &&
            notes.length > 0 &&
            notes
              .filter(
                x => selectedRole == "All" || x.reviewerRole == selectedRole
              )
              .map((n, idx) => {
                return (
                  <div
                    key={idx}
                    style={{
                      width: "100%",
                      borderBottom: "1px solid lightgray",
                      marginTop: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <p style={{ marginBottom: "0px" }}>
                          <b>
                            {n.userName} -{" "}
                            {moment(n.noteDT).format("MM/DD/YYYY")}
                          </b>
                        </p>
                        <p>{n.noteText}</p>
                      </div>
                      <div
                        style={{
                          alignSelf: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <i
                          className="fas fa-edit"
                          style={{ cursor: "pointer" }}
                          onClick={() => editNote(n)}
                        ></i>
                        <i
                          className="fas fa-reply"
                          style={{ marginTop: "5px", cursor: "pointer" }}
                          onClick={() => replyToNote(n)}
                        ></i>
                      </div>
                    </div>

                    {renderChildren(n)}
                  </div>
                )
              })}
        </Col>
      </Row>

      <Modal
      backdrop="static"
        isOpen={isEditModalVisible}
        size="md"
        toggle={() => {
          toggleEditModal()
        }}
      >
        {noteToEdit !== null && (
          <>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {noteToEdit.noteID == -1 ? "Add " : "Edit "} Comment
              </h5>
              <button
                type="button"
                onClick={() => {
                  toggleEditModal()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ color: "black" }}
              >
                <span style={{ color: "black" }} aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              {isReviewing && (
                <div className="mb-3">
                  <Label className="form-label">Role</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={noteToEdit.reviewerRoleID || -1}
                    onChange={e => {
                      setNoteToEdit({
                        ...noteToEdit,
                        reviewerRoleID: e.target.value,
                      })
                    }}
                  >
                    <option value={-1}>Select Role</option>
                    {reviewerRoles.map((a, idx) => {
                      return (
                        <option key={idx} value={`${a.listItemID}`}>
                          {a.listItemName}
                        </option>
                      )
                    })}
                  </select>
                </div>
              )}
              <div className="mb-3">
                <Label className="form-label">Comment</Label>
                <Input
                  name="note"
                  type="textarea"
                  rows="4"
                  onChange={e =>
                    setNoteToEdit({
                      ...noteToEdit,
                      noteText: e.target.value,
                    })
                  }
                  value={noteToEdit.noteText}
                />
              </div>
            </div>
          </>
        )}
        <div
          className="modal-footer"
          style={{
            justifyContent:
              noteToEdit !== null && noteToEdit.noteID > -1
                ? "space-between"
                : "flex-end",
          }}
        >
          {noteToEdit !== null && noteToEdit.noteID > -1 && (
            <button
              type="button"
              className="btn btn-danger"
              onClick={deleteNote}
            >
              Delete
            </button>
          )}
          <div>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={toggleEditModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary save-user"
              onClick={saveNote}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default NoteDrawer
