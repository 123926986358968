import useModal from "hooks/useModalHook"
import React, { useEffect, useState } from "react"
import { Col, Input, Label, Row, Alert } from "reactstrap"
import api from "services/api.service"
import ApplyTaskTypeModal from "./Modals/ApplyTaskTypeModal"

const TrackingOptionsSection = ({ activeTab, scenario, setScenario }) => {
  const [taskTypes, setTaskTypes] = useState([])
  const [taskTypeToApply, setTaskTypeToApply] = useState(null)
  const { show: showApplyModal, toggle: toggleApplyModal } = useModal()

  useEffect(() => {
    if (activeTab == "8") {
      loadData()
    }
  }, [activeTab])

  const loadData = async () => {
    let types = await api.getTaskTypes(scenario.scenarioID)
    setTaskTypes(
      types
        .filter(x => x.enableByScenario)
        .map(t => {
          let selected = scenario.scenarioTaskTypeIDs.find(
            x => x == t.taskTypeID
          )
          t.isSelected = selected == undefined ? false : true
          return t
        })
    )
  }

  const applyWorkflow = taskType => {
    setTaskTypeToApply(taskType)
    toggleApplyModal()
  }

  return (
    <React.Fragment>
                  <Row>
              <Alert
                id="helpView"
                variant="light"
                className="always-enabled"
                style={{ marginBottom: "10px", paddingBottom: "0px" }}
              >
                <div>
                  Select the types of things you need to track in this portfolio. Items tracked at the project level will show up in the Actions menu when editing a project.  Those at the portfolio level on on the menu under the "Manage Decision" option.
                  <ol>
                    <li>
                      <b>General</b> - These are  tasks that can be tracked at the project or portfolio level.  
                    </li>
                    <li>
                    <b>Workflows</b> - If you want to setup a workflow to track for each project in the portfolio, select it here. 
                    </li>
                    <li>
                    <b>Task Lists</b> - These are pre-set list of project tasks that can be setup to populate your projects.  Selecting it here makes it available for the projects in this portfolio.
                    </li>
                  </ol>
                </div>
              </Alert>
            </Row>
      <Row>
        <Col>
          <Label>
            <h4>General</h4>
          </Label>
          {taskTypes
            .filter(x => x.workflowType == null || x.workflowType == "")
            .map((tt, idx) => (
              <div
                key={idx}
                className="d-flex-row ai-center"
                style={{ gap: "15px" }}
                // style={{ marginLeft: "10px" }}
              >
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id={`tt-cbx-${idx}`}
                  name={`tt-cbx-${idx}`}
                  checked={tt.isSelected}
                  onClick={e => {
                    let copy = [...taskTypes]
                    let item = copy.find(x => x.taskTypeID == tt.taskTypeID)
                    item.isSelected = !item.isSelected
                    setTaskTypes(copy)
                    setScenario({
                      ...scenario,
                      scenarioTaskTypeIDs: copy
                        .filter(x => x.isSelected)
                        .map(x => x.taskTypeID),
                    })
                  }}
                />
                <Label
                  style={{ padding: "5px" }}
                  className="form-check-label"
                  for={`tt-cbx-${idx}`}
                >
                  {tt.selectionLabel}
                </Label>
              </div>
            ))}
          <Label className="mt-3">
          <h4>Workflows</h4>
          </Label>

          {taskTypes
            .filter(x => x.workflowType == "Workflow")
            .map((tt, idx) => (
              <div
                key={idx}
                className="d-flex-row ai-center"
                style={{ gap: "15px" }}
                // style={{ marginLeft: "10px" }}
              >
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id={`tt2-cbx-${idx}`}
                  name={`tt2-cbx-${idx}`}
                  checked={tt.isSelected}
                  onClick={e => {
                    let copy = [...taskTypes]
                    let item = copy.find(x => x.taskTypeID == tt.taskTypeID)
                    item.isSelected = !item.isSelected
                    setTaskTypes(copy)
                    setScenario({
                      ...scenario,
                      scenarioTaskTypeIDs: copy
                        .filter(x => x.isSelected)
                        .map(x => x.taskTypeID),
                    })
                  }}
                />
                <Label
                  style={{ padding: "5px" }}
                  className="form-check-label"
                  for={`tt2-cbx-${idx}`}
                >
                  {tt.workflowName}
                </Label>
                {tt.isSelected && (
                  <button
                    className="btn btn-primary"
                    style={{ marginLeft: "20px" }}
                    onClick={() => applyWorkflow(tt)}
                  >
                    Apply...
                  </button>
                )}
              </div>
            ))}
          <Label className="mt-3">
          <h4>Task Lists</h4>
          </Label>

          {taskTypes
            .filter(x => x.workflowType == "Task List")
            .map((tt, idx) => (
              <div
                key={idx}
                className="d-flex-row ai-center"
                style={{ gap: "15px" }}
                // style={{ marginLeft: "10px" }}
              >
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id={`tt3-cbx-${idx}`}
                  name={`tt3-cbx-${idx}`}
                  checked={tt.isSelected}
                  onClick={e => {
                    let copy = [...taskTypes]
                    let item = copy.find(x => x.taskTypeID == tt.taskTypeID)
                    item.isSelected = !item.isSelected
                    setTaskTypes(copy)
                    setScenario({
                      ...scenario,
                      scenarioTaskTypeIDs: copy
                        .filter(x => x.isSelected)
                        .map(x => x.taskTypeID),
                    })
                  }}
                />
                <Label
                  style={{ padding: "5px" }}
                  className="form-check-label"
                  for={`tt3-cbx-${idx}`}
                >
                  {tt.workflowName}
                </Label>
              </div>
            ))}
        </Col>
      </Row>
      <ApplyTaskTypeModal
        isOpen={showApplyModal}
        toggleModal={toggleApplyModal}
        taskType={taskTypeToApply}
        scenario={scenario}
      />
    </React.Fragment>
  )
}

export default TrackingOptionsSection
