import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import EditNotificationModal from "./EditNotificationModal"

const NotificationSettingsModal = ({ isOpen, toggleModal }) => {
  const currentUser = userService.getLoggedInUser()
  const [rules, setRules] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [notificationRuleToEdit, setNotificationRuleToEdit] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const toggleEditModal = () => setShowEditModal(!showEditModal)

  const [getNotesAlerts, setGetNotesAlerts] = useState(
    currentUser == null ? false : currentUser.getNotesAlerts
  )
  const [useEmail, setUseEmail] = useState(
    currentUser == null ? false : currentUser.useEmail
  )

  useEffect(() => {
    if (isOpen) {
      setGetNotesAlerts(userService.getLoggedInUser().getNotesAlerts)
      setUseEmail(userService.getLoggedInUser().useEmail)
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    await loadRules()
    setIsLoading(true)
  }

  const loadRules = async () => {
    let nr = await api.getNotificationRules(currentUser.userID)
    setRules(nr)
  }

  const save = async () => {}

  const deleteNotification = notification => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this notification?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteNotificationRule(
            currentUser.userID,
            notification.notificationRuleID
          )
          loadRules()
        }
      })
  }

  const editRule = notificationRule => {
    if (notificationRule == undefined) {
      notificationRule = {
        delivery: "Immediately",
        notificationRuleID: -1,
        scenarioID: 0,
        alternativeID: 0,
        fieldName: 0,
        item: "Alternative",
        triggerRole: "Team Member",
        triggerType: "any value",
        inactive: false,
        userID: currentUser.userID,
      }
    }
    setNotificationRuleToEdit(notificationRule)
    toggleEditModal()
  }

  const changeNotificationRuleToEditProp = (prop, value) => {
    setNotificationRuleToEdit({
      ...notificationRuleToEdit,
      [prop]: value,
    })
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Notification Settings
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Input
            id="getNotesAlerts"
            type="checkbox"
            className="form-check-input"
            checked={getNotesAlerts}
            onClick={e => {
              let newVal = !getNotesAlerts
              api
                .updateUser(currentUser.userID, {
                  ...currentUser,
                  getNotesAlerts: newVal,
                })
                .then(r => {
                  userService.updateUser({
                    ...currentUser,
                    getNotesAlerts: newVal,
                  })
                })
              setGetNotesAlerts(newVal)
            }}
          />
          <Label for="getNotesAlerts" className="form-check-label save-user">
            Receive Alerts from User Comments
          </Label>
        </div>
        <div className="mb-3">
          <Input
            id="useEmail"
            type="checkbox"
            className="form-check-input"
            checked={useEmail}
            onClick={e => {
              let newVal = !useEmail
              api
                .updateUser(currentUser.userID, {
                  ...currentUser,
                  useEmail: newVal,
                })
                .then(r => {
                  userService.updateUser({
                    ...currentUser,
                    useEmail: newVal,
                  })
                })
              setUseEmail(newVal)
            }}
          />
          <Label for="useEmail" className="form-check-label save-user">
            Receive Alerts via Email
          </Label>
        </div>
        <div className="mb-3">
          <button className="btn btn-primary" onClick={() => editRule()}>
            + Add Notification Rule
          </button>
        </div>
        <div className="mb-3">
          <table
            className="table table-bordered low-padding-table"
            style={{ backgroundColor: "white" }}
          >
            <thead>
              <tr>
                <th style={{ width: "75%" }}>Notification Rule</th>
                <th style={{ width: "15%" }}>Inactive</th>
                <th style={{ width: "10%" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rules.map((r, idx) => (
                <tr key={idx}>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: r.ruleText,
                      }}
                    ></div>
                  </td>
                  <td>{r.inactive ? "Yes" : "No"}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className="fas fa-edit"
                        style={{ color: colors.primary, cursor: "pointer" }}
                        onClick={() => editRule(r)}
                      ></i>
                      <i
                        className="fas fa-trash"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => deleteNotification(r)}
                      ></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
        {/* <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button> */}
      </div>
      <EditNotificationModal
        isOpen={showEditModal}
        toggleModal={toggleEditModal}
        notificationToEdit={notificationRuleToEdit}
        changeNotificationToEditProp={changeNotificationRuleToEditProp}
        reloadData={loadRules}
      />
    </Modal>
  )
}

export default NotificationSettingsModal
