import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
} from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import Loader from "react-loaders"
import {
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  Row,
  Alert,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import { ReactTabulator } from "react-tabulator"
import colors from "utils/colors"
import { sortData } from "utils/sort"

const EditReportSetModal = ({
  isOpen,
  toggleModal,
  reportSetToEdit,
  setReportSetToEdit,
  reloadData,
}) => {
  const currentUser = userService.getLoggedInUser()
  const tabulatorRef = useRef()
  const [isLoading, setIsLoading] = useState(false)

  const [search, setSearch] = useState("")
  const [allSelected, setAllSelected] = useState(false)

  const [fieldTypes, setFieldTypes] = useState([])
  const [selectedFieldType, setSelectedFieldType] = useState(null)
  const [makeModes, setMakeModes] = useState([])
  const [selectedMakeMode, setSelectedMakeMode] = useState(-1)
  const [scorecards, setScorecards] = useState([])
  const [selectedScorecard, setSelectedScorecard] = useState(null)
  const [getModes, setGetModes] = useState([])
  const [selectedGetMode, setSelectedGetMode] = useState(null)

  const [templates, setTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(0)
  const [years, setYears] = useState([])
  const [selectedYear, setSelectedYear] = useState(-1)

  const [allFields, setAllFields] = useState([])

  useEffect(() => {
    if (isOpen) {
      setAllFields([])
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)

    setSelectedMakeMode(-1)
    setSelectedYear(-1)

    let ft = await api.getList(currentUser.userID, "ViewItemType")
    setFieldTypes(ft)
    setSelectedFieldType(ft[0].listItemName)

    loadItems(ft[0].listItemName, undefined, undefined)

    let temp = await api.getList(currentUser.userID, "CostPoolTemplates")
    setTemplates(temp)
    if (temp.length > 0) {
      setSelectedTemplate(temp[0].listItemID)
    }

    let mm = await api.getList(currentUser.userID, "ViewItemForecastMode")
    setMakeModes(mm)
    // if (mm.length > 0) {
    //   setSelectedMakeMode(mm[0].listItemName)
    // }

    let y = await api.getYears(currentUser.userID)
    setYears(y)
    // if (y.length > 0) {
    //   setSelectedYear(y[0].listItemName)
    // }

    let sc = await api.getEnterpriseList(currentUser.enterpriseID, "Scorecard")
    setScorecards(sc)
    if (sc.length > 0) {
      setSelectedScorecard(sc[0].listItemID)
    }

    let gl = await api.getList(currentUser.userID, "ViewItemScorecardMode")
    setGetModes(gl)
    if (gl.length > 0) {
      setSelectedGetMode(gl[0].listItemName)
    }

    setIsLoading(false)
  }

  const loadItems = async (fieldType, scorecard, template) => {
    setSearch("")
    if (fieldType == "Business Case Field") {
      let fields = await loadBcFields(0, 0, 0, 0, 0)
      setAllFields(
        fields.map(f => {
          return {
            fieldName: f.fieldName,
            itemID: f.bcFieldID,
            type: "BCReportItem",
            mode: "SQLString",
          }
        })
      )
    } else if (fieldType == "Forecast Data") {
      let cp = await api.getCostPools(currentUser.enterpriseID, template)
      let summaryCp = await api.getList(currentUser.userID, "SummaryCostPool")

      let list = [
        ...cp.map(f => {
          return {
            fieldName: f.costPoolType + " - " + f.costPoolName,
            itemID: f.costPoolID,
            type: "CostPool",
          }
        }),
        ...summaryCp.map(s => {
          return {
            fieldName: s.listItemName,
            itemID: s.listItemID,
            type: "CostPool",
          }
        }),
      ]
      setAllFields(sortData(list, "fieldName"))
    } else if (fieldType == "Scorecard Data") {
      let sc = await api.getScorecardItemsByScorecard(scorecard)
      setAllFields(
        sc.map(f => {
          return {
            fieldName: f.itemText,
            itemID: f.scorecardItemID,
            type: "Scorecard",
          }
        })
      )
    } else if (fieldType == "Calculated Field") {
      let fields = await loadBcFields(0, 1, 0, 0, 0)
      setAllFields(
        fields.map(f => {
          return {
            fieldName: f.fieldName,
            itemID: f.bcFieldID,
            type: "CalculatedField",
          }
        })
      )
    } else if (fieldType == "Custom Form Field") {
      let fields = await loadBcFields(0, 2, 0, 0, 0)
      setAllFields(
        fields.map(f => {
          return {
            fieldName: f.fieldName,
            itemID: f.bcFieldID,
            type: "CustomFormField",
          }
        })
      )
    }
  }

  const loadBcFields = async (fieldType, template, type, costPool, year) => {
    return await api.getBcFields(
      currentUser.userID,
      fieldType,
      template,
      type,
      costPool,
      year
    )
  }

  const save = async () => {
    if (reportSetToEdit.bcReportSetID > -1) {
      await api.updateBcReportSet(currentUser.userID, reportSetToEdit)
      toggleModal()
      reloadData()
    } else {
      await api.createBcReportSet(currentUser.userID, reportSetToEdit)
      toggleModal()
      reloadData()
    }
  }

  const copyView = async () => {
    await api.copyBcReportSet(currentUser.userID, reportSetToEdit.bcReportSetID)
    toggleModal()
    reloadData()
  }
  const deleteView = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this dashboard?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteBcReportSet(
            currentUser.userID,
            reportSetToEdit.bcReportSetID
          )
          reloadData()
          toggleModal()
        }
      })
  }

  const changeReportSetProp = (prop, value) => {
    setReportSetToEdit({ ...reportSetToEdit, [prop]: value })
  }

  const getSelectedMode = () => {
    if (selectedFieldType == "Business Case Field") {
      return "SQLString"
    } else if (selectedFieldType == "Forecast Data") {
      return selectedMakeMode
    } else if (selectedFieldType == "Scorecard Data") {
      return selectedGetMode
    } else if (selectedFieldType == "Calculated Field") {
      return ""
    } else if (selectedFieldType == "Custom Form Field") {
      return "Custom"      
    }
  }

  const getSelectedYear = item => {
    if (selectedFieldType == "Business Case Field") {
      return item.yearID
    } else if (selectedFieldType == "Forecast Data") {
      if (selectedMakeMode == "Total") {
        return 0
      }
      return selectedYear
    } else if (selectedFieldType == "Scorecard Data") {
      return 0
    } else if (selectedFieldType == "Calculated Field") {
      return 0
    } else if (selectedFieldType == "Custom Form Field") {
      return 0      
    }
  }

  const addSelectedItems = () => {
    let selected = allFields.filter(x => x.selected)
    if (selected.length > 0) {
      let reportFields = [...reportSetToEdit.bcReportSetFields]
      selected.forEach(s => {
        s.selected = false
        reportFields.push({
          ...s,
          yearID: getSelectedYear(s),
          mode: getSelectedMode(),
        })
      })
      reportFields.forEach((f, idx) => {
        f.orderID = idx + 1
      })
      setReportSetToEdit({
        ...reportSetToEdit,
        bcReportSetFields: reportFields,
      })
    }
  }
  const removeSelectedItems = () => {
    let selected = tabulatorRef.current.getSelectedData()
    if (selected.length > 0) {
      let reportFields = [...reportSetToEdit.bcReportSetFields]
      reportFields = reportFields.filter(
        d =>
          selected.find(
            x =>
              x.itemID == d.itemID && x.yearID == d.yearID && x.mode == d.mode
          ) == undefined
      )
      reportFields.forEach((d, idx) => {
        d.orderID = idx + 1
      })
      setReportSetToEdit({
        ...reportSetToEdit,
        bcReportSetFields: reportFields,
      })
    }
  }

  const columns = [
    {
      formatter: "rowSelection",
      titleFormatter: "rowSelection",
      hozAlign: "center",
      headerSort: false,
      cellClick: function (e, cell) {
        cell.getRow().toggleSelect()
      },
      width: 50,
    },
    {
      title: "Field",
      field: "fieldName",
      headerSort: false,
      formatter: (cell, formatterParams, onRendered) => {
        let rowData = cell.getRow().getData()
        let mode =
          rowData.mode != undefined &&
          rowData.mode != null &&
          rowData.mode != "SQLString" &&
          rowData.mode != ""
            ? "(" + rowData.mode + ")"
            : undefined
        return `${cell.getValue()} ${mode != undefined ? mode : ""} ${
          rowData.yearID != undefined && rowData.yearID != "0"
            ? rowData.yearID
            : ""
        }`
        // return cell.getValue()
      },
    },
    {
      title: "Order",
      field: "orderID",
      headerSort: false,
      width: 100,
    },
    {
      rowHandle: true,
      formatter: "handle",
      headerSort: false,
      frozen: true,
      width: 50,
    },
  ]

  const canAddItems =
    selectedFieldType !== "Forecast Data" ||
    (selectedFieldType == "Forecast Data" &&
      (selectedMakeMode == "Total" ||
        (selectedMakeMode != "-1" && selectedYear != -1)))

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      //fullscreen={true}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          View
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {reportSetToEdit != null && (
        <div className="modal-body">
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading && (
            <>
              <Row className="mb-3">
                <Col md="12">
                  <div className="mb-3">
                    <Label className="form-label">View Name</Label>
                    <Input
                      name="chartName"
                      type="text"
                      onChange={e =>
                        changeReportSetProp("reportSetName", e.target.value)
                      }
                      value={reportSetToEdit.reportSetName}
                    />
                  </div>
                  <div
                    className="mb-3"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Input
                      type="checkbox"
                      checked={reportSetToEdit.publicSet}
                      onClick={() => {
                        changeReportSetProp(
                          "publicSet",
                          !reportSetToEdit.publicSet
                        )
                      }}
                    />
                    <Label style={{ marginLeft: "10px" }}>Public</Label>
                    <Input
                      type="checkbox"
                      checked={reportSetToEdit.inactive}
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        changeReportSetProp(
                          "inactive",
                          !reportSetToEdit.inactive
                        )
                      }}
                    />
                    <Label style={{ marginLeft: "10px" }}>Inactive</Label>
                  </div>
                  <div className="divider"></div>
                  <Alert
                    id="helpView"
                    variant="light"
                    className="always-enabled"
                    style={{ marginBottom: "10px", paddingBottom: "0px" }}
                  >
                    <div>
                      <b>Add Fields to the View</b>
                      <ol>
                        <li>
                          Select the Type of Field to add and then select from
                          the list.
                        </li>
                        <li>
                          If required, select the mode or option for the value
                          from that field and the year.
                        </li>
                        <li> Click add to add to the list below.</li>
                        <li>Click the "Save" button.</li>
                      </ol>
                    </div>
                  </Alert>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">Field Type</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={selectedFieldType}
                      onChange={e => {
                        loadItems(
                          e.target.value,
                          selectedScorecard,
                          selectedTemplate
                        )
                        setSelectedFieldType(e.target.value)
                      }}
                    >
                      {fieldTypes.map((f, idx) => (
                        <option key={idx} value={f.listItemName}>
                          {f.listItemName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {selectedFieldType == "Forecast Data" && (
                    <>
                      <div className="mb-3">
                        <Label className="form-label">Template</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={selectedTemplate}
                          onChange={e => {
                            loadItems(
                              selectedFieldType,
                              selectedScorecard,
                              e.target.value
                            )
                            setSelectedTemplate(e.target.value)
                          }}
                        >
                          {templates.map((x, idx) => (
                            <option key={idx} value={x.listItemID}>
                              {x.listItemName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Make Mode</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={selectedMakeMode}
                          onChange={e => {
                            setSelectedMakeMode(e.target.value)
                            setSelectedYear(-1)
                          }}
                        >
                          <option value="-1">Select...</option>
                          {makeModes.map((x, idx) => (
                            <option key={idx} value={x.listItemName}>
                              {x.listItemName}
                            </option>
                          ))}
                        </select>
                      </div>
                      {selectedMakeMode !== "Total" && (
                        <div className="mb-3">
                          <Label className="form-label">Year</Label>
                          <select
                            className="form-control form-select select2 mb-xxl-0"
                            value={selectedYear}
                            onChange={e => {
                              setSelectedYear(e.target.value)
                            }}
                          >
                            <option value="-1">Select...</option>
                            {selectedMakeMode == "Year Number" ||
                            selectedMakeMode ==
                              "Cumulative Through Year Number" ? (
                              <>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                                  (x, idx) => (
                                    <option key={idx} value={x}>
                                      {x}
                                    </option>
                                  )
                                )}
                              </>
                            ) : (
                              <>
                                {years.map((x, idx) => (
                                  <option key={idx} value={x.listItemName}>
                                    {x.listItemName}
                                  </option>
                                ))}
                              </>
                            )}
                          </select>
                        </div>
                      )}
                    </>
                  )}
                  {selectedFieldType == "Scorecard Data" && (
                    <>
                      <div className="mb-3">
                        <Label className="form-label">Scorecard</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={selectedScorecard}
                          onChange={e => {
                            loadItems(
                              selectedFieldType,
                              e.target.value,
                              selectedTemplate
                            )
                            setSelectedScorecard(e.target.value)
                          }}
                        >
                          {scorecards.map((x, idx) => (
                            <option key={idx} value={x.listItemName}>
                              {x.listItemName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Mode</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={selectedGetMode}
                          onChange={e => {
                            setSelectedGetMode(e.target.value)
                          }}
                        >
                          {getModes.map((x, idx) => (
                            <option key={idx} value={x.listItemName}>
                              {x.listItemName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  )}
                </Col>
                <Col md="6">
                  <InputGroup>
                    <InputGroupText>
                      <i className="fas fa-search"></i>
                    </InputGroupText>
                    <Input
                      placeholder="Search ..."
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                    />
                  </InputGroup>
                  <div style={{ overflowX: "auto", height: "350px" }}>
                    <table
                      className="table table-bordered low-padding-table"
                      style={{ backgroundColor: "white" }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }}>
                            <Input
                              type="checkbox"
                              checked={allSelected}
                              onClick={() => {
                                let copy = [...allFields].map(x => {
                                  if (
                                    search == null ||
                                    search == "" ||
                                    x.fieldName
                                      .toLowerCase()
                                      .indexOf(search.toLowerCase()) > -1
                                  ) {
                                    x.selected = !allSelected
                                  } else {
                                    x.selected = false
                                  }
                                  return x
                                })
                                setAllSelected(!allSelected)
                                setAllFields(copy)
                              }}
                            />
                          </th>
                          <th style={{ width: "95%" }}>Field</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allFields
                          .filter(
                            x =>
                              search == null ||
                              search == "" ||
                              x.fieldName
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) > -1
                          )
                          .map((c, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <Input
                                    type="checkbox"
                                    checked={c.selected}
                                    onClick={() => {
                                      let copy = [...allFields]

                                      let item = copy.find(
                                        x => x.itemID == c.itemID
                                      )
                                      item.selected = !item.selected
                                      setAllFields(copy)
                                    }}
                                  />
                                </td>
                                <td>{c.fieldName}</td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="btn btn-primary"
                      onClick={addSelectedItems}
                      disabled={!canAddItems}
                    >
                      <i className="fas fa-plus"></i> Add Field(s)
                    </button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col id="dashboard-tabulator-style">
                  <div style={{ overflowX: "auto", maxHeight: "370px" }}>
                    <ReactTabulator
                      onRef={r => (tabulatorRef.current = r.current)}
                      columns={columns}
                      data={reportSetToEdit.bcReportSetFields}
                      options={{
                        movableRows: true,
                        layout: "fitColumns",
                        cssClass: "table-borderless",
                      }}
                      events={{
                        rowMoved: function (row) {
                          let rows = tabulatorRef.current.getData()
                          rows.forEach((r, idx) => {
                            r.orderID = idx + 1
                          })
                          setReportSetToEdit({
                            ...reportSetToEdit,
                            bcReportSetFields: rows,
                          })
                        },
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="btn btn-outline-danger"
                      style={{ marginTop: "15px" }}
                      onClick={removeSelectedItems}
                    >
                      <i className="fas fa-times"></i> Remove Field(s)
                    </button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <button
            onClick={() => {
              toggleModal()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          {reportSetToEdit != null && reportSetToEdit.bcReportSetID > -1 && (
            <button
              onClick={() => {
                deleteView()
              }}
              className="btn btn-danger save-user"
              data-dismiss="modal"
            >
              Delete View
            </button>
          )}
        </div>
        <div>
          {reportSetToEdit != null && (
            <>
              {reportSetToEdit.bcReportSetID > -1 && (
                <button
                  className="btn btn-primary save-user"
                  onClick={copyView}
                >
                  Copy View
                </button>
              )}
              <button className="btn btn-primary save-user" onClick={save}>
                Save View
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default EditReportSetModal
