import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import SelectAlternativeModal from "pages/AdminAlternativeScore/SelectAlternativeModal"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import logo from "../../../assets/images/defprologo1_sm.png"
import { TextareaAutosize } from "@material-ui/core"

const AlternativeScore = props => {
  const scenarioId = props.match.params.scenarioId
  const mode = props.match.params.mode
  const currentUser = userService.getLoggedInUser()

  let [nextAltScore, setNextAltScore] = useState(null)
  let [userScore, setUserScore] = useState(null)
  let [scenarioRatingScale, setScenarioRatingScale] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  let [scenario, setScenario] = useState(null)
  let [showAltModal, setShowAltModal] = useState(false)
  let [showRefresh, setShowRefresh] = useState(false)

  useEffect(() => {
    if (mode == 1) {
      loadNextDataGroup()
    } else {
      loadNextData(0, 1, "alt")
    }
    loadScenario()
    loadScenarioRatingScale()
  }, [])

  const loadNextData = async (altScoreId, levelId, altOrObj) => {
    await saveComment()

    let altScore = await loadNextAltScore(altScoreId, levelId, altOrObj)
    if (altScore.mobileComplete) {
      props.history.push("/mobile/exit")
      return
    }
    setNextAltScore(altScore)

    let userScores = await loadUserScores(altScore.altScoreID, altScore.levelID)
    setUserScore(userScores.find(x => x.userID == currentUser.userID))
  }

  const loadPrevData = async (altScoreId, levelId, altOrObj) => {
    await saveComment()
    
    let altScore = await loadPrevAltScore(altScoreId, levelId, altOrObj)
    setNextAltScore(altScore)

    let userScores = await loadUserScores(altScore.altScoreID, altScore.levelID)
    setUserScore(userScores.find(x => x.userID == currentUser.userID))
  }

  const loadAlternativeFromModal = async alternativeId => {
    try {
      setIsLoading(true)
      await saveComment()
      
      let altScore = await api.getAltScoreByAltId(
        scenarioId,
        alternativeId,
        nextAltScore.objMeasID,
        nextAltScore.levelID
      )
      setNextAltScore(altScore)

      let userScores = await loadUserScores(
        altScore.altScoreID,
        altScore.levelID
      )
      setUserScore(userScores.find(x => x.userID == currentUser.userID))
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const loadNextAltScore = async (altScoreId, levelId, altOrObj) => {
    try {
      setIsLoading(true)
      let altScore = await api.getNextAltScore(
        altScoreId,
        levelId,
        scenarioId,
        altOrObj
      )
      setIsLoading(false)

      return altScore
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadPrevAltScore = async (altScoreId, levelId, altOrObj) => {
    try {
      setIsLoading(true)
      let altScore = await api.getPrevAltScore(
        altScoreId,
        levelId,
        scenarioId,
        altOrObj
      )
      setIsLoading(false)
      return altScore
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const reloadAltScore = async (altScoreId, levelId) => {
    try {
      setIsLoading(true)
      let altScore = await api.getAltScore(altScoreId, levelId)
      setNextAltScore(altScore)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadUserScores = async (altScoreId, levelId) => {
    try {
      let scores = await api.getAltUserScores(altScoreId, levelId)
      return scores
    } catch (err) {
      console.log(err)
    }
  }

  const loadScenario = async () => {
    try {
      var scenario = await api.getScenario(scenarioId)
      setScenario(scenario)
    } catch (err) {
      console.log(err)
    }
  }

  const loadScenarioRatingScale = async () => {
    try {
      var scenarioRatings = await api.getScenarioInputRatings(scenarioId)
      setScenarioRatingScale(
        scenarioRatings.sort(
          (a, b) => parseFloat(a.inputValue) - parseFloat(b.inputValue)
        )
      )
    } catch (err) {
      console.log(err)
    }
  }

  const toggleAltModal = () => {
    setShowAltModal(!showAltModal)
  }

  const changeUserScore = inputValue => {
    let copy = { ...userScore }
    copy.userScore = inputValue
    copy.userScored = true
    setUserScore(copy)
    api.updateAltUserScore(userScore.levelID, {
      ...copy,
      altScoreID: nextAltScore.altScoreID,
    })
  }

  const submit = async () => {
    // await api.updateAltUserScore(userScore.levelID, {
    //   ...userScore,
    //   altScoreID: nextAltScore.altScoreID,
    // })
  }

  const loadNextDataGroup = async () => {
    setShowRefresh(false)
    
    await saveComment()
    
    let altGroup = await api.getAltScoreGroup(scenarioId)
    if (altGroup == null) {
      setShowRefresh(true)
      swalWithConfirmButton.fire({
        title: `Please wait for the facilitator to start`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else if (
      nextAltScore != null &&
      altGroup.altScoreID == nextAltScore.altScoreID
    ) {
      swalWithConfirmButton.fire({
        title: `The score was submitted, please wait of the facilitator to move forward`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      setNextAltScore(altGroup)
      if (altGroup.mobileComplete) {
        props.history.push("/mobile/exit")
        return
      }

      let us = await loadUserScores(altGroup.altScoreID, altGroup.levelID)
      setUserScore(us.find(x => x.userID == currentUser.userID))
    }
  }

  const exit = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you are finished scoring the alternatives?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          props.history.push("/mobile/exit")
        }
      })
  }

  const saveComment = async () => {
    if (userScore != null && userScore.userScored && userScore.isDirty) {
      await api.updateAltUserScore(nextAltScore.levelID, { ...userScore })
    }
  }

  return (
    <React.Fragment>
      <div
        style={{
          overflow: "hidden",
          color: "white",
        }}
      >
        <Row
          className="background-gradient"
          style={{ height: "100vh", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              alignSelf: "start",
              height: "70px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "whitesmoke",
              paddingRight: "50px",
              paddingLeft: "50px",
            }}
          >
            <img src={logo} style={{ width: "30px" }}></img>
            <h5 style={{ color: colors.primary }}>
              <b>
                <u>Score {nextAltScore && nextAltScore.comparison}</u>
              </b>
            </h5>
            <i
              className="fas fa-power-off"
              style={{
                cursor: "pointer",
                color: colors.primary,
                fontSize: "16px",
              }}
              onClick={() => props.history.push("/mobile/exit")}
            ></i>
          </div>
          <Col
            md="6"
            sm="12"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              textAlign: "center",
              height: "100%",
            }}
          >
            <Row style={{ marginRight: "0%" }}>
              {nextAltScore != null && (
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      borderRadius: "10px",
                      padding: "5px",
                    }}
                  >
                    <h5>
                      To what degree does the <br />
                      <span style={{ color: "maroon" }}>
                        <b>{nextAltScore.alternativeName}</b>
                      </span>
                      <br />
                      project contribute to <br />
                      <span style={{ color: "maroon" }}>
                        <b>{nextAltScore.objMeasName}</b>
                      </span>
                    </h5>
                  </div>
                </div>
              )}
              {isLoading && (
                <div
                  style={{ height: "70px", width: "100%", textAlign: "center" }}
                >
                  <Loader
                    type="line-scale-pulse-out"
                    color={colors.primary}
                    style={{ textAlign: "center" }}
                  />
                </div>
              )}
            </Row>
            {nextAltScore != null && userScore != null && scenario != null && (
              <Row
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "80%",
                }}
              >
                {scenarioRatingScale.map((s, idx) => (
                  <div
                    key={idx}
                    className="vote-button mb-3"
                    style={{
                      color: s.foreColor,
                      backgroundColor: s.backColor,
                      fontWeight:
                        userScore.userScored &&
                        userScore.userScore == s.inputValue
                          ? "bold"
                          : "normal",
                      padding: "5px",
                      height: "30px",
                    }}
                    onClick={() => changeUserScore(s.inputValue)}
                  >
                    {s.ratingName}{" "}
                    {userScore.userScored &&
                      userScore.userScore == s.inputValue && (
                        <i className="fas fa-check"></i>
                      )}
                  </div>
                ))}

                {userScore.userScored && (
                  <TextareaAutosize
                    minRows={3}
                    placeholder="Comment..."
                    className="form-control"
                    style={{ marginTop: "15px" }}
                    onChange={e =>
                      setUserScore({
                        ...userScore,
                        comment: e.target.value,
                        isDirty: true,
                      })
                    }
                    value={userScore.comment || ""}
                  />
                )}
              </Row>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              {nextAltScore != null && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "start",
                  }}
                >
                  {mode == 2 && (
                    <button
                      type="button"
                      className="btn btn-primary mb-2"
                      // style={{ marginRight: "10px" }}
                      onClick={async () =>
                        await loadPrevData(
                          nextAltScore.altScoreID,
                          nextAltScore.levelID,
                          "alt"
                        )
                      }
                    >
                      <i className="fas fa-arrow-left"></i> Project
                    </button>
                  )}
                  {mode == 1 && (
                    <button
                      type="button"
                      className="btn btn-primary  mb-2"
                      // style={{ marginRight: "10px" }}
                      onClick={async () => await submit()}
                    >
                      Submit
                    </button>
                  )}
                  {mode == 2 && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      // style={{ marginRight: "10px" }}
                      onClick={async () =>
                        await loadPrevData(
                          nextAltScore.altScoreID,
                          nextAltScore.levelID,
                          "obj"
                        )
                      }
                    >
                      <i className="fas fa-arrow-left"></i> Previous
                    </button>
                  )}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                {nextAltScore != null && (
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={() => toggleAltModal()}
                  >
                    Go To {scenario && scenario.alternativeName}
                  </button>
                )}
                {nextAltScore == null && showRefresh && (
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={() => loadNextDataGroup()}
                  >
                    Refresh
                  </button>
                )}
                {mode == 2 && (
                  <button
                    type="button"
                    className="btn btn-primary mt-2"
                    style={{ backgroundColor: "white", color: colors.primary }}
                    onClick={async () => exit()}
                  >
                    Exit
                  </button>
                )}
              </div>
              {nextAltScore != null && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                  }}
                >
                  {mode == 2 && (
                    <button
                      type="button"
                      className="btn btn-primary mb-2"
                      onClick={async () =>
                        await loadNextData(
                          nextAltScore.altScoreID,
                          nextAltScore.levelID,
                          "alt"
                        )
                      }
                    >
                      Project
                      <i className="fas fa-arrow-right"></i>
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-primary  mb-2"
                    onClick={async () =>
                      mode == 1
                        ? await loadNextDataGroup()
                        : await loadNextData(
                            nextAltScore.altScoreID,
                            nextAltScore.levelID,
                            "obj"
                          )
                    }
                  >
                    Next
                    <i className="fas fa-arrow-right"></i>
                  </button>
                </div>
              )}
            </div>

            <div>
              <p className="mb-0">
                U.S. Patent Nos. 10,268,977; 10,366,361; 10,417,590
              </p>
              <p>All Rights Reserved</p>
            </div>
          </Col>
        </Row>

        <SelectAlternativeModal
          isOpen={showAltModal}
          toggleModal={toggleAltModal}
          scenarioId={scenarioId}
          scenario={scenario}
          onSelect={loadAlternativeFromModal}
        />
      </div>
    </React.Fragment>
  )
}

export default AlternativeScore
