import SmallLoadingIndicator from "components/custom/SmallLoadingIndicator"
import { swalWithConfirmButton } from "components/custom/swal"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const GeneralSection = ({ enterprise, changeEnterpriseProp, reload }) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [resourcePeriods, setResourcePeriods] = useState([])
  const [portfolioLabels, setPortfolioLabels] = useState([])
  const [modelLabels, setModelLabels] = useState([])
  const [requestTitles, setRequestTitles] = useState([])

  const [isGeneratingAssistant, setIsGeneratingAssistant] = useState(false)

  const months = [
    {
      text: "January",
      value: 1,
    },
    {
      text: "February",
      value: 2,
    },
    {
      text: "March",
      value: 3,
    },
    {
      text: "April",
      value: 4,
    },
    {
      text: "May",
      value: 5,
    },
    {
      text: "June",
      value: 6,
    },
    {
      text: "July",
      value: 7,
    },
    {
      text: "August",
      value: 8,
    },
    {
      text: "September",
      value: 9,
    },
    {
      text: "October",
      value: 10,
    },
    {
      text: "November",
      value: 11,
    },
    {
      text: "December",
      value: 12,
    },
  ]

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    let rs = await api.getList(currentUser.userID, "ResourcePeriod")
    setResourcePeriods(rs)
    let pl = await api.getList(currentUser.userID, "PortfolioLabel")
    setPortfolioLabels(pl)
    let rt = await api.getList(currentUser.userID, "RequestTitle")
    setRequestTitles(rt)
    let ml = await api.getList(currentUser.userID, "ModelLabel")
    setModelLabels(ml)
    setIsLoading(false)
  }

  const createGptAssistant = async () => {
    setIsGeneratingAssistant(true)
    await api.createOpenAiAssistant(enterprise.enterpriseID)
    toast.success("GPT Assistant created successfully")
    reload && reload()
    setIsGeneratingAssistant(false)
  }

  const processEnterpriseFiles = async () => {
    await api.processEnterpriseFiles(enterprise.enterpriseID)
    swalWithConfirmButton.fire({
      title: `GPT Files for your Enterprise are being processed, this might take a few minutes.`,
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Ok",
    })
  }

  const hasGptAssistant =
    enterprise != null &&
    enterprise.gptAssistantID != null &&
    enterprise.gptAssistantID != ""

  return (
    <React.Fragment>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && (
        <>
          <Row>
            <Col sm="6">
              <div className="mb-3">
                <Label className="form-label">Enterprise</Label>
                <Input
                  name="enterpriseName"
                  type="text"
                  className={`${
                    currentUser.roleID !== 110 && "always-disabled"
                  }`}
                  disabled={currentUser.roleID == 110 ? false : true}
                  onChange={e =>
                    changeEnterpriseProp("enterpriseName", e.target.value)
                  }
                  value={enterprise.enterpriseName}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <Label className="form-label">Welcome Message</Label>
                <Input
                  name="welcomeMessage"
                  type="text"
                  onChange={e =>
                    changeEnterpriseProp("welcomeMessage", e.target.value)
                  }
                  value={enterprise.welcomeMessage}
                />
              </div>
            </Col>
          </Row>
          <div className="divider"></div>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Modules</Label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                  }}
                >
                  <Input
                    type="checkbox"
                    className={`form-check-input ${
                      currentUser.roleID !== 110 && "always-disabled"
                    }`}
                    id="useIntake"
                    disabled={currentUser.roleID == 110 ? false : true}
                    defaultChecked={enterprise.useIntake}
                    onChange={e => {
                      changeEnterpriseProp("useIntake", !enterprise.useIntake)
                    }}
                  />
                  <Label
                    className="form-check-label"
                    for="useIntake"
                    style={{ marginLeft: "10px" }}
                  >
                    Intake Portal
                  </Label>
                  <Input
                    type="checkbox"
                    className={`form-check-input ${
                      currentUser.roleID !== 110 && "always-disabled"
                    }`}
                    style={{ marginLeft: "20px" }}
                    id="useBC"
                    defaultChecked={enterprise.useBC}
                    disabled={currentUser.roleID == 110 ? false : true}
                    onChange={e => {
                      changeEnterpriseProp("useBC", !enterprise.useBC)
                    }}
                  />
                  <Label
                    className="form-check-label"
                    for="useBC"
                    style={{ marginLeft: "10px" }}
                  >
                    Business Cases
                  </Label>
                  <Input
                    type="checkbox"
                    className={`form-check-input ${
                      currentUser.roleID !== 110 && "always-disabled"
                    }`}
                    style={{ marginLeft: "20px" }}
                    id="useResource"
                    defaultChecked={enterprise.useResource}
                    disabled={currentUser.roleID == 110 ? false : true}
                    onChange={e => {
                      changeEnterpriseProp(
                        "useResource",
                        !enterprise.useResource
                      )
                    }}
                  />
                  <Label
                    className="form-check-label"
                    for="useResource"
                    style={{ marginLeft: "10px" }}
                  >
                    Resource Management
                  </Label>
                  <Input
                    type="checkbox"
                    className={`form-check-input ${
                      currentUser.roleID !== 110 && "always-disabled"
                    }`}
                    style={{ marginLeft: "20px" }}
                    id="useOptimization"
                    defaultChecked={enterprise.useOptimization}
                    disabled={currentUser.roleID == 110 ? false : true}
                    onChange={e => {
                      changeEnterpriseProp(
                        "useOptimization",
                        !enterprise.useOptimization
                      )
                    }}
                  />
                  <Label
                    className="form-check-label"
                    for="useOptimization"
                    style={{ marginLeft: "10px" }}
                  >
                    Optimization
                  </Label>
                  <Input
                    type="checkbox"
                    className={`form-check-input ${
                      currentUser.roleID !== 110 && "always-disabled"
                    }`}
                    style={{ marginLeft: "20px" }}
                    id="useOkta"
                    defaultChecked={enterprise.useOkta}
                    disabled={currentUser.roleID == 110 ? false : true}
                    onChange={e => {
                      changeEnterpriseProp("useOkta", !enterprise.useOkta)
                    }}
                  />
                  <Label
                    className="form-check-label"
                    for="useOkta"
                    style={{ marginLeft: "10px" }}
                  >
                    Use Okta
                  </Label>

                  <Input
                    type="checkbox"
                    className={`form-check-input ${
                      currentUser.roleID !== 110 && "always-disabled"
                    }`}
                    style={{ marginLeft: "20px" }}
                    id="useAI"
                    defaultChecked={enterprise.useAI}
                    disabled={currentUser.roleID == 110 ? false : true}
                    onChange={e => {
                      changeEnterpriseProp("useAI", !enterprise.useAI)
                    }}
                  />
                  <Label
                    className="form-check-label"
                    for="useAI"
                    style={{ marginLeft: "10px" }}
                  >
                    Use AI
                  </Label>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <div className="mb-3">
                <Label className="form-label">Smartsheet API Key</Label>
                <Input
                  name="smartSheetKey"
                  type="text"
                  onChange={e =>
                    changeEnterpriseProp("smartSheetKey", e.target.value)
                  }
                  value={enterprise.smartSheetKey}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Jira API Key</Label>
                <Input
                  name="jiraKey"
                  type="text"
                  onChange={e =>
                    changeEnterpriseProp("jiraKey", e.target.value)
                  }
                  value={enterprise.jiraKey}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Resource Period</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={enterprise.resourcePeriod || -1}
                  onChange={e => {
                    changeEnterpriseProp("resourcePeriod", e.target.value)
                  }}
                >
                  <option value={-1}>Select Resource Period</option>
                  {resourcePeriods.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.listItemID}`}>
                        {a.listItemName}
                      </option>
                    )
                  })}
                </select>
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <Label className="form-label">Smartsheet Template</Label>
                <Input
                  name="smartSheetTemplate"
                  type="text"
                  onChange={e =>
                    changeEnterpriseProp("smartSheetTemplate", e.target.value)
                  }
                  value={enterprise.smartSheetTemplate}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Jira Base URL</Label>
                <Input
                  name="jiraURL"
                  type="text"
                  onChange={e =>
                    changeEnterpriseProp("jiraURL", e.target.value)
                  }
                  value={enterprise.jiraURL}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Fiscal Year Start</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={enterprise.enterpriseFYMonth}
                  onChange={e => {
                    changeEnterpriseProp("enterpriseFYMonth", e.target.value)
                  }}
                >
                  <option value={-1}>Select...</option>
                  {months.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.value}`}>
                        {a.text}
                      </option>
                    )
                  })}
                </select>
              </div>
            </Col>
          </Row>
          <div className="divider"></div>
          <Row>
            <Col sm="6">
              <div className="mb-3">
                <Label className="form-label">Portfolio Label</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={enterprise.portfolioLabel || -1}
                  onChange={e => {
                    changeEnterpriseProp("portfolioLabel", e.target.value)
                  }}
                >
                  <option value={-1}>Select Portfolio Label</option>
                  {portfolioLabels.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.listItemName}`}>
                        {a.listItemName}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="mb-3">
                <Label className="form-label">Location ID Label</Label>
                <Input
                  name="locationIDLabel"
                  type="text"
                  onChange={e =>
                    changeEnterpriseProp("locationIDLabel", e.target.value)
                  }
                  value={enterprise.locationIDLabel}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Request Title</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={enterprise.requestTitle || -1}
                  onChange={e => {
                    changeEnterpriseProp("requestTitle", e.target.value)
                  }}
                >
                  <option value={-1}>Select Request Title</option>
                  {requestTitles.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.listItemName}`}>
                        {a.listItemName}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="mb-3">
                <Label className="form-label">Notice Cancel URL</Label>
                <Input
                  name="noticeCancelUrl"
                  type="text"
                  onChange={e =>
                    changeEnterpriseProp("noticeCancelUrl", e.target.value)
                  }
                  value={enterprise.noticeCancelUrl}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <Label className="form-label">Model Label</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={enterprise.modelLabel || -1}
                  onChange={e => {
                    changeEnterpriseProp("modelLabel", e.target.value)
                  }}
                >
                  <option value={-1}>Select Model Label</option>
                  {modelLabels.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.listItemName}`}>
                        {a.listItemName}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="mb-3">
                <Label className="form-label">Location Category Label</Label>
                <Input
                  name="locationCategoryLabel"
                  type="text"
                  onChange={e =>
                    changeEnterpriseProp(
                      "locationCategoryLabel",
                      e.target.value
                    )
                  }
                  value={enterprise.locationCategoryLabel}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">
                  Business Case Submit Button Text
                </Label>
                <Input
                  name="bcFormButtonText"
                  type="text"
                  onChange={e =>
                    changeEnterpriseProp("bcFormButtonText", e.target.value)
                  }
                  value={enterprise.bcFormButtonText}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Notice Enter Button Text</Label>
                <Input
                  name="enterButtonText"
                  type="text"
                  onChange={e =>
                    changeEnterpriseProp("enterButtonText", e.target.value)
                  }
                  value={enterprise.enterButtonText}
                />
              </div>
            </Col>
          </Row>
          <div className="divider"></div>
          <Row>
            <Col sm="12">
              <div className="mb-3">
                <Label className="form-label">
                  System Notification Message
                </Label>
                <Input
                  name="loginMessage"
                  type="textarea"
                  rows={5}
                  onChange={e =>
                    changeEnterpriseProp("loginMessage", e.target.value)
                  }
                  value={enterprise.loginMessage}
                />
              </div>
              <div className="mb-3">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="showLoginMessage"
                  defaultChecked={enterprise.showLoginMessage}
                  onChange={e => {
                    changeEnterpriseProp(
                      "showLoginMessage",
                      !enterprise.showLoginMessage
                    )
                  }}
                />
                <Label
                  className="form-check-label"
                  for="showLoginMessage"
                  style={{ marginLeft: "10px" }}
                >
                  Show System Notification Message
                </Label>
              </div>
              {!hasGptAssistant && currentUser.roleID > 100 && (
                <div className="mb-3">
                  <button
                    className="btn btn-primary"
                    onClick={createGptAssistant}
                    disabled={isGeneratingAssistant}
                  >
                    <i className="mdi mdi-brain"></i> Generate GPT Assistant{" "}
                    {isGeneratingAssistant && <SmallLoadingIndicator />}
                  </button>
                </div>
              )}
              {hasGptAssistant && currentUser.roleID == 110 && (
                <button
                  className="btn btn-primary"
                  onClick={processEnterpriseFiles}
                >
                  Process Enterprise Files
                </button>
              )}
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  )
}

export default GeneralSection
