import { Tooltip } from "@material-ui/core"
import { swalWithConfirmButton } from "components/custom/swal"
import Widgets from "components/Widgets"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import OptimizationAlert from "../Components/optimizationAlert"
import OptimizeModal from "./OptimizeModal"
import { exportTableToExcel } from "utils/exportToExcel"

const OptimizationSingle = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const { optObjFin } = useSelector(state => ({
    optObjFin: state.optObjFin.optObjFin,
  }))

  const [costVariableSummary, setCostVariableSummary] = useState([])
  const [isOptimizeModalVisible, setIsOptimizeModalVisible] = useState(false)
  const [isChartModalVisible, setIsChartModalVisible] = useState(false)
  const [isResourceModalVisible, setIsResourceModalVisible] = useState(false)
  const [isGroupModalVisible, setIsGroupModalVisible] = useState(false)
  const [isCVModalVisible, setIsCVModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isOptModelLoading, setOptModelIsLoading] = useState(false)
  const [optModel, setOptModel] = useState(null)

  const [optResults, setOptResults] = useState([])
  const [scenario, setScenario] = useState(null)
  const [optSeries, setOptSeries] = useState(null)
  const [mixChart, setMixChart] = useState(null)
  const [widgets, setWidgets] = useState([])
  const [resourceGroupResults, setResourceGroupResults] = useState([])
  const [optGroupResults, setOptGroupResults] = useState([])

  const [sortBy, setSortBy] = useState("")
  const [sortDirection, setSortDirection] = useState("asc")

  const [showActionsDropdown, setShowActionsDropdown] = useState(false)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "optimizationSingle",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "optimizationSingle",
      })
    )
  }, [])

  useEffect(() => {
    loadData()
  }, [optObjFin])

  const loadData = async () => {
    setIsLoading(true)

    try {
      let scen = await api.getScenario(scenarioId)
      setScenario(scen)
      await loadWidgets(scen)
      let opt = await loadOptModel(
        optObjFin != null && optObjFin.opt != null
          ? optObjFin.opt.optModelID
          : scen.currentOptModelID
      )
      await loadOptResults(opt)

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const loadWidgets = async scen => {
    let w = await api.getWidgetsByView(
      "OptimizationSingle",
      optObjFin != null && optObjFin.opt != null
        ? optObjFin.opt.optModelID
        : scen == undefined
        ? scenario.currentOptModelID
        : scen.currentOptModelID
    )
    setWidgets(w)
  }

  const loadOptModel = async optModelId => {
    setOptModelIsLoading(true)
    let data = await api.getOptModel(optModelId)
    setOptModel(data)
    setOptModelIsLoading(false)
    return data
  }

  const loadOptResults = async opt => {
    let data = await api.getOptResults(
      scenarioId,
      opt?.objectiveID ?? 0,
      opt?.optModelID ?? 0,
      opt?.objModelID ?? 0,
      opt?.finModelID ?? 0
    )
    setOptResults(data)
  }

  const saveOptimization = async resourceGroupResults => {
    try {
      let itemsToUpdate = resourceGroupResults.filter(x => x.isDirty)
      if (itemsToUpdate.length > 0) {
        let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
          return await api.updateResourceGroupResult(currentUser.userID, x)
        })
        await Promise.all(itemsToUpdateTasks.map(t => t()))
      }

      let res = await api.runSingleOptimization(currentUser.userID, optModel)
      if (res.toString().toLowerCase() == "optimized") {
        loadOptResults(optModel)
        loadMixChart(optModel.optModelID)
        toggleOptimizeModal()
      } else {
        swalWithConfirmButton.fire({
          title: res,
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
        })
      }
    } catch (err) {
      console.log(err)
    }
    // loadResourceGroupResults(scenario.currentOptModelID)
  }

  const loadMixChart = async optModelId => {
    let chart = await api.getMixChart(optModelId, 0)
    setMixChart(chart)
    let opts = {
      series: chart.chartTable.map(c => c.costContribution),
      options: {
        chart: {
          width: "100%",
          type: "pie",
          donut: {
            size: "65%",
          },
        },
        labels: chart.chartTable.map(c => c.objective),
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return currencyFormatter.format(val)
            },
          },
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 600,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    }
    setOptSeries(opts)
  }

  const toggleChartModal = () => setIsChartModalVisible(!isChartModalVisible)
  const toggleResourceModal = () =>
    setIsResourceModalVisible(!isResourceModalVisible)
  const toggleGroupModal = () => setIsGroupModalVisible(!isGroupModalVisible)
  const toggleCVModal = () => setIsCVModalVisible(!isCVModalVisible)

  const loadResourceGroupResults = async optModelId => {
    let results = await api.getResourceGroupResults(optModelId)
    setResourceGroupResults(results)
  }

  const loadOptGroupResults = async optModelId => {
    let results = await api.getOptModelGroups(optModelId)
    setOptGroupResults(results)
  }

  const loadCVResults = async optModelId => {
    let results = await api.getCostVariableSummary(optModelId)
    setCostVariableSummary(results)
  }

  const toggleOptimizeModal = () =>
    setIsOptimizeModalVisible(!isOptimizeModalVisible)

  const changeOptModelProp = (prop, value) => {
    setOptModel({
      ...optModel,
      [prop]: value,
    })
  }

  const openOptimizeModal = () => {
    toggleOptimizeModal()
    loadOptModel(scenario.currentOptModelID)
  }

  const openChartModal = () => {
    toggleChartModal()
    loadMixChart(optModel.optModelID)
  }

  const openResourceModal = () => {
    toggleResourceModal()
    loadResourceGroupResults(optModel.optModelID)
  }

  const openGroupModal = () => {
    toggleGroupModal()
    loadOptGroupResults(optModel.optModelID)
  }

  const openCVModal = () => {
    toggleCVModal()
    loadCVResults(optModel.optModelID)
  }

  const formattedCost =
    optModel == null
      ? 0
      : Intl.NumberFormat("en-US", {
          notation: "compact",
          maximumFractionDigits: 1,
        }).format(optModel.costConstraint)

  const getSortIcon = prop => {
    let className = ""
    if (sortBy == prop) {
      className =
        sortDirection == "asc" ? "fas fa-chevron-down" : "fas fa-chevron-up"
    } else {
      className = "fas fa-chevron-down"
    }
    return (
      <i
        className={className}
        style={{ padding: "0px", marginLeft: "5px" }}
      ></i>
    )
  }

  const handleSort = prop => {
    let direction = ""
    if (sortBy == prop) {
      direction = sortDirection == "asc" ? "desc" : "asc"
    } else {
      direction = "asc"
      setSortBy(prop)
    }
    setSortDirection(direction)

    setOptResults(
      direction == "asc"
        ? sortAsc(optResults, prop)
        : sortDesc(optResults, prop)
    )
  }

  const sortAsc = (data, prop) => {
    function compare(a, b) {
      if (a[prop] < b[prop]) {
        return -1
      }
      if (a[prop] > b[prop]) {
        return 1
      }
      return 0
    }
    let ret = [...data].sort(compare)
    return ret
  }
  const sortDesc = (data, prop) => {
    function compare(a, b) {
      if (b[prop] < a[prop]) {
        return -1
      }
      if (b[prop] > a[prop]) {
        return 1
      }
      return 0
    }
    let ret = [...data].sort(compare)
    return ret
  }

  const exportToExcel = () => {
    exportTableToExcel(
      optResults,
      [
        { property: "alternativeNum", heading: "ID" },
        { property: "alternativeName", heading: "Project" },
        { property: "planningCategory", heading: "Planning Category" },
        { property: "benefitScore", heading: "Benefit Score" },
        { property: "mustHave", heading: "Must" },
        { property: "cantHave", heading: "Must Not" },
        { property: "partial", heading: "Allow Partial" },
        { property: "dependencies", heading: "Dependencies" },
        { property: "resourceGroups", heading: "Resources" },
        { property: "groupConstraints", heading: "Group Constraints" },
        { property: "optSelected", heading: "Selected" },
        { property: "optScore", heading: "Selected Benefit Score" },
        { property: "optCost", heading: "Selected Cost" },
      ],
      "Optimization.csv"
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Widgets widgets={widgets} marginBottom="20px" />
                  {optModel != null && (
                    <Row>
                      <Col>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>
                              <b>Cost Constraint</b>
                            </span>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <h1>${formattedCost}</h1>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginLeft: "15px",
                                }}
                              >
                                <span>
                                  Optimizing: <b>{optModel.optimizeOn}</b>
                                </span>
                                <span>
                                  Type: <b>{optModel.minMax}</b>
                                </span>
                                <span>
                                  Cost Variable:{" "}
                                  <b>{optModel.costVariableName}</b>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span>
                              <b>Other Constraints</b>
                            </span>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>
                                  Musts:{" "}
                                  <b>
                                    {optModel.useMust == 0 ? (
                                      <span style={{ color: "red" }}>NO</span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        YES ({optModel.numMust})
                                      </span>
                                    )}
                                  </b>
                                </span>
                                <span>
                                  Must Nots:{" "}
                                  <b>
                                    {optModel.useCant == 0 ? (
                                      <span style={{ color: "red" }}>NO</span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        YES ({optModel.numCant})
                                      </span>
                                    )}
                                  </b>
                                </span>
                                <span>
                                  Partials:{" "}
                                  <b>
                                    {optModel.usePartial == 0 ? (
                                      <span style={{ color: "red" }}>NO</span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        YES ({optModel.numPartial})
                                      </span>
                                    )}
                                  </b>
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginLeft: "15px",
                                }}
                              >
                                <span>
                                  Group:{" "}
                                  <b>
                                    {optModel.useResource == 0 ? (
                                      <span style={{ color: "red" }}>NO</span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        YES ({optModel.numGroup})
                                      </span>
                                    )}
                                  </b>
                                </span>
                                <span>
                                  Resource:{" "}
                                  <b>
                                    {optModel.useResourceGroups == 0 ? (
                                      <span style={{ color: "red" }}>NO</span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        YES ({optModel.numResource})
                                      </span>
                                    )}
                                  </b>
                                </span>
                                <span>
                                  Dependencies:{" "}
                                  <b>
                                    {optModel.useDependencies == 0 ? (
                                      <span style={{ color: "red" }}>NO</span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        YES ({optModel.numDependency})
                                      </span>
                                    )}
                                  </b>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span>
                              <b>Scope / Models</b>
                            </span>
                            <span>
                              Scope: <b>{optModel.scope}</b>
                            </span>
                            <span>
                              Objective Model:{" "}
                              <b>
                                {optModel.objModelName == null
                                  ? "None"
                                  : optModel.objModelName}
                              </b>
                            </span>
                            <span>
                              Financial Model: <b>{optModel.finModelName}</b>
                            </span>
                          </div>
                          <div
                            className="mb-3"
                            style={{ alignSelf: "end", display: "flex" }}
                          >
                            <button
                              className="btn btn-primary"
                              onClick={openOptimizeModal}
                            >
                              <i className="mdi mdi-cog-clockwise"></i> Modify
                              and Run
                            </button>

                            <Dropdown
                              // style={{ display: "inline-block" }}
                              isOpen={showActionsDropdown}
                              toggle={() =>
                                setShowActionsDropdown(!showActionsDropdown)
                              }
                            >
                              <DropdownToggle
                                tag="button"
                                className="btn btn-outline-primary save-user"
                              >
                                Action <i className="mdi mdi-chevron-down" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={exportToExcel}>
                                  <i className="fas fa-file-excel"></i> Export
                                  Results to Excel
                                </DropdownItem>

                                <DropdownItem onClick={openCVModal}>
                                  <i className="mdi mdi-currency-usd-circle-outline"></i>{" "}
                                  Show Cost Variables
                                </DropdownItem>

                                <DropdownItem onClick={openGroupModal}>
                                  <i className="mdi mdi-select-group"></i> Show
                                  Groups
                                </DropdownItem>
                                <DropdownItem onClick={openChartModal}>
                                  <i className="mdi mdi-chart-pie"></i> Show
                                  Objective Mix
                                </DropdownItem>
                                <DropdownItem onClick={openResourceModal}>
                                  <i className="mdi mdi-account-multiple-check-outline"></i>{" "}
                                  Show Resources
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <OptimizationAlert optModel={optModel} />
                  <Row style={{ marginTop: "10px" }}>
                    <Col style={{ overflowX: "auto" }}>
                      {isLoading && (
                        <Loader
                          type="line-scale-pulse-out"
                          color={colors.primary}
                          style={{ textAlign: "center" }}
                        />
                      )}
                      {!isLoading && optResults.length > 0 && (
                        <>
                          {/*                           <button
                            className="btn btn-primary"
                            style={{
                              float: "right",
                            }}
                            onClick={exportToExcel}
                          >
                            <i className="fas fa-file-excel"></i> Export to Excel
                          </button> */}
                          <table
                            className="table table-bordered lower-padding-table"
                            style={{ backgroundColor: "white" }}
                          >
                            <thead>
                              <tr>
                                <th
                                  onClick={() => handleSort("alternativeNum")}
                                  style={{ width: "2%" }}
                                >
                                  ID {getSortIcon("alternativeNum")}
                                </th>
                                {scenarioId < 0 && (
                                  <th
                                    onClick={() => handleSort("scenario")}
                                    style={{ width: "10%" }}
                                  >
                                    Model {getSortIcon("scenario")}
                                  </th>
                                )}
                                <th
                                  onClick={() => handleSort("alternativeName")}
                                  style={{ width: "10%" }}
                                >
                                  Project {getSortIcon("alternativeName")}
                                </th>
                                {/*                               <th style={{ width: "6%" }}>Lead</th>
                              <th style={{ width: "5%" }}>Sponsor</th> */}
                                <th
                                  onClick={() => handleSort("version")}
                                  style={{ width: "3%" }}
                                >
                                  Version {getSortIcon("version")}
                                </th>
                                <th
                                  onClick={() => handleSort("planningCategory")}
                                  style={{ width: "8%" }}
                                >
                                  Planning Category{" "}
                                  {getSortIcon("planningCategory")}
                                </th>
                                <th
                                  onClick={() => handleSort("benefitScore")}
                                  style={{ minWidth: "120px" }}
                                >
                                  Benefit Score {getSortIcon("benefitScore")}
                                </th>
                                <th
                                  onClick={() => handleSort("groupRating")}
                                  style={{ minWidth: "100px" }}
                                >
                                  Group Rating {getSortIcon("groupRating")}
                                </th>
                                <th
                                  onClick={() => handleSort("cost")}
                                  style={{ width: "5%" }}
                                >
                                  Cost {getSortIcon("cost")}
                                </th>
                                <th
                                  onClick={() => handleSort("costBenefit")}
                                  style={{ width: "4%" }}
                                >
                                  Benefit Score / Cost{" "}
                                  {getSortIcon("costBenefit")}
                                </th>
                                <th
                                  onClick={() => handleSort("mustHave")}
                                  style={{ width: "3%" }}
                                >
                                  Must {getSortIcon("mustHave")}
                                </th>
                                <th
                                  onClick={() => handleSort("cantHave")}
                                  style={{ width: "3%" }}
                                >
                                  Must Not {getSortIcon("cantHave")}
                                </th>
                                <th
                                  onClick={() => handleSort("partial")}
                                  style={{ width: "3%" }}
                                >
                                  Allow Partial {getSortIcon("partial")}
                                </th>
                                {optModel.useDependencies && (
                                  <th
                                    onClick={() => handleSort("dependencies")}
                                    style={{ width: "5%" }}
                                  >
                                    Dependencies {getSortIcon("dependencies")}
                                  </th>
                                )}
                                <th
                                  onClick={() => handleSort("resourceGroups")}
                                  style={{ width: "15%" }}
                                >
                                  Resources {getSortIcon("resourceGroups")}
                                </th>
                                <th
                                  onClick={() => handleSort("groupConstraints")}
                                  style={{ width: "5%" }}
                                >
                                  Group Constraints{" "}
                                  {getSortIcon("groupConstraints")}
                                </th>
                                <th
                                  onClick={() => handleSort("optSelected")}
                                  style={{ width: "5%" }}
                                >
                                  Selected {getSortIcon("optSelected")}
                                </th>
                                <th style={{ width: "5%" }}>Selected % </th>
                                <th
                                  onClick={() => handleSort("optScore")}
                                  style={{ width: "5%" }}
                                >
                                  Selected Benefit Score{" "}
                                  {getSortIcon("optScore")}
                                </th>
                                <th
                                  onClick={() => handleSort("optCost")}
                                  style={{ width: "5%" }}
                                >
                                  Selected Cost {getSortIcon("optCost")}
                                </th>
                                <th style={{ width: "5%" }}>
                                  Selected Benefit Score / Cost
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {optResults.map((r, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>{r.alternativeNum}</td>
                                    {scenarioId < 0 && (
                                      <td>
                                        <div className="one-line-elipsis">
                                          <Tooltip
                                            title={<h6>{r.scenario}</h6>}
                                          >
                                            <span>
                                              <b>{r.scenario}</b>
                                            </span>
                                          </Tooltip>
                                        </div>
                                      </td>
                                    )}
                                    <td>
                                      <div className="one-line-elipsis">
                                        <Tooltip
                                          title={<h6>{r.alternativeName}</h6>}
                                        >
                                          <span>
                                            <b>{r.alternativeName}</b>
                                          </span>
                                        </Tooltip>
                                      </div>
                                    </td>
                                    {/*                                   <td>
                                    <div className="one-line-elipsis">
                                      <Tooltip title={<h6>{r.lead}</h6>}>
                                        <span>{r.lead}</span>
                                      </Tooltip>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="one-line-elipsis">
                                      <Tooltip
                                        title={<h5>{r.sponsoringUnit}</h5>}
                                      >
                                        <span>{r.sponsoringUnit}</span>
                                      </Tooltip>
                                    </div>
                                  </td> */}
                                    <td>
                                      <div className="one-line-elipsis">
                                        <div
                                          style={{ marginLeft: "5px" }}
                                          dangerouslySetInnerHTML={{
                                            __html: r.version,
                                          }}
                                        ></div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="one-line-elipsis">
                                        <Tooltip
                                          title={<h6>{r.planningCategory}</h6>}
                                        >
                                          <div
                                            style={{ marginLeft: "10px" }}
                                            dangerouslySetInnerHTML={{
                                              __html: r.planningCategory,
                                            }}
                                          ></div>
                                        </Tooltip>
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: r.benefitScore,
                                        }}
                                      ></div>
                                    </td>
                                    <td>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: r.groupRating,
                                        }}
                                      ></div>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                      {currencyFormatter.format(r.cost)}
                                    </td>
                                    <td>
                                      {r.costBenefit == null
                                        ? 0
                                        : r.costBenefit.toFixed(2)}
                                    </td>
                                    <td className="text-center">
                                      <Input
                                        type="checkbox"
                                        checked={r.mustHave}
                                        readOnly={true}
                                      />
                                    </td>
                                    <td className="text-center">
                                      <Input
                                        type="checkbox"
                                        checked={r.cantHave}
                                        readOnly={true}
                                      />
                                    </td>
                                    <td className="text-center">
                                      <Input
                                        type="checkbox"
                                        checked={r.partial}
                                        readOnly={true}
                                      />
                                    </td>
                                    {optModel.useDependencies && (
                                      <td>{r.dependencies}</td>
                                    )}
                                    <td>{r.resourceGroups}</td>
                                    <td>{r.groupConstraints}</td>
                                    <td
                                      className="text-center"
                                      style={{
                                        backgroundColor: r.optSelected
                                          ? "lightgreen"
                                          : "transparent",
                                      }}
                                    >
                                      <Input
                                        type="checkbox"
                                        checked={r.optSelected}
                                        readOnly={true}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: r.optSelected
                                          ? "lightgreen"
                                          : "transparent",
                                      }}
                                    >
                                      {(r.optAmount * 100).toFixed(2)}%
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: r.optSelected
                                          ? "lightgreen"
                                          : "transparent",
                                      }}
                                    >
                                      {r.optScore}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "right",
                                        backgroundColor: r.optSelected
                                          ? "lightgreen"
                                          : "transparent",
                                      }}
                                    >
                                      {currencyFormatter.format(r.optCost)}
                                    </td>
                                    <td
                                      style={{
                                        backgroundColor: r.optSelected
                                          ? "lightgreen"
                                          : "transparent",
                                      }}
                                    >
                                      {isNaN(
                                        parseFloat(
                                          r.optScore.replace("%", "")
                                        ) / r.optCost
                                      )
                                        ? 0
                                        : (
                                            (parseFloat(
                                              r.optScore.replace("%", "")
                                            ) /
                                              r.optCost) *
                                            10000
                                          ).toFixed(2)}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <OptimizeModal
        scenario={scenario}
        isOpen={isOptimizeModalVisible}
        toggleModal={toggleOptimizeModal}
        optModel={optModel}
        isOptModelLoading={isOptModelLoading}
        saveOptimizationSingle={saveOptimization}
        changeOptModelProp={changeOptModelProp}
        reloadData={loadWidgets}
      />

      <Modal
        backdrop="static"
        isOpen={isChartModalVisible}
        size="lg"
        toggle={() => {
          toggleChartModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Selection Mix and Balance by Objective
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleChartModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {optSeries != null && (
            <div className="mb-3">
              <ReactApexChart
                options={optSeries.options}
                series={optSeries.series}
                type="pie"
                width="100%"
                height="600px"
              />
            </div>
          )}

          {mixChart !== null && (
            <div className="mb-3">
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <th style={{ width: "60%" }}>Objective</th>
                  <th style={{ width: "20%" }}>Cost Allocation</th>
                  <th style={{ width: "20%" }}>Percent (%)</th>
                </thead>
                <tbody>
                  {mixChart.chartTable.map((m, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{m.objective}</td>
                        <td style={{ textAlign: "right" }}>
                          {currencyFormatter.format(m.costContribution)}
                        </td>
                        <td>{m.percentContribution.toFixed(2)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <div>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={toggleChartModal}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        backdrop="static"
        isOpen={isResourceModalVisible}
        size="lg"
        toggle={() => {
          toggleResourceModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Resource Results
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleResourceModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label>Resource Constraints</Label>
            <table
              className="table table-bordered low-padding-table"
              style={{ backgroundColor: "white" }}
            >
              <thead>
                <th style={{ width: "10%" }}>Resource</th>
                <th style={{ width: "10%" }}>Minimum</th>
                <th style={{ width: "10%" }}>Maximum</th>
                <th style={{ width: "15%" }}>Total Required</th>
                <th style={{ width: "15%" }}>Selected</th>
                <th style={{ width: "15%" }}>% of Max</th>
                <th style={{ width: "15%" }}>% of Total Required</th>
                <th style={{ width: "10%" }}>Active</th>
              </thead>
              <tbody>
                {resourceGroupResults.length > 0 &&
                  resourceGroupResults.map((c, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{c.resourceGroupAbb}</td>
                        <td style={{ textAlign: "right" }}>
                          {c.minAmount.toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {c.maxAmount.toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {c.totalResources.toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {c.selectedAmount.toLocaleString()}
                        </td>
                        <td>{c.percentOfMax}</td>
                        <td>{c.percentOfTotal}</td>
                        <td>
                          <Input
                            type="checkbox"
                            checked={c.active}
                            disabled
                            onClick={() => {
                              let copy = [...resourceGroupResults]
                              let item = copy.find(
                                x => x.resourceGroupID == c.resourceGroupID
                              )
                              item.active = !item.active
                              item.isDirty = true
                              setResourceGroupResults(copy)
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-footer">
          <div>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={toggleResourceModal}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        backdrop="static"
        isOpen={isGroupModalVisible}
        size="lg"
        toggle={() => {
          toggleGroupModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Group Results
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleGroupModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label>Group Constraints</Label>
            <table
              className="table table-bordered low-padding-table"
              style={{ backgroundColor: "white" }}
            >
              <thead>
                <th style={{ width: "25%" }}>Group</th>
                <th style={{ width: "10%" }}>Minimum</th>
                <th style={{ width: "10%" }}>Maximum</th>
                <th style={{ width: "10%" }}>Selected</th>
                <th style={{ width: "10%" }}>% of Max</th>
                <th style={{ width: "5%" }}>Active</th>
              </thead>
              <tbody>
                {optGroupResults.length > 0 &&
                  optGroupResults.map((g, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{g.groupName}</td>
                        <td style={{ textAlign: "right" }}>
                          {g.minSelections.toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {g.maxSelections.toLocaleString()}
                        </td>
                        <td style={{ textAlign: "right" }}>{g.numSelected}</td>
                        <td>{g.percentOfMax}</td>
                        <td>
                          <Input
                            type="checkbox"
                            checked={g.active}
                            disabled
                            /*                               onClick={() => {
                                let copy = [...resourceGroupResults]
                                let item = copy.find(
                                  x => x.resourceGroupID == c.resourceGroupID
                                )
                                item.active = !item.active
                                item.isDirty = true
                                setResourceGroupResults(copy)
                              }} */
                          />
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-footer">
          <div>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={toggleGroupModal}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        backdrop="static"
        isOpen={isCVModalVisible}
        size="lg"
        toggle={() => {
          toggleCVModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Cost Variables
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleCVModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label>Cost Variables</Label>
            <table
              className="table table-bordered low-padding-table"
              style={{ backgroundColor: "white" }}
            >
              <thead>
                <th style={{ width: "40%" }}>Cost Variable</th>
                <th style={{ width: "15%" }}>Musts</th>
                <th style={{ width: "15%" }}>Selected</th>
                <th style={{ width: "15%" }}>Total</th>
                <th style={{ width: "15%" }}>%</th>
              </thead>
              <tbody>
                {costVariableSummary.map((c, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{c.costVariableName}</td>
                      <td style={{ textAlign: "right" }}>
                        {currencyFormatter.format(c.totalMust)}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {currencyFormatter.format(c.selectedValue)}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {currencyFormatter.format(c.totalValue)}
                      </td>
                      <td>{(c.selectedPercent * 100).toFixed(2)}%</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-footer">
          <div>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={toggleCVModal}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default OptimizationSingle
