import Breadcrumbs from "../../components/Common/Breadcrumb"
import AddEditParticipantModal from "pages/Participants/AddEditParticipantModal"
import React, { useEffect, useState } from "react"
import { Col, Container, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import ParticipantsGrid from "./ParticipantsGrid"
import { useDispatch, useSelector } from "react-redux"
import { changeNavbarParams, loadPageItem } from "store/actions"
import { useUserRight } from "services/role.service"

const ManageScoring = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  //   useUserRight(pageItem, props)
  const [scenario, setScenario] = useState(null)
  const [triggerSave, setTriggerSave] = useState(false)

  useEffect(() => {
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "managescoring",
      })
    )
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "managescoring",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    await loadScenario()
  }

  const loadScenario = async () => {
    let s = await api.getScenario(scenarioId)
    setScenario(s)
  }

  const save = () => {
    setTriggerSave(true)
    setTimeout(() => {
      setTriggerSave(false)
    }, 500)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "end",
                }}
              >
                <button id="saveManageScoring" className="btn btn-primary" onClick={() => save()}>
                  <i className="fas fa-save"></i> Save Changes
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              md={
                scenario != null && scenario.secondaryScenarioID > 0 ? "6" : 12
              }
            >
              <ParticipantsGrid
                scenarioId={scenarioId}
                triggerSave={triggerSave}
              />
            </Col>
            {scenario != null && scenario.secondaryScenarioID > 0 && (
              <Col md="6">
                <ParticipantsGrid
                  scenarioId={scenario.secondaryScenarioID}
                  triggerSave={triggerSave}
                  isSecondary={true}
                />
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManageScoring
