import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { MapWrapped } from "../LocationSection"

const AlternativesLocationModal = ({ isOpen, toggleModal, alternatives }) => {
  const currentUser = userService.getLoggedInUser()
  const mapRef = useRef(null)
  const [markers, setMarkers] = useState([])
  const [bounds, setBounds] = useState(null)
  const [locations, setLocations] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      loadLocations()
    }
  }, [isOpen])

  useEffect(() => {
    if (locations.length > 0) {
      setMarkers(
        locations.map((l, idx) => {
          let alt = alternatives.find(x => x.alternativeid == l.alternativeID)
          return {
            lat: l.lat,
            lng: l.lon,
            id: idx,
            alternativeNum: alt != null ? alt.alternativenum : "",
            alternativeName: alt != null ? alt.alternativename : "",
          }
        })
      )
      if (window.google == undefined) {
        setTimeout(() => {
          var b = new window.google.maps.LatLngBounds()
          for (var i = 0; i < locations.length; i++) {
            b.extend({ lat: locations[i].lat, lng: locations[i].lon })
          }
          b.extend({
            lat: locations[locations.length - 1].lat + 0.5,
            lng: locations[locations.length - 1].lon - 0.5,
          })
          if (mapRef.current) mapRef.current.fitBounds(b)
        }, 1500)
      } else {
        var b = new window.google.maps.LatLngBounds()
        for (var i = 0; i < locations.length; i++) {
          b.extend({ lat: locations[i].lat, lng: locations[i].lon })
        }
        b.extend({
          lat: locations[locations.length - 1].lat + 0.5,
          lng: locations[locations.length - 1].lon - 0.5,
        })
        if (mapRef.current) mapRef.current.fitBounds(b)
      }
    } else {
      setMarkers([])
    }
  }, [locations])

  const loadLocations = async () => {
    setIsLoading(true)
    let locs = await api.getAlternativesLocations(
      alternatives.map(x => x.alternativeid).join(",")
    )
    setLocations(locs)
    setIsLoading(false)
  }

  const save = async () => {}

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabe3">
          Locations
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            {isLoading && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center" }}
              />
            )}
            {!isLoading && locations.length == 0 && (
              <h4 className="text-center">No Data</h4>
            )}
            <MapWrapped
              mapRef={mapRef}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyA9GCVC7tKsdhlBU0HLkar9mlVSLKyWrgY&v=3.exp&libraries=geometry,drawing,places}`}
              loadingElement={
                <div style={{ height: "500px", width: "100%" }} />
              }
              containerElement={
                <div style={{ height: `500px`, width: "100%" }} />
              }
              mapElement={<div style={{ height: `500px`, width: "100%" }} />}
              markers={markers}
              defaultCenter={{ lat: 38.897, lng: -77.03985 }}
              bounds={bounds}
            />
          </Col>
        </Row>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default AlternativesLocationModal
