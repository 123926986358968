import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"

const BubbleChart = ({
  isOpen,
  toggleModal,
  scenarioId,
  alternativeList,
  chartOption,
  optObjFin,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [minX, setMinX] = useState(0)
  const [maxX, setMaxX] = useState(0)
  const [minY, setMinY] = useState(0)
  const [maxY, setMaxY] = useState(0)
  const [xAxisAuto, setXAxisAuto] = useState(true)
  const [yAxisAuto, setYAxisAuto] = useState(true)
  const [xAxisField, setXAxisField] = useState("Cost")
  const [showNames, setShowNames] = useState(false)
  const [q1, setQ1] = useState("")
  const [q2, setQ2] = useState("")
  const [q3, setQ3] = useState("")
  const [q4, setQ4] = useState("")
  const [chartOption1, setChartOption1] = useState(true)
  const [ratingScales, setRatingScales] = useState([])
  const [scenario,setScenario] = useState(null)

  useEffect(() => {
    loadRatingScales()
  }, [])

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadRatingScales = async () => {
    let rs = await api.getRatingScales(scenarioId, "secondary-output")
    setRatingScales(rs)
  }

  const loadData = async () => {
    setIsLoading(true)

    let s = await api.getScenario(scenarioId)
    setScenario(s)

    if (
      document.getElementById("chartdiv") !== undefined &&
      document.getElementById("chartdiv") !== null
    ) {
      document.getElementById("chartdiv").innerHTML = ""
    }
    let { obj, fin } = optObjFin
    let chartResponse = await api.getAlternativesBubbleChart(
      scenarioId,
      obj == null ? 0 : obj.objModelID,
      fin == null ? 0 : fin.finModelID,
      alternativeList.join(","),
      xAxisField,
      showNames,
      chartOption1 ? 1 : 2
    )

    let extraDataArray = chartResponse.chartData.split(",")
    let q1Color = extraDataArray
      .find(x => x.indexOf("Q1Color") > -1)
      .trim()
      .replace("Q1Color:", "")
    let q2Color = extraDataArray
      .find(x => x.indexOf("Q2Color") > -1)
      .trim()
      .replace("Q2Color:", "")
    let q3Color = extraDataArray
      .find(x => x.indexOf("Q3Color") > -1)
      .trim()
      .replace("Q3Color:", "")
    let q4Color = extraDataArray
      .find(x => x.indexOf("Q4Color") > -1)
      .trim()
      .replace("Q4Color:", "")
    let xAxisLabel = extraDataArray
      .find(x => x.indexOf("XAxisLabel") > -1)
      .trim()
      .replace("XAxisLabel:", "")
    let yAxisLabel = extraDataArray
      .find(x => x.indexOf("YAxisLabel") > -1)
      .trim()
      .replace("YAxisLabel:", "")
    let xFormat = extraDataArray
      .find(x => x.indexOf("XFormat") > -1)
      .trim()
      .replace("XFormat:", "")
    let xFormat2 = extraDataArray
      .find(x => x.indexOf("XFormat2") > -1)
      .trim()
      .replace("XFormat2:", "")
    let defaultMaxCost = extraDataArray
      .find(x => x.indexOf("DefaultMaxCost") > -1)
      .trim()
      .replace("DefaultMaxCost:", "")
    // let data = JSON.parse(
    //  chartResponse.chartArray.replaceAll("'", '"')
    // )

    var dataStr = chartResponse.chartArray
    var data = JSON.parse(dataStr)
    if(chartOption1) {
      var chart = anychart.quadrant(data)
    } else {
      var chart = anychart.bubble(data)
    }

    chart.quarters({
      rightTop: {
        fill: anychart.color.lighten(q2Color, 0),
        title: {
          text:
            q2 !== ""
              ? q2
              : `Quadrant 2: Higher ${yAxisLabel}, Higher ${xAxisLabel}`,
          padding: 10,
          fontStyle: "italic",
          fontFamily: "Inter",
          fontWeight: "bold",
        },
      },
      rightBottom: {
        fill: anychart.color.lighten(q4Color, 0),
        title: {
          text:
            q4 !== ""
              ? q4
              : `Quadrant 4: Lower ${yAxisLabel}, Higher ${xAxisLabel}`,
          padding: 10,
          fontStyle: "italic",
          fontFamily: "Inter",
          fontWeight: "bold",
        },
      },
      leftTop: {
        fill: anychart.color.lighten(q1Color, 0),
        title: {
          text:
            q1 !== ""
              ? q1
              : `Quadrant 1: Higher ${yAxisLabel}, Lower ${xAxisLabel}`,
          padding: 10,
          fontStyle: "italic",
          fontFamily: "Inter",
          fontWeight: "bold",
        },
      },
      leftBottom: {
        fill: anychart.color.lighten(q3Color, 0),
        title: {
          text:
            q3 !== ""
              ? q3
              : `Quadrant 3: Lower ${yAxisLabel}, Lower ${xAxisLabel}`,
          padding: 10,
          fontStyle: "italic",
          fontFamily: "Inter",
          fontWeight: "bold",
        },
      },
    })

    var series = chart.getSeries(0)
    series.labels().useHtml(true)

    series
      .labels()
      .fontFamily("Inter")
      .enabled(true)
      .format(function (e) {
        return "<span style='color:black;'>" + this.getData("id") + "</span>"
      })

    chart.xGrid(true)
    chart.yGrid(true)
    // enable minor grids
    chart.xMinorGrid(true)
    chart.yMinorGrid(true)
    chart.title({ text: chartResponse.chartName, fontWeight: "bold" })
    //chart.maxBubbleSize("10%") // take from chartData
    chart.xAxis().title({
      text: xAxisLabel,
      fontSize: 16,
      fontWeight: "bold",
      fontFamily: "Inter",
      align: "center",
    })
    chart.yAxis().title({
      text: yAxisLabel,
      fontSize: 16,
      fontWeight: "bold",
      fontFamily: "Inter",
      align: "center",
    })
    chart.xAxis(0, {
      ticks: true,
      labels: true,
      fontWeight: "bold",
      fontFamily: "Inter",
      fontSize: 16,
    })
    chart.yAxis(0, {
      ticks: true,
      labels: true,
      fontWeight: "bold",
      fontFamily: "Inter",
      fontSize: 16,
    })
    chart.yScale().minimum(yAxisAuto ? 0 : minY)
    chart.yScale().maximum(yAxisAuto ? 100 : maxY)
    chart.xScale().minimum(xAxisAuto ? 0 : minX)
    chart.xScale().maximum(maxX == 0 || xAxisAuto ? defaultMaxCost : maxX)
    var padding = chart.padding()
    padding.right(100)
    var xLabels = chart.xAxis().labels()
    xLabels.format(function () {
      if (xFormat == "$") {
        return (
          xFormat + anychart.format.number(this.value, 2, ".", ",") + xFormat2
        )
      } else {
        return this.value + "%"
      }
    })
    xLabels.fontSize(16)
    xLabels.fontWeight("bold")
    xLabels.fontFamily("Inter")
    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return this.value + "%"
    })
    yLabels.fontSize(16)
    yLabels.fontWeight("bold")
    yLabels.fontFamily("Inter")
    chart.tooltip().useHtml(true)
    var tooltip = chart.tooltip()
    tooltip.titleFormat("{%id}")
    tooltip.format("{%ToolTip}")
    chart.container("chartdiv")
    chart.credits().enabled(false)

    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")

    chart.draw()

    setIsLoading(false)
  }

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      fullscreen={true}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Bubble Chart
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col md="4" sm="6">
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label style={{ width: "100px" }}>Y Axis</Label>
              <Input
                type="checkbox"
                checked={yAxisAuto}
                onClick={() => setYAxisAuto(!yAxisAuto)}
              />
              <Label style={{ marginLeft: "10px" }}>Auto</Label>
              <Label style={{ marginLeft: "20px" }}>Min</Label>
              <Input
                type="number"
                style={{ padding: "1px", width: "70px", marginLeft: "10px" }}
                value={minY}
                onChange={e => setMinY(e.target.value)}
              />
              <Label style={{ marginLeft: "10px" }}>Max</Label>
              <Input
                type="number"
                style={{ padding: "1px", width: "70px", marginLeft: "10px" }}
                value={maxY}
                onChange={e => setMaxY(e.target.value)}
              />
            </div>
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label style={{ width: "100px" }}>X Axis</Label>
              <Input
                type="checkbox"
                checked={xAxisAuto}
                onClick={() => setXAxisAuto(!xAxisAuto)}
              />
              <Label style={{ marginLeft: "10px" }}>Auto</Label>
              <Label style={{ marginLeft: "20px" }}>Min</Label>
              <Input
                type="number"
                style={{ padding: "1px", width: "70px", marginLeft: "10px" }}
                value={minX}
                onChange={e => setMinX(e.target.value)}
              />
              <Label style={{ marginLeft: "10px" }}>Max</Label>
              <Input
                type="number"
                style={{ padding: "1px", width: "70px", marginLeft: "10px" }}
                value={maxX}
                onChange={e => setMaxX(e.target.value)}
              />
            </div>
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>
                <Label className="form-label">X Axis field</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={xAxisField}
                  style={{ width: "200px" }}
                  onChange={e => {
                    setXAxisField(e.target.value)
                  }}
                >
                  <option value="Cost">Total Cost</option>
                  <option value="ProjectPostProject">Project and Post Project Cost</option>
                  <option value="Return">Return ($)</option>
                  <option value="ROI">ROI (%)</option>
                  <option value="IRR">IRR (%)</option>
                  <option value="Payback">PBP (Years)</option>
                </select>
              </div>
              <div>
                <Input
                  style={{ marginLeft: "20px" }}
                  type="checkbox"
                  checked={showNames}
                  onClick={() => setShowNames(!showNames)}
                />
                <Label style={{ marginLeft: "10px" }}>Show Names</Label>
              </div>
            </div>
            <div>
              <input
                className="form-check-input"
                type="radio"
                name="anon1"
                id="anon1"
                // value="0"
                checked={chartOption1}
                onClick={e => {
                  setChartOption1(true)
                }}
              />
              <label
                className="form-check-label"
                htmlFor="anon1"
                style={{ marginLeft: "10px" }}
              >
                Option 1: {scenario != null && scenario.scoreName} vs. Cost, with {scenario != null && scenario.secondaryScoreName} by Bubble Color
              </label>
            </div>
            <div>
              <input
                className="form-check-input"
                type="radio"
                name="anon2"
                id="anon2"
                // value="1"
                checked={!chartOption1}
                onClick={e => {
                  setChartOption1(false)
                }}
              />
              <label
                className="form-check-label"
                htmlFor="anon2"
                style={{ marginLeft: "10px" }}
              >
                Option 2: {scenario != null && scenario.scoreName} vs. {scenario != null && scenario.secondaryScoreName}, with Cost by Bubble Size
              </label>
            </div>
          </Col>
          <Col md="4" sm="6">
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label style={{ width: "180px" }}>Quadrant 1 Label</Label>
              <Input
                name="q1l"
                type="text"
                onChange={e => setQ1(e.target.value)}
                value={q1}
              />
            </div>
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label style={{ width: "180px" }}>Quadrant 2 Label</Label>
              <Input
                name="q2l"
                type="text"
                onChange={e => setQ2(e.target.value)}
                value={q2}
              />
            </div>
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label style={{ width: "180px" }}>Quadrant 3 Label</Label>
              <Input
                name="q3l"
                type="text"
                onChange={e => setQ3(e.target.value)}
                value={q3}
              />
            </div>
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Label style={{ width: "180px" }}>Quadrant 4 Label</Label>
              <Input
                name="q4l"
                type="text"
                onChange={e => setQ4(e.target.value)}
                value={q4}
              />
            </div>
            <button className="btn btn-primary" onClick={loadData}>
              Refresh
            </button>
          </Col>
        </Row>
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        <div id="chartdiv" style={{ width: "100%", height: "600px" }}></div>
        {ratingScales.length > 0 && (
          <Row style={{justifyContent:'center'}}>
            <Col md="3" sm="6">
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>Rating</th>
                    <th style={{ width: "50%" }}>Color</th>
                  </tr>
                </thead>
                <tbody>
                  {ratingScales.map((rs, idx) => (
                    <tr key={idx}>
                      <td>{rs.ratingName}</td>
                      <td style={{ backgroundColor: rs.backColor }}></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        )}
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default BubbleChart
