import { TextareaAutosize } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import {
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import ShareDashboardModal from "./ShareDashboardModal"
import ViewDashboardModal from "./ViewDashboardModal"
import useModal from "hooks/useModalHook"

const EditDashboardModal = ({
  isOpen,
  toggleModal,
  dashboardToEdit,
  setDashboardToEdit,
  reloadData,
  showOnlyEdit,
  props,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [layouts, setLayouts] = useState([])

  const [search, setSearch] = useState("")
  const [userCharts, setUserCharts] = useState([])

  const [showShareModal, setShowShareModal] = useState(false)
  const toggleShareModal = () => setShowShareModal(!showShareModal)
  const { show: showDashboardModal, toggle: toggleDashboardModal } = useModal()

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    let l = await api.getList(currentUser.userID, "DashboardLayout")
    setLayouts(l)
    let ch = await api.getDashboardItemsForUser(currentUser.userID)
    setUserCharts(ch)
  }

  const save = async () => {
    let id
    if (dashboardToEdit.dashboardID > -1) {
      await api.updateDashboard(currentUser.userID, dashboardToEdit)
      id = dashboardToEdit.dashboardID
      toggleModal()
      reloadData && reloadData()
    } else {
      id = await api.createDashboard(currentUser.userID, dashboardToEdit)
      toggleModal()
      reloadData && reloadData()
    }

    if (dashboardToEdit.scopeID > 0) {
      props.history.push(`/dashboard/${id}/${dashboardToEdit.scopeID}`)
    } else {
      props.history.push(`/dashboard/${id}`)
    }
  }

  const copyDashboard = async () => {
    await api.copyDashboard(currentUser.userID, dashboardToEdit.dashboardID)
    toggleModal()
    reloadData && reloadData()
  }
  const deleteDashboard = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this dashboard?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteDashboard(
            currentUser.userID,
            dashboardToEdit.dashboardID
          )
          //reloadData && reloadData()
          toggleModal()
          props.history.push('/manageDashboards')
        }
      })
  }
  const shareDashboard = async () => {
    toggleShareModal()
  }
  const viewDashboard = async () => {
    if (dashboardToEdit.dashboardID > -1) {
      await api.updateDashboard(currentUser.userID, dashboardToEdit)
      toggleDashboardModal()
    }
  }

  const changeDahsboardProp = (prop, value) => {
    setDashboardToEdit({ ...dashboardToEdit, [prop]: value })
  }

  const addSelectedItems = () => {
    let selected = userCharts.filter(x => x.selected)
    if (selected.length > 0) {
      let copy = [...userCharts]
      copy = copy.filter(
        x =>
          selected.find(
            s => x.itemID == s.itemID && x.itemType == s.itemType
          ) == undefined
      )
      setUserCharts(copy)
      let dashboardItems = [...dashboardToEdit.dashboardItems]
      selected.forEach(s => {
        dashboardItems.push({
          itemID: s.itemID,
          dashboardItemName: s.dashboardItemName,
          itemType: s.itemType,
        })
      })
      changeDahsboardProp("dashboardItems", dashboardItems)
    }
  }
  const removeSelectedItems = () => {
    let selected = [...dashboardToEdit.dashboardItems.filter(x => x.selected)]
    if (selected.length > 0) {
      let dashboardItems = [...dashboardToEdit.dashboardItems]
      dashboardItems = dashboardItems.filter(
        d =>
          selected.find(
            x => x.itemID == d.itemID && x.itemType == d.itemType
          ) == undefined
      )
      changeDahsboardProp("dashboardItems", dashboardItems)
      let copy = [...userCharts]
      selected.forEach(x => {
        copy.push({
          dashboardItemName: x.dashboardItemName,
          itemID: x.itemID,
          itemType: x.itemType,
        })
      })
      setUserCharts(copy)
    }
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="xl"
      //fullscreen={true}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Dashboard
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {dashboardToEdit != null && (
        <div className="modal-body">
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Dashboard Name</Label>
                <Input
                  name="chartName"
                  type="text"
                  onChange={e =>
                    changeDahsboardProp("dashboardName", e.target.value)
                  }
                  value={dashboardToEdit.dashboardName}
                />
              </div>
             
              <div className="mb-3">
                <Label className="form-label">Description</Label>
                <TextareaAutosize
                  minRows={2}
                  className="form-control"
                  onChange={e =>
                    changeDahsboardProp("description", e.target.value)
                  }
                  value={dashboardToEdit.description}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col md="5">
              <InputGroup>
                <InputGroupText>
                  <i className="fas fa-search"></i>
                </InputGroupText>
                <Input
                  placeholder="Search ..."
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
              </InputGroup>
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}></th>
                    <th style={{ width: "95%" }}>Chart</th>
                  </tr>
                </thead>
                <tbody>
                  {userCharts
                    .filter(
                      x =>
                        search == null ||
                        search == "" ||
                        x.dashboardItemName
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) > -1
                    )
                    .map((c, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <Input
                              type="checkbox"
                              checked={c.selected}
                              onClick={() => {
                                let copy = [...userCharts]
                                let item = copy.find(
                                  x =>
                                    x.itemID == c.itemID &&
                                    x.itemType == c.itemType
                                )
                                item.selected = !item.selected
                                setUserCharts(copy)
                              }}
                            />
                          </td>
                          <td>{c.dashboardItemName}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </Col>
            <Col md="2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <button className="btn btn-primary" onClick={addSelectedItems}>
                  <i className="fas fa-plus"></i> Add Chart(s)
                </button>
                <button
                  className="btn btn-outline-danger"
                  style={{ marginTop: "15px" }}
                  onClick={removeSelectedItems}
                >
                  <i className="fas fa-times"></i> Remove Chart(s)
                </button>
              </div>
            </Col>
            <Col md="5">
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "5%" }}></th>
                    <th style={{ width: "95%" }}>Chart</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardToEdit.dashboardItems.map((c, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <Input
                            type="checkbox"
                            checked={c.selected}
                            onClick={() => {
                              let copy = [...dashboardToEdit.dashboardItems]
                              let item = copy.find(
                                x =>
                                  x.itemID == c.itemID &&
                                  x.itemType == c.itemType
                              )
                              item.selected = !item.selected
                              setDashboardToEdit({
                                ...dashboardToEdit,
                                dashboardItems: copy,
                              })
                            }}
                          />
                        </td>
                        <td>{c.dashboardItemName}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <button
            onClick={() => {
              toggleModal()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          {dashboardToEdit != null && currentUser.userID == dashboardToEdit.userID && (
            <button
              onClick={() => {
                deleteDashboard()
              }}
              className="btn btn-danger save-user"
              data-dismiss="modal"
            >
              Delete Dashboard
            </button>
          )}
        </div>
        <div>
          {dashboardToEdit != null && (
            <>
              {dashboardToEdit.dashboardID > -1 && !showOnlyEdit && (
                <>
                  <button
                    className="btn btn-primary save-user"
                    onClick={viewDashboard}
                  >
                    View Dashboard
                  </button>
                  <button
                    className="btn btn-primary save-user"
                    onClick={shareDashboard}
                  >
                    Share Dashboard
                  </button>
                  <button
                    className="btn btn-primary save-user"
                    onClick={copyDashboard}
                  >
                    Copy Dashboard
                  </button>
                </>
              )}
              <button className="btn btn-primary save-user" onClick={save}>
                Save Dashboard
              </button>
            </>
          )}
        </div>
      </div>
      {!showOnlyEdit && (
        <>
          <ShareDashboardModal
            isOpen={showShareModal}
            toggleModal={toggleShareModal}
            dashboardToEdit={dashboardToEdit}
            setDashboardToEdit={setDashboardToEdit}
          />
          {dashboardToEdit != null && (
            <ViewDashboardModal
              isOpen={showDashboardModal}
              toggleModal={toggleDashboardModal}
              props={props}
              dashboardId={dashboardToEdit.dashboardID}
            />
          )}
        </>
      )}
    </Modal>
  )
}

export default EditDashboardModal
