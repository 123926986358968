import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import NameAvatar from "components/custom/nameAvatar"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Label,
  Modal,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import {
  changeResourceBudgeting,
  changeResourcePlanning,
  loadPageItem,
} from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import anychart from "anychart"
import { toast } from "react-toastify"
import { Tooltip } from "@material-ui/core"
import { ReactTabulator } from "react-tabulator"
import { createRoot } from "react-dom/client"
import { getSelectHtmlFilterValues } from "utils/tabulatorFilter"
import useModal from "hooks/useModalHook"
import TShirtHoursAllocationModal from "pages/Projects/EditProject/Modals/TShirtHoursAllocationModal"
import { getAlternativeYears } from "utils/alternativeHelper"
import { enrichWithPhotoBase64 } from "utils/avatar"

const ResourcePlanning = props => {
  var tabulatorRef = useRef(null)
  const currentUser = userService.getLoggedInUser()
  const scenarioId = props.match.params.scenarioId
  //plan if planning, budget if budgeting
  const budgetOrPlan = props.budgetOrPlan
  const isPlanning = budgetOrPlan == "plan"
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [isGridLoading, setIsGridLoading] = useState(false)
  const selectedType = budgetOrPlan
  const [selectedYear, setSelectedYear] = useState(0)
  const [years, setYears] = useState([])
  const [selectedShow, setSelectedShow] = useState(0)
  const [showExcluded, setShowExcluded] = useState(false)
  const [gridData, setGridData] = useState([])
  const [columns, setColumns] = useState([])
  const [cols, setCols] = useState([])
  const [showFilters, setShowFilters] = useState(true)

  const { resourcePlanningDefaults } = useSelector(state => ({
    resourcePlanningDefaults: state.resourcePlanning.resourcePlanning,
  }))
  const { resourceBudgetingDefaults } = useSelector(state => ({
    resourceBudgetingDefaults: state.resourceBudgeting.resourceBudgeting,
  }))

  const [showTShirtModal, setShowTShirtModal] = useState(false)
  const toggleTShirtModal = () => setShowTShirtModal(prev => !prev)

  const [
    resourceGroupBundleIdForTShirtModal,
    setResourceGroupBundleIdForTShirtModal,
  ] = useState(null)
  const [alternative, setAlternative] = useState(null)
  const [alternativeYears, setAlternativeYears] = useState([])

  const optionsObject = isPlanning
    ? resourcePlanningDefaults
    : resourceBudgetingDefaults
  const setOptionsProp = (prop, option) => {
    if (isPlanning) {
      dispatch(
        changeResourcePlanning({
          // ...resourcePlanningDefaults,
          [prop]: option,
        })
      )
    } else {
      dispatch(
        changeResourceBudgeting({
          // ...resourceBudgetingDefaults,
          [prop]: option,
        })
      )
    }
  }

  useEffect(() => {
    if (isPlanning) {
      dispatch(
        loadPageItem({
          userId: currentUser.userID,
          scenarioId: scenarioId,
          alternativeId: 0,
          viewName: "resourcePlanning",
        })
      )
      loadData()
    } else {
      dispatch(
        loadPageItem({
          userId: currentUser.userID,
          scenarioId: scenarioId,
          alternativeId: 0,
          viewName: "resourceEstimating",
        })
      )
      loadData()
    }

    return () => {
      let filters = tabulatorRef.current.getHeaderFilters()
      let altFilter = filters.find(x => x.field == "alternative")
      let bcFilter = filters.find(x => x.field == "bcState")
      let fyFilter = filters.find(x => x.field == "fyPlanned")
      let pmFilter = filters.find(x => x.field == "pm")
      let projectCategoryFilter = filters.find(
        x => x.field == "projectCategory"
      )

      if (isPlanning) {
        dispatch(
          changeResourcePlanning({
            // yearOption: resourcePlanningDefaults.yearOption,
            // showOption: resourcePlanningDefaults.showOption,
            // showExcluded: resourcePlanningDefaults.showExcluded,
            alternativeFilter: altFilter == undefined ? "" : altFilter.value,
            businessCaseFilter: bcFilter == undefined ? "" : bcFilter.value,
            fyPlannedFilter: fyFilter == undefined ? "" : fyFilter.value,
            pmFilter: pmFilter == undefined ? "" : pmFilter.value,
            projectCategoryFilter:
              projectCategoryFilter == undefined
                ? ""
                : projectCategoryFilter.value,
          })
        )
      } else {
        dispatch(
          changeResourceBudgeting({
            // yearOption: resourceBudgetingDefaults.yearOption,
            // showOption: resourceBudgetingDefaults.showOption,
            // showExcluded: resourceBudgetingDefaults.showExcluded,
            alternativeFilter: altFilter == undefined ? "" : altFilter.value,
            businessCaseFilter: bcFilter == undefined ? "" : bcFilter.value,
            fyPlannedFilter: fyFilter == undefined ? "" : fyFilter.value,
            pmFilter: pmFilter == undefined ? "" : pmFilter.value,
            projectCategoryFilter:
              projectCategoryFilter == undefined
                ? ""
                : projectCategoryFilter.value,
          })
        )
      }
    }
  }, [])

  const loadData = async () => {
    setIsLoading(true)

    let y = []
    if (isPlanning) {
      y = await api.getScenarioList(scenarioId, "ScenarioYears")
    } else {
      let s = await api.getScenario(scenarioId)
      y = [
        s.fyYear,
        s.fyYear + 1,
        s.fyYear + 2,
        s.fyYear + 3,
        s.fyYear + 4,
      ].map(x => {
        return { listItemID: x, listItemName: x }
      })
    }
    setYears(y)

    await loadGrid(
      currentUser.userID,
      scenarioId,
      // y !== null && y.length > 0 ? y[0].listItemID : new Date().getFullYear(),
      0,
      optionsObject.showExcluded,
      optionsObject.showOption,
      selectedType
    )
    setIsLoading(false)
  }

  const loadGrid = async (
    userId,
    scenarioId,
    year,
    showExcluded,
    show,
    planbudget
  ) => {
    setIsGridLoading(true)
    let data = await api.getAlternativeResourceStatus(
      userId,
      scenarioId,
      year,
      showExcluded,
      show,
      planbudget
    )

    let c = [
      {
        title: "Project",
        field: "alternative",
        minWidth: 250,
        headerFilter: "input",
        frozen: true,
        formatter: (cell, formatterParams, onRendered) => {
          const renderFn = () => {
            const val = cell.getValue()
            const cellEl = cell.getElement()
            if (cellEl) {
              const formatterCell = cellEl.querySelector(".formatterCell")
              if (formatterCell) {
                const CompWithMoreProps = React.cloneElement(
                  // <Tooltip title={<h6>{val}</h6>}>
                  // <span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        props.history.push(
                          `/editProject/${scenarioId}/${
                            cell.getRow().getData().alternativeID
                          }`
                        )
                      }}
                    >
                      {val}
                    </a>
                  </div>,
                  // </span>,
                  // </Tooltip>
                  { cell }
                )
                createRoot(cellEl.querySelector(".formatterCell")).render(
                  CompWithMoreProps
                )
              }
            }
          }

          onRendered(renderFn)

          setTimeout(() => {
            renderFn()
          }, 0)
          return '<div class="formatterCell"></div>'
        },
      },
      {
        title: "Version",
        field: "version",
        frozen: true,
        headerFilter: "select",
        headerFilterFunc: "in",
        headerFilterParams: {
          values: getSelectHtmlFilterValues(
            new Set(
              data
                .map(u => u["version"])
                .filter(x => x !== undefined && x !== null && x !== "")
            )
          ),
          multiselect: true,
        },
        width: 100,
        formatter: (cell, formatterParams, onRendered) => {
          return cell.getValue()
        },
        hozAlign: "center",
      },
      {
        title: "Business Case Status",
        field: "bcState",
        headerFilter: "input",
        // headerFilterParams: {
        //   values: true,
        //   sortValuesList: "asc",
        // },
        minWidth: 150,
        formatter: (cell, formatterParams, onRendered) => {
          return cell.getValue()
        },
      },
      {
        title: "FY Planned",
        field: "fyPlanned",
        headerFilter: "input",
        // headerFilterParams: {
        //   values: true,
        //   sortValuesList: "asc",
        // },
        minWidth: 120,
        formatter: (cell, formatterParams, onRendered) => {
          return cell.getValue()
        },
      },
      {
        title: "Project Manager",
        field: "pm",
        minWidth: 200,
        headerFilter: "select",
        headerFilterParams: {
          values: true,
          sortValuesList: "asc",
        },
        formatter: (cell, formatterParams, onRendered) => {
          const renderFn = () => {
            const val = cell.getValue()
            const cellEl = cell.getElement()
            const photoBase64 = cell.getRow().getData().photoBase64 ?? ''
            if (cellEl) {
              const formatterCell = cellEl.querySelector(".formatterCell")
              if (formatterCell) {
                const CompWithMoreProps = React.cloneElement(
                  <NameAvatar fullName={`${val}`} showName={true} base64Image={photoBase64} />,
                  { cell }
                )
                createRoot(cellEl.querySelector(".formatterCell")).render(
                  CompWithMoreProps
                )
              }
            }
          }

          onRendered(renderFn)

          setTimeout(() => {
            renderFn()
          }, 0)
          return '<div class="formatterCell"></div>'
        },
      },
      {
        title: "Planning Category",
        field: "projectCategory",
        minWidth: 230,
        headerFilter: "select",
        headerFilterParams: {
          values: true,
          sortValuesList: "asc",
        },
        formatter: (cell, formatterParams, onRendered) => {
          const renderFn = () => {
            const val = cell.getValue()
            const cellEl = cell.getElement()
            if (cellEl) {
              const formatterCell = cellEl.querySelector(".formatterCell")
              if (formatterCell) {
                const CompWithMoreProps = React.cloneElement(
                  <Tooltip title={<h6>{val}</h6>}>
                    <span>{val}</span>
                  </Tooltip>,
                  { cell }
                )
                createRoot(cellEl.querySelector(".formatterCell")).render(
                  CompWithMoreProps
                )
              }
            }
          }

          onRendered(renderFn)

          setTimeout(() => {
            renderFn()
          }, 0)
          return '<div class="formatterCell"></div>'
        },
      },
    ]
    if (data.length > 0) {
      setColumns(Object.keys(data[0]).filter(key => key.indexOf("c:") > -1))
      let otherCols = Object.keys(data[0]).filter(key => key.indexOf("c:") > -1)

      if (otherCols.length > 0) {
        otherCols.forEach(col => {
          if (show == "20") {
            c.push({
              title: col.replace("c:", ""),
              field: col,
              minWidth: 110,
              formatter: (cell, formatterParams, onRendered) => {
                const renderFn = () => {
                  const val = cell.getValue()
                  const data = cell.getRow().getData()
                  const cellEl = cell.getElement()

                  let { resourceGroupId, tShirtBundleId, newVal } =
                    extractAndRemoveResourceGroupId(val)

                  if (cellEl) {
                    const formatterCell = cellEl.querySelector(".formatterCell")
                    if (formatterCell) {
                      let CompWithMoreProps = undefined

                      if (resourceGroupId != null) {
                        if (
                          newVal == undefined ||
                          newVal == null ||
                          newVal == ""
                        ) {
                          CompWithMoreProps = React.cloneElement(
                            <div
                              style={{
                                margin: "0",
                                textAlign: "center",
                                display: "inline-block",
                                padding: "4px 10px",
                                borderRadius: "15px",
                                borderColor: "lightgray",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                color: "blue",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                loadTShirtModal(
                                  resourceGroupId,
                                  tShirtBundleId,
                                  data.alternativeID
                                )
                              }}
                            >
                              <i className="fas fa-tshirt"></i>&nbsp;T-Shirt
                            </div>,
                            { cell }
                          )
                        } else {
                          CompWithMoreProps = React.cloneElement(
                            <div
                              dangerouslySetInnerHTML={{
                                __html: newVal,
                              }}
                              onClick={() => {
                                loadTShirtModal(
                                  resourceGroupId,
                                  tShirtBundleId,
                                  data.alternativeID
                                )
                              }}
                            ></div>,
                            {
                              cell,
                            }
                          )
                        }
                      } else {
                        CompWithMoreProps = React.cloneElement(
                          <div
                            dangerouslySetInnerHTML={{
                              __html: newVal,
                            }}
                          ></div>,
                          { cell }
                        )
                      }

                      createRoot(cellEl.querySelector(".formatterCell")).render(
                        CompWithMoreProps
                      )
                    }
                  }
                }

                onRendered(renderFn)

                setTimeout(() => {
                  renderFn()
                }, 0)
                return '<div class="formatterCell"></div>'
              },
            })
          } else {
            c.push({
              title: col.replace("c:", ""),
              field: col,
              minWidth: 110,
              formatter: (cell, formatterParams, onRendered) => {
                return cell.getValue()
              },
            })
          }
        })
      }
    } else {
      setColumns([])
    }
    setCols(c)
    data = await enrichWithPhotoBase64(data, 'pmID')
    setGridData(data)
    setIsGridLoading(false)

    setPreSelectedFilters()
  }

  const loadTShirtModal = async (resourceGroupId, tShirtBundleId, altId) => {
    setResourceGroupBundleIdForTShirtModal({resourceGroupId, tShirtBundleId})
    let alt = await api.getAlternative(altId)
    setAlternative(alt)
    setAlternativeYears(getAlternativeYears(alt))

    toggleTShirtModal()
  }

  function extractAndRemoveResourceGroupId(str) {
    const regex = /{(\d+)\|(\d+)}/

    const matches = str.match(regex)
    let resourceGroupId = null
    let tShirtBundleId = null
    if (matches) {
      resourceGroupId = matches[1]
      tShirtBundleId = matches[2]
    }

    const newVal = str.replace(regex, "").trim()

    return { resourceGroupId, tShirtBundleId, newVal }
  }

  const applyTShirtHoursAllocation = async updatedCostPools => {
    let copy = [...updatedCostPools]

    copy.forEach(cp => {
      let total = 0
      alternativeYears.forEach(y => {
        cp[`c-${y}`] = cp[`updated-${y}`]
        total += cp[`updated-${y}`]
      })
      cp[`c-Total`] = total
    })

    let itemsToUpdate = []
    updatedCostPools.forEach(cp => {
      alternativeYears.forEach(i => {
        itemsToUpdate.push({
          resourceGroupID: cp.resourceGroupID,
          alternativeID: cp.alternativeID,
          costPoolID: cp.costPoolID,
          yearID: i,
          numUnits: parseInt(cp[`c-${i}`]),
        })
      })
    })

    console.log(itemsToUpdate)

    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateAlternativeResourceBudget(currentUser.userID, x)
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
    }
    let rowsToUpdate = updatedCostPools.map(x => {
      return {
        resourceGroupID: x.resourceGroupID,
        costPoolID: x.costPoolID,
        alternativeID: x.alternativeID,
        budgetNotes: x["c-Estimation Notes"],
        budgetStatusID: x.budgetStatusID,
        altRGValue: x["c-Total"],
      }
    })
    if (rowsToUpdate.length > 0) {
      let rowsToUpdateTasks = rowsToUpdate.map(x => async () => {
        return await api.updateAlternativeResourceFromBudget(
          currentUser.userID,
          x
        )
      })
      await Promise.all(rowsToUpdateTasks.map(t => t()))
    }

    await loadData()
  }

  const setPreSelectedFilters = () => {
    setTimeout(() => {
      tabulatorRef.current.setHeaderFilterValue(
        "alternative",
        optionsObject.alternativeFilter
      )
      tabulatorRef.current.setHeaderFilterValue(
        "bcState",
        optionsObject.businessCaseFilter
      )
      tabulatorRef.current.setHeaderFilterValue(
        "fyPlanned",
        optionsObject.fyPlannedFilter
      )
      tabulatorRef.current.setHeaderFilterValue("pm", optionsObject.pmFilter)
      tabulatorRef.current.setHeaderFilterValue(
        "projectCategory",
        optionsObject.projectCategoryFilter
      )
    }, 1000)
  }

  const toggleFilters = () => {
    tabulatorRef.current.element.classList.remove(
      "resource-planning-show-filters"
    )
    tabulatorRef.current.element.classList.remove(
      "resource-planning-hide-filters"
    )
    tabulatorRef.current.element.classList.add(
      !showFilters
        ? "resource-planning-show-filters"
        : "resource-planning-hide-filters"
    )
    setShowFilters(!showFilters)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <>
                  <Row>
                    {/* <Col md="3">
                      <div className="mb-3">
                        <Label style={{ marginBottom: "0px" }}>Type</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={selectedType}
                          onChange={e => {
                            setSelectedType(e.target.value)
                            loadGrid(
                              currentUser.userID,
                              scenarioId,
                              selectedYear,
                              showExcluded,
                              selectedShow,
                              e.target.value
                            )
                          }}
                        >
                          <option value="budget">Resource Estimate</option>
                          <option value="plan">Resource Plan</option>
                        </select>
                      </div>
                    </Col> */}

                    <Col md="3">
                      <div className="mb-3">
                        <Label style={{ marginBottom: "0px" }}>
                          Show Resource Estimates for FY
                        </Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={optionsObject.yearOption}
                          onChange={e => {
                            setOptionsProp("yearOption", e.target.value)
                            loadGrid(
                              currentUser.userID,
                              scenarioId,
                              e.target.value,
                              optionsObject.showExcluded,
                              optionsObject.showOption,
                              selectedType
                            )
                          }}
                        >
                          <option value="0">All</option>
                          {years.map((r, idx) => {
                            return (
                              <option key={idx} value={`${r.listItemID}`}>
                                {r.listItemName}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label style={{ marginBottom: "0px" }}>Show</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={optionsObject.showOption}
                          onChange={e => {
                            setOptionsProp("showOption", e.target.value)
                            loadGrid(
                              currentUser.userID,
                              scenarioId,
                              optionsObject.yearOption,
                              optionsObject.showExcluded,
                              e.target.value,
                              selectedType
                            )
                          }}
                        >
                          <option value="0">Requested and Not Complete</option>
                          <option value="1">Requested and Complete</option>
                          <option value="10">All and Not Complete</option>
                          <option value="11">All and Complete</option>
                          <option value="20">
                            Project Hours and T-Shirt Size
                          </option>
                          <option value="-99">All</option>
                        </select>
                      </div>
                    </Col>
                    <Col
                      md="3"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="show"
                        checked={optionsObject.showExcluded}
                        onClick={e => {
                          const val = !optionsObject.showExcluded
                          setOptionsProp("showExcluded", val)
                          loadGrid(
                            currentUser.userID,
                            scenarioId,
                            optionsObject.yearOption,
                            val,
                            optionsObject.showOption,
                            selectedType
                          )
                        }}
                      />
                      <Label
                        className="form-check-label"
                        for="show"
                        style={{ marginLeft: "10px" }}
                      >
                        Show Excluded from Planning Cycle
                      </Label>
                    </Col>
                  </Row>
                  {!isGridLoading && (
                    <Row>
                      <Col>
                        <button
                          style={{ float: "right" }}
                          onClick={toggleFilters}
                          className="btn btn-sm btn-outline-primary save-user"
                        >
                          {showFilters ? "Hide" : "Show"}{" "}
                          <i className="fas fa-search"></i>{" "}
                          <i className="fas fa-filter"></i>
                        </button>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col style={{ overflowX: "auto" }}>
                      {isGridLoading && (
                        <Loader
                          type="line-scale-pulse-out"
                          color={colors.primary}
                          style={{ textAlign: "center" }}
                        />
                      )}
                      {!isGridLoading && cols.length > 0 && (
                        <div
                          className={
                            showFilters
                              ? "resource-planning-show-filters"
                              : "resource-planning-hide-filters"
                          }
                        >
                          <ReactTabulator
                            onRef={r => (tabulatorRef.current = r.current)}
                            columns={cols}
                            data={gridData}
                            options={{
                              pagination: "local",
                              paginationSize: 30,
                              paginationSizeSelector: [
                                5, 10, 20, 30, 50, 100, 200, 300,
                              ],
                              layout: "fitColumns",
                            }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      {alternative != null && (
        <TShirtHoursAllocationModal
          isOpen={showTShirtModal}
          toggleModal={toggleTShirtModal}
          resourceGroupBundleId={resourceGroupBundleIdForTShirtModal}
          alternativeYears={alternativeYears}
          applyTShirtHoursAllocation={applyTShirtHoursAllocation}
          alternativeId={alternative.alternativeID}
          alternativeName={alternative.alternativeName}
          scenarioId={alternative.scenarioID}
        />
      )}
    </React.Fragment>
  )
}

export default ResourcePlanning
