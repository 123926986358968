import { handleResponse } from "helpers/handle-response"
import { useDispatch } from "react-redux"
import { changeNavbarParams } from "store/actions"
import { extraHeaders, mainApiUrl } from "./api.service"

export const authenticationService = {
  login,
  authenticate,
  logout,
  resetPassword,
}

function login(username, password, dispatch) {
//   const dispatch = useDispatch()
  let data = {
    username: username,
    password: password,
    grant_type: "password",
    scope: 1,
  }
  let formBody = []
  for (let property in data) {
    let encodedKey = encodeURIComponent(property)
    let encodedValue = encodeURIComponent(data[property])
    formBody.push(encodedKey + "=" + encodedValue)
  }
  formBody = formBody.join("&")

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      ...extraHeaders
    },
    body: formBody,
  }

  return fetch(`${mainApiUrl}/token`, requestOptions)
    .then(handleResponse)
    .then(
      data => {
        if (data.user) {
          let u = JSON.parse(data.user)
          dispatch && dispatch(changeNavbarParams({ userId: u.userID, scenarioId: 0, viewName: "empty" }))
        }
        localStorage.setItem("currentData", JSON.stringify(data))
        localStorage.setItem('initialLogin', true)
        return JSON.parse(data.user)
      },
      error => {
        return error("Username or password incorrect")
      }
    )
}

function authenticate(username, password) {
    let data = {
      username: username,
      password: password,
      grant_type: "password",
      scope: 1,
    }
    let formBody = []
    for (let property in data) {
      let encodedKey = encodeURIComponent(property)
      let encodedValue = encodeURIComponent(data[property])
      formBody.push(encodedKey + "=" + encodedValue)
    }
    formBody = formBody.join("&")
  
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    }
  
    return fetch(`${mainApiUrl}/token`, requestOptions)
      .then(handleResponse)
      .then(
        data => {
          return data
        },
        error => {
          return error("Username or password incorrect")
        }
      )
  }

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentData")
}

function resetPassword(email) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }

  return fetch(
    `${mainApiUrl}/api/User/ResetPassword?email=${email}`,
    requestOptions
  )
    .then(handleResponse)
    .then(
      data => {
        return data
      },
      error => {
        return error(
          "Something went wrong while trying to reset your password."
        )
      }
    )
}
