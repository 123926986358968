import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { changeNavbarParams, loadPageItem } from "store/actions"
import PaginatedTable from "components/custom/PaginatedTable"
import Loader from "react-loaders"
import colors from "utils/colors"
import EditRoadmapModal from "pages/Roadmap/Modals/EditRoadmapModal"
import useModal from "hooks/useModalHook"
import EditTaskModal from "pages/Projects/EditProject/Modals/EditTaskModal"
import EditUserChartModal from "pages/Reporting/UserCharts/EditUserChartModal"
import moment from "moment"
import { sortData } from "utils/sort"
import NotificationEmails from "./NotificationEmails"
import isNullOrEmpty from "utils/isNullOrEmpty"

const MyData = props => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [scenarios, setScenarios] = useState([])
  const [alternatives, setAlternatives] = useState([])
  const [tasks, setTasks] = useState([])
  const [charts, setCharts] = useState([])
  const [dashboards, setDashboards] = useState([])
  const [roadmaps, setRoadmaps] = useState([])

  const [roadmapToEdit, setRoadmapToEdit] = useState(null)
  const { show: showRoadmapModal, toggle: toggleRoadmapModal } = useModal()

  const [taskToEdit, setTaskToEdit] = useState(null)
  const { show: showTaskModal, toggle: toggleTaskModal } = useModal()
  const [chartToEdit, setChartToEdit] = useState(null)
  const { show: showChartModal, toggle: toggleChartModal } = useModal()

  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState(-1)
  const [filterOptions, setFilterOptions] = useState([])

  const [allData, setAllData] = useState([])

  useEffect(() => {
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "myData",
      })
    )
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "myData",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)

    let data = []

    let s = await api.getScenariosByUser(currentUser.userID, 0)
    const sixMonthsAgo = moment().subtract(6, "months")
    s = s.filter(
      x => x.locked == "No" && moment(x.lastUpdateDT).isAfter(sixMonthsAgo)
    )
    data = [
      ...sortData(s, "lastUpdateDT", "desc").map(x => {
        return {
          id: x.scenarioID,
          type: "Portfolio",
          name: x.scenarioName,
          information: isNullOrEmpty(x.lastUpdateDT)
            ? ""
            : `Last Updated: ${moment(x.lastUpdateDT).format("MM/DD/YYYY")}`,
          isScenario: true,
        }
      }),
    ]

    let alts = await api.getAlternativesUserHasRole(currentUser.userID)
    // const oneYearAgo = moment().subtract(1, "years")
    // alts = alts.filter(
    //   x =>
    //     !isNullOrEmpty(x.modifiedDT) && moment(x.modifiedDT).isAfter(oneYearAgo)
    // )
    data = [
      ...data,
      ...sortData(alts, "lastUpdate", "desc").map(x => {
        let name = `${x.alternativeNum} ${x.alternativeName} ${
          !isNullOrEmpty(x.version) ? x.version : ""
        }`
        let info = `Role: ${
          x.brmUserID == currentUser.userID
            ? "BRM"
            : x.pmUserID == currentUser.userID
            ? "PM"
            : "Submitter"
        } ${
          !isNullOrEmpty(x.bcStatus)
            ? `<br> Business Case Status: ${x.bcStatus}`
            : ""
        } ${!isNullOrEmpty(x.decision) ? ` <br> Decision: ${x.decision}` : ""}`
        return {
          id: x.alternativeID,
          type: "Alternative",
          name: name,
          information: info,
          isAlternative: true,
        }
      }),
    ]

    // await loadTasks()
    let t = await api.getTasksUserHasRole(currentUser.userID)
    setTasks(t)
    data = [
      ...data,
      ...t.map(x => {
        let info = ""
        info += isNullOrEmpty(x.scopeItemName) ? "" : `${x.scopeItemName} <br>`
        info += isNullOrEmpty(x.requestType)
          ? ""
          : `Type: ${x.requestType} <br>`
        if (x.taskType == "Change Request") {
          info += isNullOrEmpty(x.taskDisposition)
            ? ""
            : `<br> Disposition: ${x.taskDisposition}`
        } else {
          info += isNullOrEmpty(x.dueDT)
            ? ""
            : `Due Date: ${
                new Date(x.dueDT) < new Date() ? (
                  `<span style={{ color: "red" }}>
                    ${moment(x.dueDT).format("MM/DD/YYYY")}
                  </span>`
                ) : (
                  `<span>${moment(x.dueDT).format("MM/DD/YYYY")}</span>`
                )
              } <br> `
          info += isNullOrEmpty(x.lastUpdateDT)
            ? ""
            : `Last Updated: ${moment(x.lastUpdateDT).format(
                "MM/DD/YYYY"
              )} <br>`
          info += isNullOrEmpty(x.taskStatus) ? "" : `Status: ${x.taskStatus}`
        }
        return {
          id: x.taskID,
          type: x.taskType,
          name: x.taskName,
          information: info,
          isTask: true,
        }
      }),
    ]

    let c = await api.getUserCharts(currentUser.userID)
    setCharts(c)
    data = [
      ...data,
      ...c.map(x => {
        return {
          id: x.userChartID,
          type: "Chart",
          name: x.chartName,
          information: x.chartType + ": " + x.description,
          isChart: true,
        }
      }),
    ]

    let d = await api.getDashboards(currentUser.userID, false)
    setDashboards(d)
    data = [
      ...data,
      ...d.map(x => {
        return {
          id: x.dashboardID,
          type: "Dashboard",
          name: x.dashboardName,
          information: x.description,
          isDashboard: true,
        }
      }),
    ]

    let r = await api.getRoadmaps(currentUser.userID)
    setRoadmaps(r)
    data = [
      ...data,
      ...r.map(x => {
        return {
          id: x.roadmapID,
          type: "Roadmap",
          name: x.roadmapName,
          information: x.description,
          isRoadmap: true,
        }
      }),
    ]

    setFilterOptions(
      [...new Set(data.map(d => d.type))].filter(
        x => x !== undefined && x !== null && x !== ""
      )
    )

    setAllData(data)

    setIsLoading(false)
  }

  const loadRoadmaps = async () => {
    let r = await api.getRoadmaps(currentUser.userID)
    setRoadmaps(r)
  }

  const loadTasks = async () => {
    let t = await api.getTasksUserHasRole(currentUser.userID)
    setTasks(t)
  }

  const loadCharts = async () => {
    let c = await api.getUserCharts(currentUser.userID)
    setCharts(c)
  }

  const goToAlternative = (e, alternative) => {
    e && e.preventDefault()
    props.history.push(
      `/editProject/${alternative.scenarioID}/${alternative.alternativeID}`
    )
  }

  const goToScenario = (e, scenario) => {
    e && e.preventDefault()
    props.history.push(`/projects/${scenario.scenarioID}`)
  }

  const goToDashboard = (e, dashboard) => {
    e && e.preventDefault()
    props.history.push(`/dashboard/${dashboard.dashboardID}`)
  }

  const editRoadmap = async (e, roadmap) => {
    e && e.preventDefault()
    // let r = await api.getRoadmap(roadmap.roadmapID)
    setRoadmapToEdit(roadmap)
    toggleRoadmapModal()
  }

  const editTask = async (e, task) => {
    e && e.preventDefault()
    // let r = await api.getTask(task.taskID)
    setTaskToEdit(task)
    toggleTaskModal()
  }

  const editChart = async (e, chartId) => {
    e && e.preventDefault()
    let chart = await api.getUserChart(chartId)
    setChartToEdit(chart)
    toggleChartModal()
  }

  const handleItemClick = async (e, item) => {
    e && e.preventDefault()
    if (item.isScenario) {
      props.history.push(`/projects/${item.id}`)
    } else if (item.isTask) {
      let t = tasks.find(x => x.taskID == item.id)
      if (t !== undefined) {
        setTaskToEdit(t)
        toggleTaskModal()
      }
    } else if (item.isChart) {
      let chart = await api.getUserChart(item.id)
      if (chart != undefined && chart != null) {
        setChartToEdit(chart)
        toggleChartModal()
      }
    } else if (item.isDashboard) {
      props.history.push(`/dashboard/${item.id}`)
    } else if (item.isRoadmap) {
      let roadmap = roadmaps.find(x => x.roadmapID == item.id)
      if (roadmap != undefined) {
        setRoadmapToEdit(roadmap)
        toggleRoadmapModal()
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          <Row>
            <Col md="8">
              <div className="d-flex-row mb-3" style={{ gap: "10px" }}>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  style={{ width: "200px" }}
                  value={filter}
                  onChange={e => {
                    setFilter(e.target.value)
                  }}
                >
                  <option value="-1">All</option>
                  {filterOptions.map((f, idx) => (
                    <option key={idx} value={f}>
                      {f}s
                    </option>
                  ))}
                  {/* <option value="Chart">Charts</option>
                  <option value="Dashboard">Dashboards</option>
                  <option value="Roadmap">Roadmaps</option>
                  <option value="Task">Tasks</option> */}
                </select>

                <InputGroup style={{ width: "300px" }}>
                  <InputGroupText>
                    <i className="fas fa-search"></i>
                  </InputGroupText>
                  <Input
                    placeholder="Search ..."
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                  />
                </InputGroup>
              </div>
              {allData.length > 0 && (
                <Row className="mb-3">
                  <Col>
                    <Breadcrumbs title={"My Stuff"} />
                    <PaginatedTable pageSize={50}
                      items={allData
                        .filter(
                          x =>
                            search == null ||
                            search == "" ||
                            x.name.toLowerCase().indexOf(search.toLowerCase()) >
                              -1
                        )
                        .filter(
                          x => filter == -1 || x.type == filter
                          // (filter == "Scenario" && x.isScenario) ||
                          // (filter == "Chart" && x.isChart) ||
                          // (filter == "Dashboard" && x.isDashboard) ||
                          // (filter == "Roadmap" && x.isRoadmap) ||
                          // (filter == "Task" && x.isTask)
                        )}
                      rowRenderer={(a, idx) => (
                        <tr key={idx}>
                          <td>{a.type}</td>
                          <td>
                            <a href="#" onClick={e => handleItemClick(e, a)}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: a.name,
                                }}
                              ></div>
                            </a>
                          </td>
                          <td>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: a.information,
                              }}
                            ></div>
                          </td>
                        </tr>
                      )}
                    >
                      <thead>
                        <tr>
                          <th className="col-1">Type</th>
                          <th className="col-3">Item</th>
                          <th className="col-5">Information</th>
                        </tr>
                      </thead>
                    </PaginatedTable>
                  </Col>
                </Row>
              )}
              {/* {scenarios.length > 0 && (
                <Row className="mb-3">
                  <Col>
                    <Breadcrumbs title={"Models"} />
                    <PaginatedTable
                      items={scenarios.filter(
                        x =>
                          search == null ||
                          search == "" ||
                          x.scenarioName
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) > -1
                      )}
                      rowRenderer={(a, idx) => (
                        <tr key={idx}>
                          <td>
                            <a href="#" onClick={e => goToScenario(e, a)}>
                              {a.scenarioName}
                            </a>
                          </td>
                          <td>
                            {a.lastUpdateDT == null || a.lastUpdateDT == ""
                              ? ""
                              : moment(a.lastUpdateDT).format("MM/DD/YYYY")}
                          </td>
                        </tr>
                      )}
                    >
                      <thead>
                        <tr>
                          <th className="col-1">Model</th>
                          <th className="col-1">Last Updated</th>
                        </tr>
                      </thead>
                    </PaginatedTable>
                  </Col>
                </Row>
              )}
              {alternatives.length > 0 && (
                <Row className="mb-3">
                  <Col>
                    <Breadcrumbs title={"Projects"} />
                    <PaginatedTable
                      items={alternatives.filter(
                        x =>
                          search == null ||
                          search == "" ||
                          x.alternativeName
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) > -1
                      )}
                      rowRenderer={(a, idx) => (
                        <tr key={idx}>
                          <td>{a.scenarioName}</td>
                          <td>{a.ideaOnly ? "Idea" : a.alternativeNum}</td>
                          <td>
                            <a href="#" onClick={e => goToAlternative(e, a)}>
                              {a.alternativeName}
                            </a>
                          </td>
                          <td>
                            {!isNullOrEmpty(a.version) && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: a.version,
                                }}
                              ></div>
                            )}
                          </td>
                          <td>
                            {a.brmUserID == currentUser.userID
                              ? "BRM"
                              : a.pmUserID == currentUser.userID
                              ? "PM"
                              : "Submitter"}
                          </td>
                          <td>
                            {new Date(a.modifiedDT) >
                            new Date(1900, 1, 1, 0, 0, 0, 0)
                              ? moment(a.modifiedDT).format("MM/DD/YYYY")
                              : ""}
                          </td>
                        </tr>
                      )}
                    >
                      <thead>
                        <tr>
                          <th className="col-1">Scenario</th>
                          <th className="col-1">ID</th>
                          <th className="col-1">Alternative Name</th>
                          <th className="col-1">Version</th>
                          <th className="col-1">My Role</th>
                          <th className="col-1">Last Updated</th>
                        </tr>
                      </thead>
                    </PaginatedTable>
                  </Col>
                </Row>
              )}
              {tasks.length > 0 && (
                <Row className="mb-3">
                  <Col>
                    <Breadcrumbs title={"Tracking Items"} />
                    <PaginatedTable
                      items={tasks.filter(
                        x =>
                          search == null ||
                          search == "" ||
                          x.taskName
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) > -1
                      )}
                      rowRenderer={(a, idx) => (
                        <tr key={idx}>
                          <td>{a.taskType}</td>
                          <td>{a.scopeItemName}</td>
                          <td>
                            <a href="#" onClick={e => editTask(e, a)}>
                              {a.taskName}
                            </a>
                          </td>
                          <td>
                            <div className="d-flex-col">
                              {a.dueDT != null && (
                                <div
                                  style={{
                                    color:
                                      a.dueDT == null ||
                                      new Date(a.dueDT) > new Date()
                                        ? "black"
                                        : "red",
                                  }}
                                >
                                  {moment(a.dueDT).format("MM/DD/YYYY")}
                                </div>
                              )}
                              {!isNullOrEmpty(a.taskStatus) && (
                                <div
                                  className="d-flex-row ai-center"
                                  style={{ gap: "10px" }}
                                >
                                  <span>Status:</span>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: a.taskStatus,
                                    }}
                                  ></div>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    >
                      <thead>
                        <tr>
                          <th className="col-1">Task Type</th>
                          <th className="col-1">Scope Item</th>
                          <th className="col-1">Task Name</th>
                          <th className="col-1">Disposition</th>
                        </tr>
                      </thead>
                    </PaginatedTable>
                  </Col>
                </Row>
              )}
              {charts.length > 0 && (
                <Row className="mb-3">
                  <Col>
                    <Breadcrumbs title={"Charts"} />
                    <PaginatedTable
                      items={charts.filter(
                        x =>
                          search == null ||
                          search == "" ||
                          x.chartName
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) > -1
                      )}
                      rowRenderer={(a, idx) => (
                        <tr key={idx}>
                          <td>
                            <a
                              href="#"
                              onClick={e => editChart(e, a.userChartID)}
                            >
                              {a.chartName}
                            </a>
                          </td>
                          <td>{a.chartType}</td>
                        </tr>
                      )}
                    >
                      <thead>
                        <tr>
                          <th className="col-1">Chart Name</th>
                          <th className="col-1">Chart Type</th>
                        </tr>
                      </thead>
                    </PaginatedTable>
                  </Col>
                </Row>
              )}
              {dashboards.length > 0 && (
                <Row className="mb-3">
                  <Col>
                    <Breadcrumbs title={"Dashboards"} />
                    <PaginatedTable
                      items={dashboards.filter(
                        x =>
                          search == null ||
                          search == "" ||
                          x.dashboardName
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) > -1
                      )}
                      rowRenderer={(a, idx) => (
                        <tr key={idx}>
                          <td>
                            <a href="#" onClick={e => goToDashboard(e, a)}>
                              {a.dashboardName}
                            </a>
                          </td>
                          <td>{a.publicDashboard ? "Yes" : "No"}</td>
                        </tr>
                      )}
                    >
                      <thead>
                        <tr>
                          <th className="col-1">Dashboard Name</th>
                          <th className="col-1">Public</th>
                        </tr>
                      </thead>
                    </PaginatedTable>
                  </Col>
                </Row>
              )}
              {roadmaps.length > 0 && (
                <Row className="mb-3">
                  <Col>
                    <Breadcrumbs title={"Roadmaps"} />
                    <PaginatedTable
                      items={roadmaps.filter(
                        x =>
                          search == null ||
                          search == "" ||
                          x.roadmapName
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) > -1
                      )}
                      rowRenderer={(a, idx) => (
                        <tr key={idx}>
                          <td>
                            <a href="#" onClick={e => editRoadmap(e, a)}>
                              {a.roadmapName}
                            </a>
                          </td>
                          <td>{a.description}</td>
                        </tr>
                      )}
                    >
                      <thead>
                        <tr>
                          <th className="col-1">Roadmap Name</th>
                          <th className="col-1">Description</th>
                        </tr>
                      </thead>
                    </PaginatedTable>
                  </Col>
                </Row>
              )} */}
            </Col>
            <Col
              md="4"
              style={{
                background: "white",
                borderLeft: "2px solid lightgray",
                padding: "15px",
              }}
            >
              <NotificationEmails />
            </Col>
          </Row>
        </Container>
      </div>
      <EditRoadmapModal
        isOpen={showRoadmapModal}
        toggleModal={toggleRoadmapModal}
        roadmapToEdit={roadmapToEdit}
        setRoadmapToEdit={setRoadmapToEdit}
        reload={loadData}
      />
      <EditTaskModal
        isEditModalVisible={showTaskModal}
        toggleEditModal={toggleTaskModal}
        taskToEdit={taskToEdit}
        flatTaskList={tasks}
        setTaskToEdit={setTaskToEdit}
        reload={loadData}
      />
      <EditUserChartModal
        isOpen={showChartModal}
        toggleModal={toggleChartModal}
        chartToEdit={chartToEdit}
        setChartToEdit={setChartToEdit}
        reloadData={loadData}
      />
    </React.Fragment>
  )
}

export default MyData
