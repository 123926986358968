import React, { useEffect, useState } from "react"
import { Col, Input, Label, Modal, Row, FormGroup } from "reactstrap"
import Loader from "react-loaders"
import api from "services/api.service"
import anychart from "anychart"
import colors from "utils/colors"
import GanttLegend from "assets/images/GanttLegend.png"
import { userService } from "services/user.service"
import isNullOrEmpty from "utils/isNullOrEmpty"
import useModal from "hooks/useModalHook"
import RedirectModal from "./Modals/RedirectModal"

const RoadmapChart = ({ roadmapId, className }) => {
  const currentUser = userService.getLoggedInUser()
  const [chartA, setChart] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showGrid, setShowGrid] = useState(null)
  const [timeScale, setTimeScale] = useState(null)
  const [roadmapName, setRoadmapName] = useState(null)
  const [chartResp, setChartResp] = useState(null)
  const [cT, setCT] = useState(null)
  const [fyMonth, setFYMonth] = useState(null)
  const [roadmapTypes, setRoadmapTypes] = useState([])

  useEffect(() => {
    if (roadmapId > 0) {
      loadData()
    }
  }, [roadmapId])

  useEffect(() => {
    if (timeScale != null && chartResp != null) {
      loadChart()
    }
  }, [timeScale, chartResp])

  useEffect(() => {
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
  }, [])

  const downloadName = "Roadmap Summary"
  const exportChartAsPDF = () => {
    if (!chartA) {
      return
    }

    chartA.saveAsPdf({ filename: downloadName })
  }

  const exportChartAsPNG = () => {
    if (!chartA) {
      return
    }

    chartA.saveAsPng({ filename: downloadName })
  }

  const loadData = async () => {
    let rt = await api.getList(currentUser.userID, "RoadmapType")
    setRoadmapTypes(rt)
    api.getRoadmapChart(roadmapId, "Roadmap_General").then(
      resp => {
        setIsLoading(false)
        setTimeScale(resp.chartArray2)
        setRoadmapName(resp.chartTitle)
        setChartResp(resp)
        setCT(resp.chartType)
        setFYMonth(resp.chartData)
      },
      err => setIsLoading(false)
    )
  }

  const loadChart = async () => {
    renderGanttChart(chartResp, `gantt-${roadmapId}`)
  }

  function labelsTextSettings(label, item) {
    label.fontColor(item.get("color"))
    label.fontWeight(600)
    label.background().fill(item.get("color"))
  }

  const renderGanttChart = (response, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    let obj = eval("(" + response.chartArray.replaceAll("'", '"') + ")")
    let json = JSON.stringify(obj)
    var data = JSON.parse(json)
    var treeData = anychart.data.tree(data, "as-tree")
    var chart = anychart.ganttProject()
    chart.data(treeData)
    var ganttDiv = document.getElementById(divId)
    var items = data.length
    var gh = 100 + 160 * items
    ganttDiv.style.height = gh + "px"

    var newColumn = chart.dataGrid().column(0)
    newColumn.width(60)
    newColumn.title("ID")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().useHtml(true)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("<div style='text-align: center'>{%id}</div>")
    newColumn.depthPaddingMultiplier(25)
    newColumn.collapseExpandButtons(true)

    var newColumn = chart.dataGrid().column(1)
    newColumn.width(400)
    newColumn.title("Name")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("{%name}")
    newColumn.depthPaddingMultiplier(25)
    newColumn.collapseExpandButtons(true)

    var newColumn = chart.dataGrid().column(2)
    newColumn.width(100)
    newColumn.title("Business Priority")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("{%BusinessPriority}")

    if (showGrid) {
      var newColumn = chart.dataGrid().column(2)
      newColumn.width(250)
      newColumn.title("Planning Category")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().fontColor("#000000")
      newColumn.labels().format("{%planningcategory}")
      newColumn.depthPaddingMultiplier(25)
      newColumn.collapseExpandButtons(true)

      var newColumn = chart.dataGrid().column(3)
      newColumn.width(150)
      newColumn.title("Project Manager")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().fontColor("#000000")
      newColumn.labels().format("{%projectmanager}")
      newColumn.depthPaddingMultiplier(25)
      newColumn.collapseExpandButtons(true)

      var newColumn = chart.dataGrid().column(4)
      newColumn.width(150)
      newColumn.title("BRM")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().fontColor("#000000")
      newColumn.labels().format("{%brm}")
      newColumn.depthPaddingMultiplier(25)
      newColumn.collapseExpandButtons(true)

      var newColumn = chart.dataGrid().column(5)
      newColumn.width(90)
      newColumn.title("Start")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().useHtml(true)
      newColumn.labels().fontColor("#000000")
      newColumn
        .labels()
        .format(
          "<div style='text-align: center'>{%actualStart}{dateTimeFormat:MM/dd/yyyy}</div>"
        )
      newColumn.depthPaddingMultiplier(20)
      newColumn.collapseExpandButtons(true)

      var newColumn2 = chart.dataGrid().column(6)
      newColumn2.width(90)
      newColumn2.title("Finish")
      newColumn2.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn2.title().fontWeight(600)
      newColumn2.labels().useHtml(true)
      newColumn2.labels().fontColor("#000000")
      newColumn2
        .labels()
        .format(
          "<div style='text-align: center'>{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</div>"
        )
      newColumn2.depthPaddingMultiplier(20)
      newColumn2.collapseExpandButtons(true)
    }

    var dataGrid = chart.dataGrid()
    var dgTooltip = dataGrid.tooltip()
    dgTooltip.useHtml(true)

    dgTooltip.format("<span>" + "{%tooltip}<br>")

    chart.getTimeline().tooltip().useHtml(true)
    chart
      .getTimeline()
      .tooltip()
      .format("<span>" + "{%tooltip}<br>")

    if (timeScale == "YMW") {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "week", count: 1 },
            { unit: "month", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])

      if (fyMonth > 6) {
        var header = chart.getTimeline().header()
        header.level(2).format(function () {
          return +this.value + 1
        })
      }
    } else if (timeScale == "YM") {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "month", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])

      if (fyMonth > 6) {
        var header = chart.getTimeline().header()
        header.level(1).format(function () {
          return +this.value + 1
        })
      }
    } else if (timeScale == "YQ") {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "quarter", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])

      if (fyMonth > 6) {
        var header = chart.getTimeline().header()
        header.level(1).format(function () {
          return +this.value + 1
        })
      }
    } else {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "month", count: 1 },
            { unit: "quarter", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])

      if (fyMonth > 6) {
        var header = chart.getTimeline().header()
        header.level(2).format(function () {
          return +this.value + 1
        })
      }
    }

    // header.level(0).format("{%tickValue}{dateTimeFormat:MMM}");

    chart.rowStroke("0.2 #64b5f6")
    chart.columnStroke("0.2 #64b5f6")
    const thisdate = new Date()
    var year = thisdate.getFullYear()
    var month = thisdate.getMonth()
    var day = thisdate.getDate()

    chart.getTimeline().milestones().preview().enabled(true)

    chart.defaultRowHeight(25)
    chart.xScale().fiscalYearStartMonth(fyMonth) //(<%=HiddenFYMonth.Value%>);
    chart.container(divId)
    // initiate drawing the chart
    chart.draw()
    chart.credits().enabled(false)
    //chart.fitAll()

    if (timeScale == "YQ") {
      chart.fitAll()
    } else {
      chart.zoomTo(Date.UTC(year, 1, 1), Date.UTC(year + 2, 11, 30))
    }

    if (showGrid) {
      chart.splitterPosition(800)
    } else {
      chart.splitterPosition(550)
    }

    dataGrid.column(1).labelsOverrider(labelsTextSettings)

    var timeline = chart.getTimeline()
    timeline.groupingTasks().progress().fill(null).stroke(null)

    setChart(chart)
  }

  return (
    <div>
      <Row>
        <Col>
          <h5 className="text-center">{roadmapName}</h5>
        </Col>
      </Row>
      <Row className={className ? className : ""}>
        <Col className="mb-3">
          <div>
            <Label className="form-label">Timescale</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              style={{ width: "200px" }}
              value={timeScale}
              onChange={e => {
                setTimeScale(e.target.value)
              }}
            >
              <option value="YQM">Year Quarter Month</option>
              <option value="YMW">Year Month Week</option>
              <option value="YM">Year Month</option>
              <option value="YQ">Year Quarter</option>
            </select>
          </div>
        </Col>
        {/* <Col className="mb-3">
<div>
          <Label className="form-label">Milestone Display</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={cT}
                onChange={e => {
                  setCT(e.target.value)
                }}
              >
                <option value="-1">Select Type...</option>
                {roadmapTypes.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.listItemID}`}>
                      {a.listItemName}
                    </option>
                  )
                })}
              </select>
              </div>
              </Col> */}
        <Col className="mb-3">
          <div>
            <div style={{ fontSize: "20px", textAlign: "right" }}>
              <i className="fas fa-file-pdf" onClick={exportChartAsPDF}></i>
              &nbsp;&nbsp;
              <i className="fas fa-file-image" onClick={exportChartAsPNG}></i>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div>
            {cT > 0 && (
              <div>
                <img src={GanttLegend} alt="" height="80" />
              </div>
            )}

            {isLoading && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center" }}
              />
            )}
          </div>
          <div
            id={`gantt-${roadmapId}`}
            style={{ width: "100%", height: "500px" }}
          ></div>
        </Col>
      </Row>
    </div>
  )
}

export const RoadmapChart2 = ({ roadmapId, showSummary, history }) => {
  const [chartA, setChart] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showGrid, setShowGrid] = useState(null)
  const [timeScale, setTimeScale] = useState(null)
  const [roadmapName, setRoadmapName] = useState(null)
  const [chartResp, setChartResp] = useState(null)
  const [cT, setCT] = useState(null)
  const [fyMonth, setFYMonth] = useState(null)
  // const [roadmapTypes, setRoadmapTypes] = useState([])
  const [link1, setLink1] = useState("")
  const [link2, setLink2] = useState("")
  const { show: showRedirectModal, toggle: toggleRedirectModal } = useModal()

  useEffect(() => {
    if (roadmapId > 0) {
      loadData()
    }
  }, [roadmapId])

  useEffect(() => {
    if (timeScale != null && chartResp != null) {
      loadChart()
    }
  }, [timeScale, chartResp])

  useEffect(() => {
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
  }, [])

  const downloadName = "Roadmap Summary"
  const exportChartAsPDF = () => {
    if (!chartA) {
      return
    }

    chartA.saveAsPdf({ filename: downloadName })
  }

  const exportChartAsPNG = () => {
    if (!chartA) {
      return
    }

    chartA.saveAsPng({ filename: downloadName })
  }

  const loadData = async () => {
    //  api.getRoadmapChart(roadmapId, "Roadmap_Summary").then(
    let task = showSummary
      ? api.getRoadmapChart(roadmapId, "Roadmap_Summary")
      : api.getRoadmapChart(roadmapId, "Roadmap_General")
    task.then(
      resp => {
        setIsLoading(false)
        setTimeScale(resp.chartArray2)
        setRoadmapName(resp.chartTitle)
        setChartResp(resp)
        setCT(resp.chartType)
        setFYMonth(resp.chartData)
      },
      err => setIsLoading(false)
    )
  }

  const loadChart = async () => {
    if (showSummary) {
      renderGanttChart_Summary(chartResp, `gantt-${roadmapId}-${showSummary}`)
    } else {
      renderGanttChart(chartResp, `gantt-${roadmapId}-${showSummary}`)
    }
  }

  function labelsTextSettings(label, item) {
    label.fontColor(item.get("color"))
    label.fontWeight(600)
    label.background().fill(item.get("color"))
  }

  const renderGanttChart_Summary = (response, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    
    let obj = eval("(" + response.chartArray.replaceAll("'", '"') + ")")
    let json = JSON.stringify(obj)
    var data = JSON.parse(json)
    var treeData = anychart.data.tree(data, "as-tree")
    var chart = anychart.ganttProject()
    chart.data(treeData)
    var ganttDiv = document.getElementById(divId)
    var items = data.length
    var gh = 160 * items
    //ganttDiv.style.height = gh + "px"

    // disable the first data grid column
    chart.dataGrid().column(0).enabled(false)
    chart.dataGrid().column(1).enabled(false)
    //chart.dataGrid().column(1).width(400)

    var dataGrid = chart.dataGrid()
    var dgTooltip = dataGrid.tooltip()
    dgTooltip.useHtml(true)

    //chart.dataGrid(false)

    dgTooltip.format("<span>" + "{%tooltip}<br>")

    var barlabels = chart.getTimeline().elements().labels()

    barlabels.enabled(true)
    //chart.getTimeline().elements().labels().format("{%name}")
    //barlabels.fontColor("#FFFFFF")
    barlabels.fontColor("#fff")
    barlabels.anchor("left-center")
    barlabels.position("left-center")
    barlabels.useHtml(true)
    barlabels.format("<div style='background:red'>{%name}</div> ")

    chart.getTimeline().tooltip().useHtml(true)
    chart.getTimeline().tooltip().background("black")
    chart
      .getTimeline()
      .tooltip()
      .format("<span>" + "{%tooltip}<br>")

    chart.getTimeline().tooltip().width(200)

    chart
      .getTimeline()
      .scale()
      .zoomLevels([
        [
          { unit: "quarter", count: 1 },
          { unit: "year", count: 1 },
        ],
      ])

    if (fyMonth > 6) {
      var header = chart.getTimeline().header()
      header.level(1).format(function () {
        return +this.value + 1
      })
    }

    // header.level(0).format("{%tickValue}{dateTimeFormat:MMM}");

    chart.rowStroke("0.2 #64b5f6")
    chart.columnStroke("0.2 #64b5f6")
    const thisdate = new Date()
    var year = thisdate.getFullYear()
    var month = thisdate.getMonth()
    var day = thisdate.getDate()

    // chart.getTimeline().milestones().preview().enabled(true)

    chart.defaultRowHeight(25)
    chart.xScale().fiscalYearStartMonth(fyMonth) //(<%=HiddenFYMonth.Value%>);
    chart.container(divId)
    // initiate drawing the chart
    chart.draw()
    chart.credits().enabled(false)
    //chart.fitAll()

    chart.dataGrid().fixedColumns(true)

    chart.fitAll()

    //dataGrid.column(1).labelsOverrider(labelsTextSettings)

    var timeline = chart.getTimeline()
    timeline.groupingTasks(false)

    chart.listen("rowClick", function (e) {
      let link = e.item.get("link")
      let link2 = e.item.get("link2")
      if (isNullOrEmpty(link2)) {
        const location = {
          pathname: link,
          state: { fromDashboard: true },
        }
        history.push(location)
      } else {
        setLink1(link)
        setLink2(link2)
        toggleRedirectModal()
      }
    })

    setChart(chart)
  }

  const renderGanttChart = (response, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    let obj = eval("(" + response.chartArray.replaceAll("'", '"') + ")")
    let json = JSON.stringify(obj)
    var data = JSON.parse(json)
    var treeData = anychart.data.tree(data, "as-tree")
    var chart = anychart.ganttProject()
    chart.data(treeData)
    var ganttDiv = document.getElementById(divId)
    var items = data.length
    var gh = 100 + 160 * items
    //ganttDiv.style.height = gh + "px"

    var newColumn = chart.dataGrid().column(0)
    newColumn.width(60)
    newColumn.title("ID")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().useHtml(true)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("<div style='text-align: center'>{%id}</div>")
    newColumn.depthPaddingMultiplier(25)
    newColumn.collapseExpandButtons(true)

    var newColumn = chart.dataGrid().column(1)
    newColumn.width(400)
    newColumn.title("Name")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("{%name}")
    newColumn.depthPaddingMultiplier(25)
    newColumn.collapseExpandButtons(true)

    var newColumn = chart.dataGrid().column(2)
    newColumn.width(100)
    newColumn.title("Business Priority")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontFamily("Inter")
    newColumn.labels().fontFamily("Inter")
    newColumn.title().fontWeight(600)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("{%BusinessPriority}")

    if (showGrid) {
      var newColumn = chart.dataGrid().column(2)
      newColumn.width(250)
      newColumn.title("Planning Category")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().fontColor("#000000")
      newColumn.labels().format("{%planningcategory}")
      newColumn.depthPaddingMultiplier(25)
      newColumn.collapseExpandButtons(true)

      var newColumn = chart.dataGrid().column(3)
      newColumn.width(150)
      newColumn.title("Project Manager")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().fontColor("#000000")
      newColumn.labels().format("{%projectmanager}")
      newColumn.depthPaddingMultiplier(25)
      newColumn.collapseExpandButtons(true)

      var newColumn = chart.dataGrid().column(4)
      newColumn.width(150)
      newColumn.title("BRM")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().fontColor("#000000")
      newColumn.labels().format("{%brm}")
      newColumn.depthPaddingMultiplier(25)
      newColumn.collapseExpandButtons(true)

      var newColumn = chart.dataGrid().column(5)
      newColumn.width(90)
      newColumn.title("Start")
      newColumn.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn.title().fontWeight(600)
      newColumn.labels().useHtml(true)
      newColumn.labels().fontColor("#000000")
      newColumn
        .labels()
        .format(
          "<div style='text-align: center'>{%actualStart}{dateTimeFormat:MM/dd/yyyy}</div>"
        )
      newColumn.depthPaddingMultiplier(20)
      newColumn.collapseExpandButtons(true)

      var newColumn2 = chart.dataGrid().column(6)
      newColumn2.width(90)
      newColumn2.title("Finish")
      newColumn2.title().fontColor("#64b5f6")
      newColumn.title().fontFamily("Inter")
      newColumn.labels().fontFamily("Inter")
      newColumn2.title().fontWeight(600)
      newColumn2.labels().useHtml(true)
      newColumn2.labels().fontColor("#000000")
      newColumn2
        .labels()
        .format(
          "<div style='text-align: center'>{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</div>"
        )
      newColumn2.depthPaddingMultiplier(20)
      newColumn2.collapseExpandButtons(true)
    }

    var dataGrid = chart.dataGrid()
    var dgTooltip = dataGrid.tooltip()
    dgTooltip.useHtml(true)

    dgTooltip.format("<span>" + "{%tooltip}<br>")

    chart.getTimeline().tooltip().useHtml(true)
    chart
      .getTimeline()
      .tooltip()
      .format("<span>" + "{%tooltip}<br>")

    if (timeScale == "YMW") {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "week", count: 1 },
            { unit: "month", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])

      if (fyMonth > 6) {
        var header = chart.getTimeline().header()
        header.level(2).format(function () {
          return +this.value + 1
        })
      }
    } else if (timeScale == "YM") {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "month", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])

      if (fyMonth > 6) {
        var header = chart.getTimeline().header()
        header.level(1).format(function () {
          return +this.value + 1
        })
      }
    } else if (timeScale == "YQ") {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "quarter", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])

      if (fyMonth > 6) {
        var header = chart.getTimeline().header()
        header.level(1).format(function () {
          return +this.value + 1
        })
      }
    } else {
      chart
        .getTimeline()
        .scale()
        .zoomLevels([
          [
            { unit: "month", count: 1 },
            { unit: "quarter", count: 1 },
            { unit: "year", count: 1 },
          ],
        ])

      if (fyMonth > 6) {
        var header = chart.getTimeline().header()
        header.level(2).format(function () {
          return +this.value + 1
        })
      }
    }

    // header.level(0).format("{%tickValue}{dateTimeFormat:MMM}");

    chart.rowStroke("0.2 #64b5f6")
    chart.columnStroke("0.2 #64b5f6")
    const thisdate = new Date()
    var year = thisdate.getFullYear()
    var month = thisdate.getMonth()
    var day = thisdate.getDate()

    chart.getTimeline().milestones().preview().enabled(true)

    chart.title(chartResp.chartTitle)
    //chart.title("testABC")
    chart.title().fontWeight(700)
    chart.title().fontFamily("Inter")

    chart.defaultRowHeight(25)
    chart.xScale().fiscalYearStartMonth(fyMonth) //(<%=HiddenFYMonth.Value%>);
    chart.container(divId)
    // initiate drawing the chart
    chart.draw()
    chart.credits().enabled(false)
    //chart.fitAll()

    if (timeScale == "YQ") {
      chart.fitAll()
    } else {
      chart.zoomTo(Date.UTC(year, 1, 1), Date.UTC(year + 2, 11, 30))
    }

    if (showGrid) {
      chart.splitterPosition(800)
    } else {
      chart.splitterPosition(550)
    }

    dataGrid.column(1).labelsOverrider(labelsTextSettings)

    var timeline = chart.getTimeline()
    timeline.groupingTasks().progress().fill(null).stroke(null)

    chart.listen("rowClick", function (e) {
      let link = e.item.get("link")
      let link2 = e.item.get("link2")
      if (isNullOrEmpty(link2)) {
        const location = {
          pathname: link,
          state: { fromDashboard: true },
        }
        history.push(location)
      } else {
        setLink1(link)
        setLink2(link2)
        toggleRedirectModal()
      }
    })

    setChart(chart)
  }

  return (
    <>
      <div style={{ width: "100%", height: "100%", padding: "5px" }}>
        <div
          id={`gantt-${roadmapId}-${showSummary}`}
          style={{ width: "100%", height: "100%" }}
        ></div>
      </div>
      <RedirectModal
        isOpen={showRedirectModal}
        toggleModal={toggleRedirectModal}
        history={history}
        link1={link1}
        link2={link2}
      />
    </>
  )
}

export default RoadmapChart
