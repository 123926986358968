import React, { useState } from "react"
import { currencyFormatter } from "utils/formatters"

// const column = {
//   title,
//   field,
//   formatter?,
//   tdStyle
//   thStyle
//   sort
// }
const formatters = {
  stringAsHtml: (row, prop) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: row[prop],
        }}
      ></div>
    )
  },
  currency: (row, prop) => {
    return currencyFormatter.format(row[prop])
  }
}
const CustomTable = ({ columns, items, setItems, selectable }) => {
  const [sortBy, setSortBy] = useState("")
  const [sortDirection, setSortDirection] = useState("asc")

  const handleSort = prop => {
    let direction = ""
    if (sortBy == prop) {
      direction = sortDirection == "asc" ? "desc" : "asc"
    } else {
      direction = "asc"
      setSortBy(prop)
    }
    setSortDirection(direction)

    setItems(direction == "asc" ? sortAsc(items, prop) : sortDesc(items, prop))
  }

  const sortAsc = (data, prop) => {
    function compare(a, b) {
      if (a[prop] < b[prop]) {
        return -1
      }
      if (a[prop] > b[prop]) {
        return 1
      }
      return 0
    }
    let ret = [...data].sort(compare)
    return ret
  }

  const sortDesc = (data, prop) => {
    function compare(a, b) {
      if (b[prop] < a[prop]) {
        return -1
      }
      if (b[prop] > a[prop]) {
        return 1
      }
      return 0
    }
    let ret = [...data].sort(compare)
    return ret
  }

  const getSortIcon = prop => {
    let className = ""
    if (sortBy == prop) {
      className =
        sortDirection == "asc" ? "fas fa-chevron-down" : "fas fa-chevron-up"
    } else {
      className = "fas fa-chevron-down"
    }
    return (
      <i
        className={className}
        style={{ padding: "0px", marginLeft: "5px" }}
      ></i>
    )
  }

  const getFormatted = (col, item) => {
    if (col.formatter !== undefined) {
      if (typeof col.formatter == "string") {
        let formatter = formatters[col.formatter]
        if (formatter !== undefined) {
          return formatter(item, col.field)
        }
      } else {
        return col.formatter(item)
      }
    }

    return item[col.field]
  }

  return (
    <table
      className="table table-bordered low-padding-table table-condensed"
      style={{ backgroundColor: "white" }}
    >
      <thead>
        <tr>
          {columns.map((c, idx) => {
            if (c.sort) {
              return (
                <th
                  key={idx}
                  onClick={() => handleSort(c.field)}
                  style={{
                    cursor: "pointer",
                    ...c.thStyle,
                  }}
                >
                  {c.title} {getSortIcon(c.field)}
                </th>
              )
            } else {
              return (
                <th
                  key={idx}
                  style={{
                    ...c.thStyle,
                  }}
                >
                  {c.title}
                </th>
              )
            }
          })}
        </tr>
      </thead>
      <tbody>
        {items.map((item, idx) => (
          <tr key={idx}>
            {columns.map((c, idxc) => (
              <td key={idxc} style={c.tdStyle}>
                {getFormatted(c, item)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default CustomTable
