import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import useModal from "hooks/useModalHook"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row, Alert } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { ReactTabulator } from "react-tabulator"
import { createRoot } from "react-dom/client"
import EditWorkflowStepModal from "./EditWorkflowStepModal"
import isNullOrEmpty, { hasFieldError } from "utils/isNullOrEmpty"
import { Tooltip } from "@material-ui/core"

const EditWorkflowModal = ({
  isOpen,
  toggleModal,
  workflowIdToEdit,
  workflowType,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [workflowToEdit, setWorkflowToEdit] = useState(null)
  const [taskScopes, setTaskScopes] = useState([])
  const [taskTypes, setTaskTypes] = useState([])
  const [taskStatuses, setTaskStatuses] = useState([])
  const [projectTemplates, setProjectTemplates] = useState([])

  const [workflowSteps, setWorkflowSteps] = useState([])
  const [workflowStepToEdit, setWorkflowStepToEdit] = useState(null)
  const { show: showWorkflowStepModal, toggle: toggleWorkflowStepModal } =
    useModal()
  const [errorFields, setErrorFields] = useState([])

  const newWorkflow = {
    workflowID: -1,
    enterpriseID: currentUser.enterpriseID,
    scope: "",
    workflowName: "",
    description: "",
    inactive: false,
    taskTypeID: -1,
    scopeItemID: "",
    projectTemplateID: 0,
  }

  const newWorkflowStep = {
    workflowTaskTypeID: -1,
    workflowID: -1,
    taskTypeID: -1,
    orderID: 0,
    taskName: "",
    dueDays: 0,
    scopeLockRule: "",
    startOwnerID: -1,
    predID: -1,
  }

  useEffect(() => {
    if (isOpen) {
      setErrorFields([])
      setWorkflowSteps([])
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    api.getList(currentUser.userID, "TaskScope").then(r => {
      setTaskScopes(r)
    })
    api.getTaskTypesForEnterprise(currentUser.enterpriseID).then(r => {
      setTaskTypes(r)
    })
    api.getList(currentUser.userID, "ProjectTemplate").then(r => {
      setProjectTemplates(r)
    })
    if (workflowIdToEdit == -1) {
      setWorkflowToEdit({ ...newWorkflow, workflowType })
    } else {
      let w = await loadWorkflow(workflowIdToEdit)
      await loadTaskStatuses(w.taskTypeID)
      // load steps
      await loadWorkflowSteps(workflowIdToEdit)
    }
  }

  const loadWorkflow = async workflowId => {
    let w = await api.getWorkflow(workflowId)
    setWorkflowToEdit(w)
    return w
  }

  const loadTaskStatuses = async taskTypeId => {
    if (taskTypeId == -1) {
      setTaskStatuses([])
    } else {
      let ts = await api.getTaskList(taskTypeId, "TaskStatus")
      setTaskStatuses(ts)
    }
  }

  const loadWorkflowSteps = async workflowId => {
    let ws = await api.getWorkflowTaskTypes(workflowId)
    setWorkflowSteps(ws)
  }

  const addWorkflowStep = () => {
    let newId = -1
    if (workflowSteps.length > 0) {
      let minId = Math.min(...workflowSteps.map(x => x.workflowTaskTypeID))
      if (minId < 0) {
        newId = minId - 1
      }
    }
    let nextOrder = 1
    if (workflowSteps.length > 0) {
      let maxOrder = Math.min(...workflowSteps.map(x => x.orderID))
      if (nextOrder <= maxOrder) {
        nextOrder = maxOrder + 1
      }
    }
    setWorkflowStepToEdit({
      ...newWorkflowStep,
      workflowTaskTypeID: newId,
      taskTypeID: workflowToEdit.taskTypeID,
      orderID: nextOrder,
    })
    toggleWorkflowStepModal()
  }

  const editWorkflowStep = (e, step) => {
    e && e.preventDefault()
    setWorkflowStepToEdit(step)
    toggleWorkflowStepModal()
  }

  const deleteWorkflowStep = async step => {
    if (workflowToEdit.workflowTaskTypeID == -1) {
      let copy = [...workflowSteps]
      let item = copy.find(x => x.workflowTaskTypeID == step.workflowTaskTypeID)
      copy.splice(copy.indexOf(item), 1)
      setWorkflowSteps(copy)
    } else {
      await api.deleteWorkflowTaskType(
        currentUser.userID,
        step.workflowTaskTypeID
      )
      await loadWorkflowSteps(workflowToEdit.workflowID)
    }
  }

  const changeWorkflowProp = (prop, val) =>
    setWorkflowToEdit({ ...workflowToEdit, [prop]: val })

  const save = async () => {
    let errors = []
    if (isNullOrEmpty(workflowToEdit.workflowName)) {
      errors.push("workflowName")
    }
    if (workflowToEdit.taskTypeID == -1) {
      errors.push("taskTypeID")
    }
    setErrorFields(errors)
    if (errors.length > 0) {
      return
    }

    if (workflowToEdit.workflowID == -1) {
      let id = await api.createWorkflow(currentUser.userID, workflowToEdit)
      setWorkflowToEdit({ ...workflowToEdit, workflowID: id })
      await loadWorkflowSteps(id)
    } else {
      await api.updateWorkflow(currentUser.userID, workflowToEdit)
    }
    reload && reload()
    toast.success("Workflow Saved")
    toggleModal()
  }

  const updateWorkflowStepsOrder = async list => {
    let tasks = list
      .filter(x => x.isDirty)
      .map(x => async () => {
        return await api.updateWorkflowTaskType(currentUser.userID, x)
      })

    if (tasks.length > 0) {
      await Promise.all(tasks.map(t => t()))
    }
  }

  const columns =
    workflowToEdit != null && workflowToEdit.workflowType == "Workflow"
      ? [
          {
            rowHandle: true,
            formatter: "handle",
            headerSort: false,
            frozen: true,
            width: 50,
          },
          {
            title: "Step",
            field: "taskName",
            headerSort: false,
            formatter: (cell, formatterParams, onRendered) => {
              const renderFn = () => {
                const val = cell.getValue()
                const cellEl = cell.getElement()
                if (cellEl) {
                  const formatterCell = cellEl.querySelector(".formatterCell")
                  if (formatterCell) {
                    const CompWithMoreProps = React.cloneElement(
                      <a
                        href="#"
                        onClick={e =>
                          editWorkflowStep(e, cell.getRow().getData())
                        }
                      >
                        {val}
                      </a>,
                      { cell }
                    )
                    createRoot(cellEl.querySelector(".formatterCell")).render(
                      CompWithMoreProps
                    )
                  }
                }
              }

              onRendered(renderFn)

              setTimeout(() => {
                renderFn()
              }, 0)
              return '<div class="formatterCell"></div>'
            },
          },
          {
            title: "Predecessor",
            field: "predName",
            headerSort: false,
            formatter: (cell, formatterParams, onRendered) => {
              const renderFn = () => {
                const val = cell.getValue()
                const cellEl = cell.getElement()
                if (cellEl) {
                  const formatterCell = cellEl.querySelector(".formatterCell")
                  if (formatterCell) {
                    const CompWithMoreProps = React.cloneElement(
                      <Tooltip title={<h6>{val}</h6>}>
                        <div className="one-line-elipsis">{val}</div>
                      </Tooltip>,
                      { cell }
                    )
                    createRoot(cellEl.querySelector(".formatterCell")).render(
                      CompWithMoreProps
                    )
                  }
                }
              }
              onRendered(renderFn)

              setTimeout(() => {
                renderFn()
              }, 0)
              return '<div class="formatterCell"></div>'
            },
          },
          {
            title: "Starting Status",
            field: "startStatus",
            headerSort: false,
            formatter: (cell, formatterParams, onRendered) => {
              const renderFn = () => {
                const val = cell.getValue()
                const cellEl = cell.getElement()
                if (cellEl) {
                  const formatterCell = cellEl.querySelector(".formatterCell")
                  if (formatterCell) {
                    const CompWithMoreProps = React.cloneElement(
                      <Tooltip title={<h6>{val}</h6>}>
                        <div className="one-line-elipsis">{val}</div>
                      </Tooltip>,
                      { cell }
                    )
                    createRoot(cellEl.querySelector(".formatterCell")).render(
                      CompWithMoreProps
                    )
                  }
                }
              }
              onRendered(renderFn)

              setTimeout(() => {
                renderFn()
              }, 0)
              return '<div class="formatterCell"></div>'
            },
          },
          {
            title: "Due Days",
            field: "dueDays",
            headerSort: false,
          },
          {
            title: "Locking Rule",
            field: "scopeLockRule",
            headerSort: false,
            formatter: (cell, formatterParams, onRendered) => {
              const renderFn = () => {
                const val = cell.getValue()
                const cellEl = cell.getElement()
                if (cellEl) {
                  const formatterCell = cellEl.querySelector(".formatterCell")
                  if (formatterCell) {
                    const CompWithMoreProps = React.cloneElement(
                      <Tooltip title={<h6>{val}</h6>}>
                        <div className="one-line-elipsis">{val}</div>
                      </Tooltip>,
                      { cell }
                    )
                    createRoot(cellEl.querySelector(".formatterCell")).render(
                      CompWithMoreProps
                    )
                  }
                }
              }
              onRendered(renderFn)

              setTimeout(() => {
                renderFn()
              }, 0)
              return '<div class="formatterCell"></div>'
            },
          },
          {
            title: "Owner",
            field: "startOwnerName",
            headerSort: false,
            formatter: (cell, formatterParams, onRendered) => {
              const renderFn = () => {
                const val = cell.getValue()
                const cellEl = cell.getElement()
                if (cellEl) {
                  const formatterCell = cellEl.querySelector(".formatterCell")
                  if (formatterCell) {
                    const CompWithMoreProps = React.cloneElement(
                      <Tooltip title={<h6>{val}</h6>}>
                        <div className="one-line-elipsis">{val}</div>
                      </Tooltip>,
                      { cell }
                    )
                    createRoot(cellEl.querySelector(".formatterCell")).render(
                      CompWithMoreProps
                    )
                  }
                }
              }
              onRendered(renderFn)

              setTimeout(() => {
                renderFn()
              }, 0)
              return '<div class="formatterCell"></div>'
            },
          },
          {
            title: "Step action list",
            field: "stepActionList",
            headerSort: false,
            formatter: (cell, formatterParams, onRendered) => {
              return cell.getValue()
            },
          },
          {
            title: "",
            field: "",
            width: 50,
            headerSort: false,
            formatter: (cell, formatterParams, onRendered) => {
              const renderFn = () => {
                const val = cell.getValue()
                const cellEl = cell.getElement()
                if (cellEl) {
                  const formatterCell = cellEl.querySelector(".formatterCell")
                  if (formatterCell) {
                    const CompWithMoreProps = React.cloneElement(
                      <i
                        className="fas fa-trash"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() =>
                          deleteWorkflowStep(cell.getRow().getData())
                        }
                      ></i>,
                      { cell }
                    )
                    createRoot(cellEl.querySelector(".formatterCell")).render(
                      CompWithMoreProps
                    )
                  }
                }
              }

              onRendered(renderFn)

              setTimeout(() => {
                renderFn()
              }, 0)
              return '<div class="formatterCell"></div>'
            },
          },
        ]
      : workflowToEdit != null && workflowToEdit.workflowType == "Task List"
      ? [
          {
            rowHandle: true,
            formatter: "handle",
            headerSort: false,
            frozen: true,
            width: 50,
          },
          {
            title: "Task",
            field: "taskName",
            headerSort: false,
            formatter: (cell, formatterParams, onRendered) => {
              const renderFn = () => {
                const val = cell.getValue()
                const cellEl = cell.getElement()
                if (cellEl) {
                  const formatterCell = cellEl.querySelector(".formatterCell")
                  if (formatterCell) {
                    const CompWithMoreProps = React.cloneElement(
                      <a
                        href="#"
                        onClick={e =>
                          editWorkflowStep(e, cell.getRow().getData())
                        }
                      >
                        {val}
                      </a>,
                      { cell }
                    )
                    createRoot(cellEl.querySelector(".formatterCell")).render(
                      CompWithMoreProps
                    )
                  }
                }
              }

              onRendered(renderFn)

              setTimeout(() => {
                renderFn()
              }, 0)
              return '<div class="formatterCell"></div>'
            },
          },
          {
            title: "Starting Status",
            field: "startStatus",
            headerSort: false,
            formatter: (cell, formatterParams, onRendered) => {
              const renderFn = () => {
                const val = cell.getValue()
                const cellEl = cell.getElement()
                if (cellEl) {
                  const formatterCell = cellEl.querySelector(".formatterCell")
                  if (formatterCell) {
                    const CompWithMoreProps = React.cloneElement(
                      <Tooltip title={<h6>{val}</h6>}>
                        <div className="one-line-elipsis">{val}</div>
                      </Tooltip>,
                      { cell }
                    )
                    createRoot(cellEl.querySelector(".formatterCell")).render(
                      CompWithMoreProps
                    )
                  }
                }
              }
              onRendered(renderFn)

              setTimeout(() => {
                renderFn()
              }, 0)
              return '<div class="formatterCell"></div>'
            },
          },
          {
            title: "Due Days",
            field: "dueDays",
            headerSort: false,
          },
          {
            title: "Start Days",
            field: "startDTDays",
            headerSort: false,
          },
          {
            title: "Order",
            field: "orderID",
            headerSort: false,
          },

          {
            title: "",
            field: "",
            width: 50,
            headerSort: false,
            formatter: (cell, formatterParams, onRendered) => {
              const renderFn = () => {
                const val = cell.getValue()
                const cellEl = cell.getElement()
                if (cellEl) {
                  const formatterCell = cellEl.querySelector(".formatterCell")
                  if (formatterCell) {
                    const CompWithMoreProps = React.cloneElement(
                      <i
                        className="fas fa-trash"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() =>
                          deleteWorkflowStep(cell.getRow().getData())
                        }
                      ></i>,
                      { cell }
                    )
                    createRoot(cellEl.querySelector(".formatterCell")).render(
                      CompWithMoreProps
                    )
                  }
                }
              }

              onRendered(renderFn)

              setTimeout(() => {
                renderFn()
              }, 0)
              return '<div class="formatterCell"></div>'
            },
          },
        ]
      : []

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="xl"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add/Edit Workflow
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {workflowToEdit != null && (
          <div className="modal-body">
            <Row>
              <Alert
                id="helpView"
                variant="light"
                className="always-enabled"
                style={{ marginBottom: "10px", paddingBottom: "0px" }}
              >
                <div>
                  To add a workflow, first give it a name and select the
                  template that it will use. Once saved, you can add the steps
                  to the workflow. If you dont see the template that you want to
                  use, review the template creation and ensure it has the
                  required fields to be used in a workflow.
                  <ol>
                    <li>
                      Click on "Add Step" to add each step in the workflow.
                    </li>
                  </ol>
                </div>
              </Alert>
            </Row>

            <div className="mb-3">
              <Label className="form-label">Name</Label>
              <Input
                type="text"
                onChange={e =>
                  changeWorkflowProp("workflowName", e.target.value)
                }
                value={workflowToEdit.workflowName}
              />
              {hasFieldError(errorFields, "workflowName") && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Name is required
                </div>
              )}
            </div>

            <div className="mb-3">
              <Label className="form-label">Task Template</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={workflowToEdit.taskTypeID}
                onChange={e => {
                  let taskType = taskTypes.find(
                    x => x.taskTypeID == e.target.value
                  )
                  setWorkflowToEdit({
                    ...workflowToEdit,
                    taskTypeID:
                      taskType == undefined ? -1 : taskType.taskTypeID,
                    scope: taskType == undefined ? "" : taskType.scope,
                  })
                  loadTaskStatuses(e.target.value)
                }}
              >
                <option value="-1">Select template...</option>
                {taskTypes
                  .filter(x =>
                    workflowToEdit.workflowType == "Workflow"
                      ? x.canBeWorkflow
                      : true
                  )
                  .map((s, i) => (
                    <option key={i} value={s.taskTypeID}>
                      {s.taskTypeName}
                    </option>
                  ))}
              </select>
              {hasFieldError(errorFields, "taskTypeID") && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Task Template is required
                </div>
              )}
            </div>
            <div className="mb-3">
              <Label className="form-label">Project Template</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={workflowToEdit.projectTemplateID}
                onChange={e => {
                  changeWorkflowProp("projectTemplateID", e.target.value)
                }}
              >
                <option value="0">All...</option>
                {projectTemplates.map((s, i) => (
                  <option key={i} value={s.listItemID}>
                    {s.listItemName}
                  </option>
                ))}
              </select>
            </div>
            {workflowToEdit.workflowID > -1 && workflowToEdit.taskTypeID != -1 && (
              <div className="mb-3">
                <button
                  type="button"
                  className="btn btn-primary mb-3"
                  onClick={addWorkflowStep}
                >
                  {workflowToEdit.workflowType == "Workflow"
                    ? "Add Step"
                    : "Add Task"}
                </button>
                <ReactTabulator
                  columns={columns}
                  data={workflowSteps.filter(x => !x.isDeleted)}
                  options={{
                    pagination: "local",
                    paginationSize: 30,
                    paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
                    movableRows: true,
                    layout: "fitColumns",
                    cssClass: "table-borderless",
                  }}
                  events={{
                    rowMoved: function (row) {
                      let list = row.getTable().getData()
                      list = list.map((l, idx) => {
                        if (l.orderID != idx + 1) {
                          l.orderID = idx + 1
                          l.isDirty = true
                        }
                        return l
                      })

                      setWorkflowSteps(list)
                      updateWorkflowStepsOrder(list)
                    },
                  }}
                />
              </div>
            )}

            <div className="form-check mb-3">
              <Input
                type="checkbox"
                className="form-check-input"
                id="wf-inactive"
                checked={workflowToEdit.inactive}
                onClick={e => {
                  changeWorkflowProp("inactive", !workflowToEdit.inactive)
                }}
              />
              <Label className="form-check-label" for="wf-inactive">
                Inactive
              </Label>
            </div>
          </div>
        )}
        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary" onClick={save}>
            Save
          </button>
        </div>
      </Modal>
      {workflowToEdit != null && workflowToEdit.workflowID > -1 && (
        <EditWorkflowStepModal
          isOpen={showWorkflowStepModal}
          toggleModal={toggleWorkflowStepModal}
          workflowStepToEdit={workflowStepToEdit}
          setWorkflowStepToEdit={setWorkflowStepToEdit}
          taskStatuses={taskStatuses}
          taskTypes={taskTypes}
          reload={() => loadWorkflowSteps(workflowToEdit.workflowID)}
          workflowId={workflowToEdit.workflowID}
          workflowType={workflowToEdit.workflowType}
          workflowSteps={workflowSteps}
          // onSave={onWorkflowStepSave}
        />
      )}
    </>
  )
}

export default EditWorkflowModal
