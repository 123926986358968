import React, { useEffect, useRef, useState } from "react"
import Loader from "react-loaders"
import { ReactTabulator } from "react-tabulator"
import colors from "utils/colors"
import * as ReactDOMClient from "react-dom/client"
import api from "services/api.service"
import { userService } from "services/user.service"
import { Card, CardBody } from "reactstrap"

const EnterprisesGrid = ({ userEnterprise, enterprises, props }) => {
  var tabulatorRef = useRef(null)

  const handleEnterprisePress = (e, enterprise) => {
    e && e.preventDefault()
    props.history.push(`/editEnterprise/${enterprise.enterpriseID}`)
  }

  const columns = [
    {
      title: "Enterprise",
      field: "enterpriseName",
      headerSort: true,
      widthGrow: 5,
      formatter: (cell, formatterParams, onRendered) => {
        const renderFn = () => {
          const val = cell.getValue()
          const cellEl = cell.getElement()
          if (cellEl) {
            const formatterCell = cellEl.querySelector(".formatterCell")
            if (formatterCell) {
              const CompWithMoreProps = React.cloneElement(
                <a
                  href="#"
                  onClick={e =>
                    handleEnterprisePress(e, cell.getRow().getData())
                  }
                >
                  {val}
                </a>,
                { cell }
              )
              let root = ReactDOMClient.createRoot(
                cellEl.querySelector(".formatterCell")
              )
              root.render(CompWithMoreProps)
            }
          }
        }

        onRendered(renderFn) // initial render only.

        setTimeout(() => {
          renderFn() // render every time cell value changed.
        }, 0)
        return '<div class="formatterCell"></div>'
      },
    },
    {
      title: "# of Users",
      field: "numActiveUsers",
      widthGrow: 1,
    },
    {
      title: `# of ${userEnterprise != null && userEnterprise.modelLabel}s`,
      field: "numScenarios",
      widthGrow: 1,
    },
  ]
  return (
    <Card>
      <CardBody>
        {enterprises.length > 0 && (
          <>
            {/* <button
              onClick={toggleFilters}
              className="btn btn-outline-primary"
              style={{ float: "right" }}
            >
              {showFilters ? "Hide" : "Show"} <i className="fas fa-search"></i>{" "}
              <i className="fas fa-filter"></i>
            </button> */}
            <ReactTabulator
              onRef={r => (tabulatorRef.current = r.current)}
              columns={columns}
              data={enterprises}
              options={{
                pagination: "local",
                paginationSize: 30,
                paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
                layout: "fitColumns",
                cssClass: "table-borderless",
              }}
            />
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default EnterprisesGrid
