import React, { useEffect, useRef, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import {
  withScriptjs,
  withGoogleMap,
  InfoWindow,
  GoogleMap,
  Marker,
} from "react-google-maps"
import Geocode from "react-geocode"
import api from "services/api.service"
import { userService } from "services/user.service"
import { toast } from "react-toastify"
import colors from "utils/colors"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import DynamicForm from "components/custom/DynamicForm/View/DynamicForm"

function Map(props) {
  const [selectedMarker, setSelectedMarker] = useState(null)

  return (
    <GoogleMap
      defaultZoom={9}
      defaultCenter={props.defaultCenter}
      //   ref={props.ref}
      ref={map => {
        if (props.mapRef != undefined) {
          props.mapRef.current = map
        }
      }}
      bounds={props.bounds}
      {...props}
      // options={{ styles: mapStyles }}
    >
      {props.markers &&
        props.markers.map((m, i) => (
          <Marker
            key={i}
            position={m}
            onClick={() => {
              setSelectedMarker(m)
            }}
            options={{
              strokeColor: "#66009a",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: `#66009a`,
              fillOpacity: 0.35,
              zIndex: 1,
            }}
          >
            {selectedMarker != null &&
              selectedMarker.id != undefined &&
              m.id != undefined &&
              selectedMarker.id == m.id && (
                <InfoWindow onCloseClick={() => setSelectedMarker(null)}>
                  <div>
                    <div>{m.alternativeNum}</div>
                    <div>{m.alternativeName}</div>
                  </div>
                </InfoWindow>
              )}
          </Marker>
        ))}
    </GoogleMap>
  )
}

export const MapWrapped = withScriptjs(withGoogleMap(Map))

const LocationSection = ({
  alternativeId,
  scenarioId,
  activeTab,
  sectionFormId,
  showToastChangesCallback,
  triggerFormSave,
  setTriggerFormSave,
}) => {
  const newLocation = {
    locationID: -1,
    address: "",
    city: "",
    zip: "",
    state: "",
    lat: "",
    lon: "",
  }
  const mapRef = useRef(null)
  const [markers, setMarkers] = useState([])
  const [locationToEdit, setLocationToEdit] = useState(newLocation)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)
  const [locations, setLocations] = useState([])
  const [bounds, setBounds] = useState(null)

  useEffect(() => {
    if (activeTab == "Location" && alternativeId > 0) loadLocations()
  }, [activeTab, alternativeId])

  useEffect(() => {
    if (locations.length > 0) {
      setMarkers(
        locations.map(l => {
          return {
            lat: l.lat,
            lng: l.lon,
          }
        })
      )
      var b = new google.maps.LatLngBounds()
      for (var i = 0; i < locations.length; i++) {
        b.extend({ lat: locations[i].lat, lng: locations[i].lon })
      }
      b.extend({
        lat: locations[locations.length - 1].lat + 0.5,
        lng: locations[locations.length - 1].lon - 0.5,
      })
      setTimeout(() => {
        mapRef.current.fitBounds(b)
      }, 1500)
    }
  }, [locations])

  const loadLocations = async () => {
    let locs = await api.getAlternativeLocations(alternativeId)
    setLocations(locs)
  }

  const onLocationClick = (e, location) => {
    e && e.preventDefault()
    setLocationToEdit(location)
    // setMarkers([{ lat: location.lat, lng: location.lon }])
    toggleModal()
  }

  const addNewLocation = () => {
    setLocationToEdit({ ...newLocation, alternativeID: alternativeId })
    // setMarkers([])
    toggleModal()
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <button className="btn btn-primary" onClick={addNewLocation}>
            + Add Location
          </button>
          {locations.length > 0 &&
            locations.map((l, idx) => (
              <div
                key={idx}
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                  marginTop: "10px",
                }}
              >
                <h5
                  style={{
                    padding: "10px",
                    backgroundColor: "lightgray",
                    marginBottom: "0px",
                  }}
                >
                  Address
                </h5>
                <p
                  onClick={e => onLocationClick(e, l)}
                  style={{
                    padding: "10px",
                    marginBottom: "0px",
                    color: colors.primary,
                    cursor: "pointer",
                  }}
                >
                  {l.address}
                </p>
              </div>
            ))}
        </Col>
        <Col md="9">
          <MapWrapped
            mapRef={mapRef}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyA9GCVC7tKsdhlBU0HLkar9mlVSLKyWrgY&v=3.exp&libraries=geometry,drawing,places}`}
            loadingElement={<div style={{ height: "500px", width: "100%" }} />}
            containerElement={
              <div style={{ height: `500px`, width: "100%" }} />
            }
            mapElement={<div style={{ height: `500px`, width: "100%" }} />}
            markers={markers}
            defaultCenter={{ lat: 38.897, lng: -77.03985 }}
            bounds={bounds}
          />
        </Col>
      </Row>
      {sectionFormId != 0 && activeTab == "Location" && (
        <>
          <Row>
            <Col md="12">
              <div className="divider"></div>
              <DynamicForm
                formId={sectionFormId}
                itemId={alternativeId}
                scenarioId={scenarioId}
                setTriggerFormSave={setTriggerFormSave}
                triggerFormSave={triggerFormSave}
                showToastChangesCallback={showToastChangesCallback}
              />
            </Col>
          </Row>
        </>
      )}
      <EditLocation
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        location={locationToEdit}
        reloadLocations={loadLocations}
      />
    </React.Fragment>
  )
}

const EditLocation = ({ location, isOpen, toggleModal, reloadLocations }) => {
  const currentUser = userService.getLoggedInUser()
  const [locationToEdit, setLocationToEdit] = useState(null)
  const [markers, setMarkers] = useState([])
  const [addressValidated, setAddressValidated] = useState(false)

  useEffect(() => {
    Geocode.setApiKey("AIzaSyA9GCVC7tKsdhlBU0HLkar9mlVSLKyWrgY")
  }, [])

  useEffect(() => {
    setLocationToEdit({
      locationID: location.locationID,
      alternativeID: location.alternativeID,
      address: { value: location.address, isDirty: false },
      city: { value: location.city, isDirty: false },
      zip: { value: location.zip, isDirty: false },
      state: { value: location.state, isDirty: false },
      lat: { value: location.lat, isDirty: false },
      lon: { value: location.lon, isDirty: false },
    })
    if (
      location.lat != undefined &&
      location.lat != "" &&
      location.lon != undefined &&
      location.lon != ""
    ) {
      setMarkers([{ lat: location.lat, lng: location.lon }])
    } else {
      setMarkers([])
    }
  }, [location])

  const changeLocationProp = (prop, value) => {
    setAddressValidated(false)
    setLocationToEdit({
      ...locationToEdit,
      [prop]: { value: value, isDirty: true },
    })
  }

  const validateAddress = () => {
    setAddressValidated(true)
    if (locationToEdit.lat.isDirty || locationToEdit.lon.isDirty) {
      Geocode.fromLatLng(
        locationToEdit.lat.value,
        locationToEdit.lon.value
      ).then(resp => {
        const { lat, lng } = resp.results[0].geometry.location
        setMarkers([{ lat: lat, lng: lng }])
        updateLocationToEdit(resp, lat, lng)
      })
    } else {
      let add =
        locationToEdit.address.value +
        " " +
        locationToEdit.city.value +
        " " +
        locationToEdit.state.value +
        " " +
        locationToEdit.zip.value +
        " USA"
      Geocode.fromAddress(add).then(
        resp => {
          const { lat, lng } = resp.results[0].geometry.location
          setMarkers([{ lat: lat, lng: lng }])
          updateLocationToEdit(resp, lat, lng)
        },
        error => {
          console.error(error)
        }
      )
    }
  }

  const onMapClick = e => {
    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      resp => {
        updateLocationToEdit(resp, e.latLng.lat(), e.latLng.lng())
        setMarkers([{ lat: e.latLng.lat(), lng: e.latLng.lng() }])
      },
      err => {
        console.log(err)
      }
    )
  }

  const updateLocationToEdit = (resp, lat, lng) => {
    let zip = resp.results[0].address_components.find(
      x => x.types.find(t => t == "postal_code") !== undefined
    )
    let state = resp.results[0].address_components.find(
      x => x.types.find(t => t == "administrative_area_level_1") !== undefined
    )
    let city = resp.results[0].address_components.find(
      x => x.types.find(t => t == "locality") !== undefined
    )
    let addr =
      resp.results[0].address_components.find(
        x => x.types.find(t => t == "street_number") !== undefined
      )?.short_name +
      " " +
      resp.results[0].address_components.find(
        x => x.types.find(t => t == "route") !== undefined
      )?.short_name
    setLocationToEdit({
      address: { value: addr || "", isDirty: false },
      city: {
        value: city != undefined ? city.long_name : "",
        isDirty: false,
      },
      zip: { value: zip != undefined ? zip.long_name : "", isDirty: false },
      state: {
        value: state != undefined ? state.short_name : "",
        isDirty: false,
      },
      lat: { value: lat, isDirty: false },
      lon: { value: lng, isDirty: false },
      alternativeID: locationToEdit.alternativeID,
      locationID: locationToEdit.locationID,
    })
  }

  const saveLocation = async () => {
    if (
      locationToEdit.locationID == undefined ||
      locationToEdit.locationID < 0
    ) {
      await api.createAlternativeLocation(currentUser.userID, {
        locationID: -1,
        alternativeID: locationToEdit.alternativeID,
        address: locationToEdit.address.value,
        city: locationToEdit.city.value,
        zip: locationToEdit.zip.value,
        state: locationToEdit.state.value,
        lat: locationToEdit.lat.value,
        lon: locationToEdit.lon.value,
      })
      toast.success("Location added successfully")
      toggleModal()
      reloadLocations()
    } else {
      await api.updateAlternativeLocation(currentUser.userID, {
        locationID: locationToEdit.locationID,
        alternativeID: locationToEdit.alternativeID,
        address: locationToEdit.address.value,
        city: locationToEdit.city.value,
        zip: locationToEdit.zip.value,
        state: locationToEdit.state.value,
        lat: locationToEdit.lat.value,
        lon: locationToEdit.lon.value,
      })
      toast.success("Location updated successfully")
      toggleModal()
      reloadLocations()
    }
  }

  const deleteLocation = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this location?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteAlternativeLocation(locationToEdit.locationID)
          toggleModal()
          reloadLocations()
        }
      })
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit Location
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {locationToEdit !== null && (
        <div className="modal-body">
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">Address</Label>
                <Input
                  name="address"
                  type="text"
                  onChange={e => changeLocationProp("address", e.target.value)}
                  value={locationToEdit.address.value}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">City</Label>
                <Input
                  name="city"
                  type="text"
                  onChange={e => changeLocationProp("city", e.target.value)}
                  value={locationToEdit.city.value}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <div className="mb-3">
                <Label className="form-label">State</Label>
                <Input
                  name="state"
                  type="text"
                  onChange={e => changeLocationProp("state", e.target.value)}
                  value={locationToEdit.state.value}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <Label className="form-label">Zip</Label>
                <Input
                  name="zip"
                  type="text"
                  onChange={e => changeLocationProp("zip", e.target.value)}
                  value={locationToEdit.zip.value}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <div className="mb-3">
                <Label className="form-label">Latitute</Label>
                <Input
                  name="lat"
                  type="text"
                  onChange={e => changeLocationProp("lat", e.target.value)}
                  value={locationToEdit.lat.value}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <Label className="form-label">Longitude</Label>
                <Input
                  name="lon"
                  type="text"
                  onChange={e => changeLocationProp("lon", e.target.value)}
                  value={locationToEdit.lon.value}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <p>
                You must validate the address or enter a Lat and Lon to save the
                location.​
              </p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={validateAddress}
                >
                  Validate Address
                </button>

                {locationToEdit.locationID > 0 && (
                  <button
                    type="button"
                    className="btn btn-danger save-user"
                    onClick={deleteLocation}
                  >
                    Delete
                  </button>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <MapWrapped
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyA9GCVC7tKsdhlBU0HLkar9mlVSLKyWrgY&v=3.exp&libraries=geometry,drawing,places}`}
                loadingElement={
                  <div style={{ height: "400px", width: "100%" }} />
                }
                containerElement={
                  <div style={{ height: `400px`, width: "100%" }} />
                }
                mapElement={<div style={{ height: `400px`, width: "100%" }} />}
                defaultCenter={
                  markers.length > 0
                    ? markers[0]
                    : { lat: 38.897, lng: -77.03985 }
                }
                markers={markers}
                onClick={e => {
                  onMapClick(e)
                }}
              />
            </Col>
          </Row>
        </div>
      )}
      <div className="modal-footer">
        <div>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={saveLocation}
            disabled={!addressValidated}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default LocationSection
