export const sortData = (data, prop, ascDesc) => {
  function compare(a, b) {
    if (ascDesc === undefined || ascDesc === "asc") {
      if (a[prop] < b[prop]) {
        return -1
      }
      if (a[prop] > b[prop]) {
        return 1
      }
      return 0
    } else {
      if (a[prop] > b[prop]) {
        return -1
      }
      if (a[prop] < b[prop]) {
        return 1
      }
      return 0
    }
  }
  return data.sort(compare)
}
