import { Tooltip } from "@material-ui/core"
import React from "react"

const AlternativeRatingsGrid = ({
  data,
  dropdownSelection,
  objectives,
  alternativeName,
  objectivesAndMeasures,
}) => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "65vh",
          marginBotton: "0px",
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        <table
          className="table table-bordered low-padding-table"
          style={{ backgroundColor: "white" }}
        >
          <thead>
            <tr>
              <th style={{ width: "5%" }}>ID</th>
              <th style={{ width: "15%" }}>{alternativeName}</th>
              <th>Version</th>
              {dropdownSelection.value == 0 &&
                objectives.map((o, i) => {
                  return <th key={i}>{o.objectiveName}</th>
                })}
              {dropdownSelection.value == -99 &&
                objectivesAndMeasures.map((o, i) => {
                  if (o.measureID != undefined) {
                    return <th key={i}>{o.measureName}</th>
                  }
                  return <th key={i}>{o.objectiveName}</th>
                })}
              {dropdownSelection.value > 0 &&
                objectivesAndMeasures
                  .filter(
                    x =>
                      x.measureID != undefined &&
                      x.objectiveID == dropdownSelection.value
                  )
                  .map((m, i) => {
                    return <th key={i}>{m.measureName}</th>
                  })}
              <th style={{ width: "10%" }}>Benefit Score</th>
              <th style={{ width: "10%" }}>Group Rating</th>
            </tr>
          </thead>
          <tbody>
            {data.map((a, idx) => (
              <tr key={idx}>
                <td>{a.alternativeNum}</td>
                <td className="one-line-elipsis" style={{ cursor: "pointer" }}>
                  <Tooltip title={<h6>{a.alternative}</h6>}>
                    <span>
                      <b>{a.alternative}</b>
                    </span>
                  </Tooltip>
                </td>
                <td>
                  <div
                    style={{ textAlign: "center" }}
                    dangerouslySetInnerHTML={{
                      __html: a.version,
                    }}
                  ></div>
                </td>
                {dropdownSelection.value == 0 &&
                  objectives.map((o, i) => {
                    return (
                      <td key={i}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              a[
                                o.objectiveName
                                  .toLowerCase()
                                  .replaceAll(",", "")
                                  .replaceAll('"', "")
                                  .replaceAll("'", "")
                              ],
                          }}
                        ></div>
                        {/* {
                      a[
                        o.objectiveName
                          .toLowerCase()
                          .replaceAll(",", "")
                          .replaceAll('"', "")
                          .replaceAll("'", "")
                      ]
                    } */}
                      </td>
                    )
                  })}
                {dropdownSelection.value == -99 &&
                  objectivesAndMeasures.map((o, i) => {
                    if (o.measureID != undefined) {
                      return (
                        <td key={i}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                a[
                                  o.measureName
                                    .toLowerCase()
                                    .replaceAll(",", "")
                                    .replaceAll('"', "")
                                    .replaceAll("'", "")
                                ],
                            }}
                          ></div>
                          {/* {
                        a[
                          o.measureName
                            .toLowerCase()
                            .replaceAll(",", "")
                            .replaceAll('"', "")
                            .replaceAll("'", "")
                        ]
                      } */}
                        </td>
                      )
                    }
                    return (
                      <td key={i}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              a[
                                o.objectiveName
                                  .toLowerCase()
                                  .replaceAll(",", "")
                                  .replaceAll('"', "")
                                  .replaceAll("'", "")
                              ],
                          }}
                        ></div>
                        {/* {
                      a[
                        o.objectiveName
                          .toLowerCase()
                          .replaceAll(",", "")
                          .replaceAll('"', "")
                          .replaceAll("'", "")
                      ]
                    } */}
                      </td>
                    )
                  })}
                {dropdownSelection.value > 0 &&
                  objectivesAndMeasures
                    .filter(
                      x =>
                        x.measureID != undefined &&
                        x.objectiveID == dropdownSelection.value
                    )
                    .map((m, i) => {
                      return (
                        <td key={i}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                a[
                                  m.measureName
                                    .toLowerCase()
                                    .replaceAll(",", "")
                                    .replaceAll('"', "")
                                    .replaceAll("'", "")
                                ],
                            }}
                          ></div>
                          {/* {
                        a[
                          m.measureName
                            .toLowerCase()
                            .replaceAll(",", "")
                            .replaceAll('"', "")
                            .replaceAll("'", "")
                        ]
                      } */}
                        </td>
                      )
                    })}
                <td>
                  <div dangerouslySetInnerHTML={{ __html: a.saaScore }}></div>
                </td>
                <td>
                  <div dangerouslySetInnerHTML={{ __html: a.saaRating }}></div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}

export default AlternativeRatingsGrid
