import React, { useEffect, useState } from "react"

const CustomSelect = ({ label, value, onChange, disabled, options }) => {
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    if (options && options.length > 0 && value !== undefined && value !== null) {
      let s = options.find(x => x.listItemID == value)
      setSelected(s == undefined ? null : s)
    }
  }, [])

  const style =
    selected == null
      ? {}
      : {
          color: selected.foreColor !== null ? selected.foreColor : "initial",
          background:
            selected.backColor !== null ? selected.backColor : "initial",
        }

  return (
    <select
      style={style}
      className="form-control form-select select2 mb-xxl-0"
      value={value}
      onChange={e => {
        setSelected(
          e == -1 ? null : options.find(x => x.listItemID == e.target.value)
        )
        onChange(e.target.value)
      }}
      disabled={disabled}
    >
      <option
        value={-1}
        style={{
          color: "black",
          background: "white",
        }}
      >
        Select {label}
      </option>
      {options && options.map((a, idx) => {
        return (
          <option
            key={idx}
            value={`${a.listItemID}`}
            style={{
              color: a.foreColor !== null ? a.foreColor : "initial",
              background: a.backColor !== null ? a.backColor : "initial",
              fontWeight: a.listItemID < 0 ? 'bold' : 'normal'
            }}
          >
            {a.listItemName}
          </option>
        )
      })}
    </select>
  )
}

export default CustomSelect
