import moment from "moment"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import anychart from "anychart"
import Loader from "react-loaders"
import colors from "utils/colors"
import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core"

const EnterpriseResources = props => {
  const currentUser = userService.getLoggedInUser()
  const scenarioId = props.match.params.scenarioId
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [scenarios, setScenarios] = useState([])
  const [selectedScenarioOptions, setSelectedScenarioOptions] = useState([])
  const [decisions, setDecisions] = useState([])
  const [selectedDecisionId, setSelectedDecisionId] = useState(0)
  const [resourceClasses, setResourceClasses] = useState([])
  const [selectedResourceClassId, setSelectedResourceClassId] = useState(0)
  const [resourceGroups, setResourceGroups] = useState([])
  const [selectedResourceGroupId, setSelectedResourceGroupId] = useState(0)
  const [selectedChartOption, setSelectedChartOption] = useState("ByModel")
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  )
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "enterpriseResources",
      })
    )
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
    loadData()
  }, [])

  useEffect(() => {
    if (selectedScenarioOptions.length == 1) {
      loadResourceGroups()
    } else {
      setResourceGroups([])
    }
  }, [selectedScenarioOptions])

  const loadResourceGroups = async () => {
    let selectedScenId = getSelectedScenariosIds()
    let rsg = await api.getResourceGroups(selectedScenId, 0)
    setResourceGroups(rsg)
  }

  const loadData = async () => {
    let scens = await api.getScenariosByUser(currentUser.userID, 0)
    setScenarios(scens)

    // setSelectedScenarioOptions([
    //   scens.find(x => x.scenarioID == scenarioId).scenarioName,
    // ])

    let decs = await api.getList(currentUser.userID, "Decisions")
    setDecisions(decs)

    let rsc = await api.getEnterpriseList(
      currentUser.enterpriseID,
      "ResourceClass"
    )
    setResourceClasses(rsc)
  }

  const loadChart = async () => {
    let chartResp = null
    document.getElementById("chartdiv").innerHTML = ""
    setIsLoading(true)
    if (selectedChartOption == "ByModel") {
      chartResp = await api.getEnterpriseResourceChartScenario(
        1,
        moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
        moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
        getSelectedScenariosIds(),
        selectedDecisionId,
        selectedResourceClassId < 0 ? 0 : selectedResourceClassId,
        selectedResourceGroupId < 0 ? 0 : selectedResourceGroupId
      )
      let obj1 = eval("(" + chartResp.chartArray.replaceAll("'", '"') + ")")
      let json1 = JSON.stringify(obj1)
      let chartData = JSON.parse(json1)
      var dataSet = anychart.data.set(chartData)

      var maxSet = anychart.data.set(chartData)

      var maxSeriesData = maxSet.mapAs({ x: 0, value: 1 })

      // helper function to setup label settings for all series
      var setupSeriesLabels = function (series, name) {
        series.name(name).stroke("3 #fa6b71 1")
        series.hovered().stroke("3 #fa6b71 1")
      }

      // create bar chart
      var chart = anychart.area()

      var alts = chartResp.chartArray2.split(",").filter(x => x !== "")

      var numseries = parseInt(chartResp.chartData, 10)
      var j = 0

      for (var i = 0; i < numseries; i++) {
        j = i + 1

        chart.addSeries(
          dataSet.mapAs({
            x: 0,
            value: j + 1, //,
            //'tooltip': 4
          })
        )

        chart.getSeriesAt(i).name(alts[i])
      }

      var tooltip = chart.tooltip()
      tooltip.displayMode("single")
      // set X scale mode to continuous
      chart.xScale().mode("continuous")

      let series = chart.line(maxSeriesData)
      setupSeriesLabels(series, "Capacity")

      // turn on chart animation
      chart.animation(true)

      // force chart to stack values by Y scale.
      chart.yScale().stackMode("value")

      // set chart title text settings
      chart.title(chartResp.chartTitle)
      chart.title().padding([0, 0, 5, 0])

      var xAxisLabels = chart.xAxis().labels()
      xAxisLabels.rotation(90)

      // turn on legend
      chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0])
      // set yAxis labels formatter
      chart.yAxis().labels().format("{%Value}{groupsSeparator: }")

      // set titles for axes
      chart.xAxis().title("Months")
      chart.yAxis().title("Units")

      // set interactivity hover
      chart.interactivity().hoverMode("bySpot")
      chart.interactivity().spotRadius(10)

      chart.xScroller().enabled(true)
      chart.xZoom().setToPointsCount(12)
      chart.container("chartdiv")
      chart.credits().enabled(false)
      chart.draw()
      // let dataArray1 = JSON.parse(chartResp.chartArray.replaceAll("'", '"'))
      // let dataArray2 = JSON.parse(chartResp.chartArray2.replaceAll("'", '"'))

      // var dataSet = anychart.data.set(dataArray1)

      // var maxSet = anychart.data.set(dataArray2)

      // var maxSeriesData = maxSet.mapAs({ x: 0, value: 1 })

      // var setupSeriesLabels = function (series, name) {
      //   series.name(name).stroke("3 #fff 1")
      //   series.hovered().stroke("3 #fff 1")
      // }
      // var chart = anychart.area()
      // chart.xScale().mode("continuous")
      // var series = chart.line(maxSeriesData)
      // setupSeriesLabels(series, "Capacity")
      // series.stroke("#ff0000")
      // chart.animation(true)

      // let sc = chartResp.chartData.split(",")
      // sc.forEach(scen => {
      //   let seriesData = dataSet.mapAs({ x: 0, value: scen })
      //   let s = chart.area(seriesData)
      //   setupSeriesLabels(s, scen)
      // })

      // chart.yScale().stackMode("value")
      // chart.title(chartResp.chartTitle)
      // chart.title().padding([0, 0, 5, 0])

      // var xAxisLabels = chart.xAxis().labels()
      // xAxisLabels.rotation(90)
      // chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0])
      // // set yAxis labels formatter
      // chart.yAxis().labels().format("{%Value}{groupsSeparator: }")
      // chart.xAxis().title("Period")
      // chart.yAxis().title("Units")
      // chart.yScale().minimum(0)
      // chart.interactivity().hoverMode("by-x")

      // chart.xScroller().enabled(true)
      // chart.xZoom().setToPointsCount(12)
      // chart.container("chartdiv")
      // chart.credits().enabled(false)
      // chart.draw()
    } else {
      if (selectedChartOption == "ByClass") {
        chartResp = await api.getEnterpriseResourceChartClass(
          1,
          moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
          moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
          getSelectedScenariosIds(),
          selectedDecisionId,
          selectedResourceClassId < 0 ? 0 : selectedResourceClassId
        )
      } else {
        chartResp = await api.getEnterpriseResourceChartResource(
          1,
          moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
          moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
          getSelectedScenariosIds(),
          selectedDecisionId,
          selectedResourceClassId < 0 ? 0 : selectedResourceClassId
        )
      }

      let obj1 = eval("(" + chartResp.chartArray.replaceAll("'", '"') + ")")
      let json1 = JSON.stringify(obj1)
      let dataArray1 = JSON.parse(json1)

      var dataSet = anychart.data.set(dataArray1)

      var maxSet = anychart.data.set(dataArray1)

      var maxSeriesData = maxSet.mapAs({ x: 0, value: 1 })

      // helper function to setup label settings for all series
      var setupSeriesLabels = function (series, name) {
        series.name(name).stroke("3 #fa6b71 1")
        series.hovered().stroke("3 #fa6b71 1")
      }

      // create bar chart
      var chart = anychart.area()

      var alternatives = chartResp.chartArray2.split(",").filter(x => x !== "")

      var numseries = parseInt(chartResp.chartData, 10)
      var j = 0

      for (var i = 0; i < numseries; i++) {
        j = i + 1

        chart.addSeries(
          dataSet.mapAs({
            x: 0,
            value: j + 1, //,
            //'tooltip': 4
          })
        )

        chart.getSeriesAt(i).name(alternatives[i])
      }

      chart.xScale().mode("continuous")

      let series = chart.line(maxSeriesData)
      setupSeriesLabels(series, "Capacity")
      chart.animation(true)
      chart.yScale().stackMode("value")

      chart.title(chartResp.chartTitle)
      chart.title().padding([0, 0, 5, 0])
      var xAxisLabels = chart.xAxis().labels()
      xAxisLabels.rotation(90)

      // turn on legend
      chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0])
      // set yAxis labels formatter
      chart.yAxis().labels().format("{%Value}{groupsSeparator: }")

      // set titles for axes
      chart.xAxis().title("Period")
      chart.yAxis().title("Units")

      chart.yScale().minimum(0)

      // set interactivity hover
      chart.interactivity().hoverMode("by-x")

      chart.xScroller().enabled(true)
      chart.xZoom().setToPointsCount(12)
      chart.container("chartdiv")
      chart.credits().enabled(false)
      chart.draw()
    }
    setIsLoading(false)
  }

  const handleChange = e => {
    const {
      target: { value },
    } = e
    if (e.currentTarget.innerText == "All") {
      if (value.indexOf("All") > -1) {
        setSelectedScenarioOptions([
          "All",
          ...scenarios.map(x => x.scenarioName),
        ])
      } else {
        setSelectedScenarioOptions([])
      }
    } else {
      setSelectedScenarioOptions(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      )
    }
  }

  const getSelectedScenariosIds = () => {
    let scens = scenarios.filter(
      x => selectedScenarioOptions.indexOf(x.scenarioName) > -1
    )
    return scens.map(x => x.scenarioID).join(",")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody
              style={{
                display: "flex",
                flexDirection: "column",
                height: "85vh",
              }}
            >
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      style={{ marginBottom: "10px" }}
                    >
                      Models
                    </Label>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={selectedScenarioOptions}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      renderValue={selected => selected.join(", ")}
                    >
                      <MenuItem value={"All"}>
                        <Checkbox
                          checked={selectedScenarioOptions.indexOf("All") > -1}
                          color="primary"
                        />
                        <ListItemText primary={"All"} />
                      </MenuItem>
                      {scenarios.map(s => (
                        <MenuItem key={s.scenarioID} value={s.scenarioName}>
                          <Checkbox
                            checked={
                              selectedScenarioOptions.indexOf(s.scenarioName) >
                              -1
                            }
                            color="primary"
                          />
                          <ListItemText primary={s.scenarioName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col md="6" style={{ display: "flex", alignItems: "center" }}>
                  <button className="btn btn-primary" onClick={loadChart}>
                  <i className="fas fa-chart-area"></i> View / Refresh Chart
                  </button>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <div className="mb-3">
                    <Label className="form-label">By Resource Class</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={selectedResourceClassId}
                      onChange={e => {
                        setSelectedResourceClassId(e.target.value)
                      }}
                    >
                      <option value="0">All</option>
                      {resourceClasses.map((s, idx) => (
                        <option key={idx} value={s.listItemID}>
                          {s.listItemName}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col sm="3">
                  <div className="mb-3">
                    <Label className="form-label">Show Alternatives</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={selectedDecisionId}
                      onChange={e => {
                        setSelectedDecisionId(e.target.value)
                      }}
                    >
                      <option key="0">All</option>
                      {decisions.map((s, idx) => (
                        <option key={idx} value={s.listItemID}>
                          {s.listItemName}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label className="form-label">Chart Option</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={selectedChartOption}
                      onChange={e => {
                        setSelectedChartOption(e.target.value)
                      }}
                    >
                      <option value="ByModel">Show Data by Model</option>
                      <option value="ByClass">
                        Show Data by Resource Class
                      </option>
                      <option value="ByResource">Show Data by Resource</option>
                      {/* {decisions.map((s, idx) => (
                            <option key={idx} value={s.listItemID}>
                              {s.listItemName}
                            </option>
                          ))} */}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <div className="mb-3">
                    <Label className="form-label">or by Resource</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={selectedResourceGroupId}
                      onChange={e => {
                        setSelectedResourceGroupId(e.target.value)
                      }}
                    >
                      <option value="0">All</option>
                      {resourceGroups.map((s, idx) => (
                        <option key={idx} value={s.resourceGroupID}>
                          {s.resourceGroupName}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label>Start Date</Label>
                    <Input
                      name="startDate"
                      type="date"
                      onChange={e =>
                        setStartDate(moment(e.target.value, "YYYY-MM-DD"))
                      }
                      value={moment(startDate).format("YYYY-MM-DD")}
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label>End Date</Label>
                    <Input
                      name="endDate"
                      type="date"
                      onChange={e =>
                        setEndDate(moment(e.target.value, "YYYY-MM-DD"))
                      }
                      value={moment(endDate).format("YYYY-MM-DD")}
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ flexGrow: 1 }}>
                {isLoading && (
                  <Loader
                    type="line-scale-pulse-out"
                    color={colors.primary}
                    style={{ textAlign: "center" }}
                  />
                )}
                <Col>
                  <div style={{ height: "100%" }} id="chartdiv"></div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EnterpriseResources
