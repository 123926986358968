import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import React, { useEffect, useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import Loader from "react-loaders"
import { ReactTabulator } from "react-tabulator"
import { toast } from "react-toastify"
import { Col, FormFeedback, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"

const ContractEntitiesSection = ({ enterpriseId }) => {
  const tabulatorRef = useRef()
  const [listItems, setListItems] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [types, setTypes] = useState([])
  const [selectedTypeName, setSelectedTypeName] = useState(-1)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newItemName, setNewItemName] = useState("")
  const [newItemType, setNewItemType] = useState(-1)
  const [hasNameError, setHasNameError] = useState(false)
  const [hasTypeError, setHasTypeError] = useState(false)

  useEffect(() => {
    if (enterpriseId > -1) {
      loadData()
    }
  }, [enterpriseId])

  const loadData = async () => {
    try {
      let tps = await api.getEnterpriseList(enterpriseId, "ContractEntityType")
      setTypes(tps)
      await loadList()
    } catch (err) {
      //   setIsLoading(false)
    }
  }

  const loadList = async () => {
    let list = await api.getEnterpriseList(enterpriseId, "ContractEntity")
    setFilteredItems(list.filter(x => x.listItemCategory == selectedTypeName))
    setListItems(list)
  }

  const addListItem = () => {
    setNewItemName("")
    setNewItemType(-1)
    setHasNameError(false)
    setHasTypeError(false)
    toggleModal()
  }

  const createListItem = async () => {
    if (newItemName == "") {
      setHasNameError(true)
    } else if (newItemType == -1) {
      setHasTypeError(true)
    } else {
      await api.createEnterpriseListItem(enterpriseId, "ContractEntity", {
        listItemName: newItemName,
        listItemCategory: newItemType,
      })
      toggleModal()
      await loadList()
    }
  }

  const updateListItem = async listItem => {
    try {
      await api.updateEnterpriseListItem(
        enterpriseId,
        "ContractEntity",
        listItem
      )
      await loadList()
    } catch (err) {
      console.log(err)
    }
  }

  const deleteListItem = item => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this item?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          let ret = await api.deleteEnterpriseListItem(
            item.listItemID,
            "ContractEntity",
            item
          )
          toast.success(
            ret == -1
              ? "Item deleted successfully"
              : "The item was inactivated because it is in use"
          )
          await loadList()
        }
      })
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const columns = [
    {
      title: "Type",
      field: "listItemCategory",
      headerSort: false,
    },
    {
      title: "Contract Entity",
      field: "listItemName",
      headerSort: false,
      editor: "input",
    },
    {
      title: "State",
      field: "inactive",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() == null || cell.getValue() == false
          ? "Active"
          : "Inactive"
      },
      width: 150,
      headerSort: false,
      editor: "select",
      editorParams: {
        values: { false: "Active", true: "Inactive" },
      },
    },
    {
      field: "",
      headerSort: false,
      width: 50,
      formatter: (cell, formatterParams, onRendered) => {
        const renderFn = () => {
          const cellEl = cell.getElement()
          if (cellEl) {
            const formatterCell = cellEl.querySelector(".formatterCell")
            if (formatterCell) {
              const CompWithMoreProps = React.cloneElement(
                <i
                  className="fas fa-trash-alt"
                  style={{ color: "red" }}
                  onClick={() => deleteListItem(cell.getRow().getData())}
                ></i>,
                { cell }
              )
              createRoot(cellEl.querySelector(".formatterCell")).render(
                CompWithMoreProps
              )
            }
          }
        }

        onRendered(renderFn)

        setTimeout(() => {
          renderFn()
        }, 0)
        return '<div class="formatterCell"></div>'
      },
    },
  ]

  return (
    <React.Fragment>
      <button
        onClick={() => addListItem()}
        className="btn btn-primary"
        style={{ marginBottom: "10px" }}
      >
        <i className="fas fa-plus"></i> Add Contract Entity
      </button>
      {types.length > 0 && (
        <Row>
          <Col sm="8">
            <div className="mb-3">
              <Label className="form-label">Contract Entity Type</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={selectedTypeName}
                onChange={e => {
                  setSelectedTypeName(e.target.value)
                  if (e.target.value != -1) {
                    setFilteredItems(
                      listItems.filter(
                        x => x.listItemCategory == e.target.value
                      )
                    )
                  }
                }}
              >
                <option value={-1}>Select Contract Entity Type</option>
                {types.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.listItemName}`}>
                      {a.listItemName}
                    </option>
                  )
                })}
              </select>
            </div>
          </Col>
        </Row>
      )}
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      <Row>
        <Col sm="8">
          {!isLoading && selectedTypeName != -1 && listItems.length > 0 && (
            <ReactTabulator
              onRef={r => (tabulatorRef.current = r.current)}
              columns={columns}
              data={filteredItems}
              options={{
                pagination: "local",
                paginationSize: 30,
                paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
                layout: "fitColumns",
                cssClass: "table-borderless",
              }}
              events={{
                cellEdited: function (cell) {
                  updateListItem(cell.getData())
                },
              }}
            />
          )}
        </Col>
      </Row>
      <Modal
      backdrop="static"
        isOpen={isModalOpen}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Contract Entity
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Contract Entity Type</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={newItemType}
              onChange={e => {
                setNewItemType(e.target.value)
              }}
            >
              <option value={-1}>Select Contract Entity Type</option>
              {types.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemName}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
            {hasTypeError ? (
              <FormFeedback type="invalid">Type is required</FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <Label className="form-label">Name</Label>
            <Input
              name="name"
              type="text"
              value={newItemName}
              onChange={e => {
                setNewItemName(e.target.value)
                setHasNameError(false)
              }}
              invalid={hasNameError}
            />
            {hasNameError ? (
              <FormFeedback style={{ display: "block" }} type="invalid">
                Name is required
              </FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={createListItem}
          >
            Save
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ContractEntitiesSection
