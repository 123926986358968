import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Dropdown,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import BusinessCaseSection from "./BusinessCaseSection"
import GeneralSection from "./GeneralSection"
import ManageListSection from "./ManageListSection"
import ParticipantOptionsSection from "./ParticipantOptionsSection"
import TrackingOptionsSection from "./TrackingOptionsSection"
import PlanningStagesSection from "./PlanningStagesSection"
import FieldsSection from "./FieldsSection"
import BusinessRulesSection from "./BusinessRulesSection"

const EditModel = props => {
  const currentUser = userService.getLoggedInUser()
  const [activeTab, setActiveTab] = useState("1")
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [scenarioId, setScenarioId] = useState(-1)
  let [scenario, setScenario] = useState(null)
  let [enterprise, setEnterprise] = useState(null)
  let [isLoading, setIsLoading] = useState(false)
  let [alternativeScoreLabels, setAlternativeScoreLabels] = useState([])
  let [alternativeLabels, setAlternativeLabels] = useState([])
  let [objectiveLabels, setObjectiveLabels] = useState([])
  let [measureLabels, setMeasureLabels] = useState([])
  let [costLabels, setCostLabels] = useState([])
  let [poolTypes, setPoolTypes] = useState([])
  let [ahpRatings, setAhpRatings] = useState([])
  let [ahpMeans, setAhpMeans] = useState([])
  let [criteriaPrioritizationStates, setCriteriaPrioritizationStates] =
    useState([])
  let [inputRatingScale, setInputRatingScale] = useState([])
  let [outputRatingScale, setOutputRatingScale] = useState([])
  let [parentScenarios, setParentScenarios] = useState([])
  let [combinedScoreTypes, setCombinedScoreTypes] = useState([])
  let [selectedManageableList, setSelectedManageableList] = useState({
    text: "Manage Lists",
    value: -1,
  })
  const [triggerSaveFields, setTriggerSaveFields] = useState(false)
  const [triggerSaveBusinessCase, setTriggerSaveBusinessCase] = useState(false)
  const [scenarioTypes, setScenarioTypes] = useState([])
  const manageableLists = [
    {
      text: "Manage Lists",
      value: -1,
    },
    {
      value: "Strategy",
      text: "Acquisition Approach",
    },
    {
      value: "AdvancedPlanning",
      text: "Advanced Planning",
    },
    {
      value: "GovernanceBoard",
      text: "Governance Board",
    },
    {
      value: "InvestmentCategory",
      text: "Investment Categories",
    },
    {
      value: "LDO",
      text: "Lead Delivery Organizations",
    },
    {
      value: "PlanningCategory",
      text: "Planning Categories",
    },
    {
      value: "SponsoringUnit",
      text: "Sponsoring Unit",
    },
    {
      value: "WorkflowStatus",
      text: "Workflow Status",
    },
  ]

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: props.match.params.scenarioId,
        alternativeId: 0,
        viewName: "editModel",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: props.match.params.scenarioId,
        viewName: "editModel",
      })
    )
    loadData()
    loadEnterprise()
    setScenarioId(props.match.params.scenarioId)
  }, [props.match.params.scenarioId])

  useEffect(() => {
    loadScenario()
  }, [scenarioId])

  const loadEnterprise = async () => {
    let e = await api.getEnterprise(currentUser.enterpriseID)
    setEnterprise(e)
  }

  const loadData = async () => {
    setIsLoading(true)
    await loadGeneralDropdownData()
    setIsLoading(false)
  }

  const loadScenario = async () => {
    if (scenarioId > -1) {
      try {
        let s = await api.getScenario(scenarioId)
        setScenario(s)
      } catch (err) {
        console.log(err)
      }
    } else {
      setScenario({ scenarioID: -1, scenarioTypeID: -1 })
    }
  }

  const loadGeneralDropdownData = async () => {
    let altScoreLabels = await api.getList(
      currentUser.userID,
      "AlternativeScoreLabel"
    )
    setAlternativeScoreLabels(altScoreLabels)
    let scTypes = await api.getList(currentUser.userID, "ScenarioTypes")
    setScenarioTypes(scTypes)
    let altLabels = await api.getList(currentUser.userID, "AlternativeLabel")
    setAlternativeLabels(altLabels)
    let objLabels = await api.getList(currentUser.userID, "ObjectiveLabel")
    setObjectiveLabels(objLabels)
    let measLabels = await api.getList(currentUser.userID, "MeasureLabel")
    setMeasureLabels(measLabels)
    let cost = await api.getList(currentUser.userID, "CostLabel")
    setCostLabels(cost)
    let pool = await api.getList(currentUser.userID, "PoolType")
    setPoolTypes(pool)
    let inputRating = await api.getList(currentUser.userID, "InputRatingScale")
    setInputRatingScale(inputRating)
    let outputRating = await api.getList(
      currentUser.userID,
      "OutputRatingScale"
    )
    setOutputRatingScale(outputRating)
    let parents = await api.getList(
      currentUser.userID,
      "PotentialParentScenarios"
    )
    setParentScenarios(parents)
    let scores = await api.getList(currentUser.userID, "CombinedScoreType")
    setCombinedScoreTypes(scores)
    let crits = await api.getList(currentUser.userID, "ScoreState")
    setCriteriaPrioritizationStates(crits)
    let ahpRat = await api.getList(currentUser.userID, "AHPRatings")
    setAhpRatings(ahpRat)
    let means = await api.getList(currentUser.userID, "AHPMean")
    setAhpMeans(means)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const saveScenario = async () => {
    try {
      toast.dismiss()
      if (
        activeTab == "1" ||
        activeTab == "2" ||
        activeTab == "3" ||
        activeTab == "8"
      ) {
        if(activeTab == "2"){
          setTriggerSaveBusinessCase(true)
        }
        if (scenarioId > -1) {
          await api.updateScenario(currentUser.userID, scenario)
          toast.success(`Portfolio updated successfuly`)
          loadScenario()
        } else {
          let newScenId = await api.createScenario(currentUser.userID, scenario)
          if (newScenId > -1) {
            setScenarioId(newScenId)
            props.history.replace({
              pathname: `/editModel/${newScenId}`,
            })
            toast.success(`Portfolio created successfuly`)
          } else {
            toast.error(`There was a problem saving the model`)
          }
        }
      } else if (activeTab == "5") {
        setTriggerSaveFields(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const deleteScenario = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this portfolio?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteScenario(currentUser.userID, scenarioId)
          props.history.goBack()
        }
      })
  }

  const createSecondaryScenario = async (copyTeam, attributeName) => {
    await api.createSecondaryScenario(
      currentUser.userID,
      scenario.scenarioID,
      copyTeam,
      attributeName
    )
    await loadScenario()
  }

  const cancel = () => {
    toast.dismiss()
    props.history.goBack()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            /*                       title={`${scenarioId == -1 ? "Add Decision" : "Settings"} ${
                        enterprise != null ? enterprise.modelLabel : "Portfolio"
                      }`} */
            title={`${scenarioId == -1 ? "Add Decision" : "Settings"}`}
          />

          <Row>
            <Col>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {scenario != null && (
                <Card>
                  <CardBody>
                    <Row>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end",
                        }}
                      >
                        {activeTab != "4" && (
                          <button
                            type="button"
                            className="btn btn-primary save-user"
                            onClick={saveScenario}
                          >
                            <i className="fas fa-save"></i>
                            {scenarioId > -1 ? " Update" : " Save"}
                          </button>
                        )}
                        <button
                          type="button"
                          className="btn btn-outline-secondary save-user"
                          onClick={cancel}
                        >
                          Cancel
                        </button>
                        {scenarioId > -1 && (
                          <button
                            type="button"
                            className="btn btn-danger save-user"
                            onClick={deleteScenario}
                          >
                            <i className="fas fa-times"></i> Delete
                          </button>
                        )}
                      </div>
                    </Row>
                    {scenario.scenarioID > -1 && (
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => {
                              toggleTab("1")
                            }}
                          >
                            <b>General</b>
                          </NavLink>
                        </NavItem>



                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "2" ? "active" : ""}
                            onClick={() => {
                              toggleTab("2")
                            }}
                          >
                            <b>Business Case</b>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "3" ? "active" : ""}
                            onClick={() => {
                              toggleTab("3")
                            }}
                          >
                            <b>Business Rules</b>
                          </NavLink>
                        </NavItem>                        


                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "4" ? "active" : ""}
                            onClick={() => {
                              toggleTab("4")
                            }}
                          >
                            <select
                              className="form-control form-select select2 mb-xxl-0"
                              style={{
                                cursor: "pointer",
                                padding: "2px 1.75rem 2px 2px",
                                border: "none",
                                fontWeight: "bold",
                              }}
                              value={selectedManageableList.value}
                              onChange={e => {
                                let lst = manageableLists.find(
                                  x => x.value == e.target.value
                                )
                                setSelectedManageableList(lst)
                              }}
                            >
                              {manageableLists.map((m, idx) => {
                                return (
                                  <option key={idx} value={m.value}>
                                    {m.text}
                                  </option>
                                )
                              })}
                            </select>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "5" ? "active" : ""}
                            onClick={() => {
                              toggleTab("5")
                            }}
                          >
                            <b>Fields</b>
                          </NavLink>
                        </NavItem>                        


                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "6" ? "active" : ""}
                            onClick={() => {
                              toggleTab("6")
                            }}
                          >
                            <b>Participant Options</b>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "7" ? "active" : ""}
                            onClick={() => {
                              toggleTab("7")
                            }}
                          >
                            <b>Planning Stages</b>
                          </NavLink>
                        </NavItem>                       


                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "8" ? "active" : ""}
                            onClick={() => {
                              toggleTab("8")
                            }}
                          >
                            <b>Tracking Options</b>
                          </NavLink>
                        </NavItem>










                      </Nav>
                    )}
                    <TabContent
                      activeTab={activeTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <GeneralSection
                          scenario={scenario}
                          setScenario={setScenario}
                          alternativeScoreLabels={alternativeScoreLabels}
                          alternativeLabels={alternativeLabels}
                          objectiveLabels={objectiveLabels}
                          measureLabels={measureLabels}
                          costLabels={costLabels}
                          poolTypes={poolTypes}
                          criteriaPrioritizationStates={
                            criteriaPrioritizationStates
                          }
                          ahpRatings={ahpRatings}
                          ahpMeans={ahpMeans}
                          inputRatingScale={inputRatingScale}
                          outputRatingScale={outputRatingScale}
                          parentScenarios={parentScenarios}
                          combinedScoreTypes={combinedScoreTypes}
                          scenarioTypes={scenarioTypes}
                          enterprise={enterprise}
                          props={props}
                          createSecondaryScenario={createSecondaryScenario}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        {scenario.scenarioID > -1 && (
                          <BusinessCaseSection
                            activeTab={activeTab}
                            scenario={scenario}
                            setScenario={setScenario}
                            saveScenario={saveScenario}
                            triggerSave={triggerSaveBusinessCase}
                            setTriggerSave={setTriggerSaveBusinessCase}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="6">
                        {scenario.scenarioID > -1 && (
                          <ParticipantOptionsSection
                            scenario={scenario}
                            setScenario={setScenario}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="4">
                        {scenario.scenarioID > -1 && (
                          <ManageListSection
                            scenarioId={scenarioId}
                            selectedManageableList={selectedManageableList}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="8">
                        {scenario !== undefined && scenario != null && (
                          <TrackingOptionsSection
                            activeTab={activeTab}
                            scenario={scenario}
                            setScenario={setScenario}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="7">
                        {scenarioId > -1 && (
                          <PlanningStagesSection
                            activeTab={activeTab}
                            scenarioId={scenarioId}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="5">
                        {scenarioId > -1 && (
                          <FieldsSection
                            activeTab={activeTab}
                            scenarioId={scenarioId}
                            triggerSaveFields={triggerSaveFields}
                            setTriggerSaveFields={setTriggerSaveFields}
                          />
                        )}
                      </TabPane>
                      <TabPane tabId="3">
                        {scenarioId > -1 && (
                          <BusinessRulesSection
                            activeTab={activeTab}
                            scenarioId={scenarioId}
                          />
                        )}
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditModel
