import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import anychart from "anychart"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import renderUserChart from "./userChartRenderer"
import { toast } from "react-toastify"
import { sortData } from "utils/sort"
import CustomColorPicker from "components/custom/CustomColorPicker"
import useModal from "hooks/useModalHook"
import ChartDrilldownModal from "./ChartDrilldownModal"
import { currencyFormatter } from "utils/formatters"
import isNullOrEmpty from "utils/isNullOrEmpty"

const EditUserChartModal = ({
  isOpen,
  toggleModal,
  chartToEdit,
  setChartToEdit,
  reloadData,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [chartGroupItems, setChartGroupItems] = useState([])
  const [selectedChartGroupItem, setSelectedChartGroupItem] = useState(["Any"])
  const [chartValues, setChartValues] = useState([])
  const [chartScopes, setChartScopes] = useState([])
  const [roadmaps, setRoadmaps] = useState([])
  const [alternativeLists, setAlternativeLists] = useState([])
  const [selectedChartValue, setSelectedChartValue] = useState(["Any"])
  const [chartTypes, setChartTypes] = useState([])
  const [selectedChartType, setSelectedChartType] = useState(["Any"])
  const [scenarios, setScenarios] = useState([])
  const [selectedScenario, setSelectedScenario] = useState(["Any"])
  const [bcStatuses, setBcStatuses] = useState([])
  const [selectedBcStatus, setSelectedBcStatus] = useState(["Any"])
  const [priorities, setPriorities] = useState([])
  const [selectedPriorities, setSelectedPriorities] = useState(["Any"])
  const [complete, setComplete] = useState([])
  const [selectedComplete, setSelectedComplete] = useState(["Any"])
  const [years, setYears] = useState([])
  const [selectedYears, setSelectedYears] = useState(["Any"])
  const [decisions, setDecisions] = useState([])
  const [selectedDecision, setSelectedDecision] = useState(["Any"])
  const [ldoList, setLdoList] = useState([])
  const [selectedLdo, setSelectedLdo] = useState(["Any"])
  const [pocList, setPocList] = useState([])
  const [selectedPoc, setSelectedPoc] = useState(["Any"])
  const [brmList, setBrmList] = useState([])
  const [selectedBrm, setSelectedBrm] = useState(["Any"])
  const [pmList, setPmList] = useState([])
  const [selectedPm, setSelectedPm] = useState(["Any"])
  const [sponsorList, setSponsorList] = useState([])
  const [selectedSponsor, setSelectedSponsor] = useState(["Any"])
  const [submitterList, setSubmitterList] = useState([])
  const [selectedSubmitter, setSelectedSubmitter] = useState(["Any"])
  const [governanceBoards, setGovernanceBoards] = useState([])
  const [selectedGovernanceBoard, setSelectedGovernanceBoard] = useState([
    "Any",
  ])
  const [workflows, setWorkflows] = useState([])
  const [selectedWorkflow, setSelectedWorkflow] = useState(["Any"])

  const [CPICPhases, setCPICPhases] = useState([])
  const [selectedCPICPhase, setSelectedCPICPhase] = useState(["Any"])
  const [AdvancedPlannings, setAdvancedPlannings] = useState([])
  const [selectedAdvancedPlanning, setSelectedAdvancedPlanning] = useState([
    "Any",
  ])

  const [investmentCategories, setInvestmentCategories] = useState([])
  const [selectedInvestment, setSelectedInvestment] = useState(["Any"])
  const [projectStatuses, setProjectStatuses] = useState([])
  const [selectedProjectStatus, setSelectedProjectStatus] = useState(["Any"])
  const [planningCategories, setPlanningCategories] = useState([])
  const [selectedPlanningCategory, setSelectedPlanningCategory] = useState([
    "Any",
  ])
  const [excludeList, setExcludeList] = useState([])
  const [selectedExclude, setSelectedExclude] = useState(["Any"])

  const [errorFields, setErrorFields] = useState([])

  const [drilldownDataTable, setDrilldownDataTable] = useState([])
  const { show: showDrilldownModal, toggle: toggleDrilldownModal } = useModal()

  useEffect(() => {
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
    if (isOpen) loadModalData()
  }, [isOpen])

  useEffect(() => {
    if (isOpen && chartToEdit != null) {
      setErrorFields([])
      setTimeout(() => {
        selectExistingFilters("A.ProjectState", setSelectedProjectStatus)
        selectExistingFilters("A.WorkflowStatusID", setSelectedWorkflow)
        selectExistingFilters("A.CPICPhaseID", setSelectedCPICPhase)
        selectExistingFilters(
          "A.AdvancedPlanningID",
          setSelectedAdvancedPlanning
        )
        selectExistingFilters("A.BCState", setSelectedBcStatus)
        selectExistingFilters("B.BusinessPriority​", setSelectedPriorities)
        selectExistingFilters("A.BRMUserID", setSelectedBrm)
        selectExistingFilters("A.Inactive", setSelectedComplete)
        selectExistingFilters("A.DecisionID", setSelectedDecision)
        selectExistingFilters("A.Exclude", setSelectedExclude)
        selectExistingFilters("B.GovernanceBoardID", setSelectedGovernanceBoard)
        selectExistingFilters("B.LeadDeliveryOrganizationID", setSelectedLdo)
        selectExistingFilters("B.DeliveryPOCID", setSelectedPoc)
        selectExistingFilters(
          "B.ProjectCategoryID",
          setSelectedPlanningCategory
        )
        selectExistingFilters("A.PMUserID", setSelectedPm)
        selectExistingFilters("B.SponsoringUnitID", setSelectedSponsor)
        selectExistingFilters("B.CreatedByID", setSelectedSubmitter)
        selectExistingFilters("B.InvestmentCategoryID", setSelectedInvestment)
        selectExistingFilters("F.YearID", setSelectedYears)
        renderUserChart(chartToEdit, "chartdiv", onChartPointClick)
      }, 1000)
    }
  }, [isOpen])

  const onChartPointClick = async e => {
    if (e.point != undefined && e.point.get("x") != undefined) {
      let dataTable = await api.getUserChartDrilldown(
        chartToEdit.userChartID,
        e.point.get("x")
      )
      setDrilldownDataTable(dataTable)
      if (dataTable != undefined && dataTable != null && dataTable.length > 0) {
        toggleDrilldownModal()
      }
    }
  }

  const selectExistingFilters = (filterName, setSelectedList) => {
    let filters = chartToEdit.chartFilters.filter(
      x => x.filterName == filterName
    )
    setSelectedList(
      filters.length > 0
        ? filters.map(x => x.filterValue.replaceAll("'", ""))
        : ["Any"]
    )
  }

  const loadModalData = async () => {
    api
      .getEnterpriseList(currentUser.enterpriseID, "ChartGroupItem")
      .then(chartGroups => {
        chartGroups = chartGroups.filter(x => !x.inactive)
        setChartGroupItems(chartGroups)
        // if (chartGroups.length > 0 && chartToEdit.groupField == undefined) {
        //   changeChartProp("groupField", chartGroups[0].listItemID)
        // }
      })
    api.getList(currentUser.userID, "ChartValue").then(values => {
      setChartValues(values.filter(x => !x.inactive))
    })
    api.getList(currentUser.userID, "ChartScope").then(values => {
      setChartScopes(values.filter(x => !x.inactive))
    })
    api.getRoadmaps(currentUser.userID).then(data => {
      setRoadmaps(data)
    })
    api.getAlternativeLists(currentUser.userID, 0).then(data => {
      setAlternativeLists(data)
    })
    api.getList(currentUser.userID, "Exclude").then(values => {
      setExcludeList(values.filter(x => !x.inactive))
    })
    api.getList(currentUser.userID, "ProjectStatus").then(values => {
      setProjectStatuses(values.filter(x => !x.inactive))
    })
    api.getList(currentUser.userID, "ChartType").then(types => {
      setChartTypes(types.filter(x => !x.inactive))
    })
    api.getScenariosByUser(currentUser.userID, 0).then(sc => {
      setScenarios(
        sortData(
          sc.filter(x => !x.inactive),
          "scenarioName"
        )
      )
    })
    api.getList(currentUser.userID, "BCStatus").then(bc => {
      setBcStatuses(bc.filter(x => !x.inactive))
    })
    api.getList(currentUser.userID, "Priorities").then(pr => {
      setPriorities(pr.filter(x => !x.inactive))
    })
    api.getList(currentUser.userID, "Complete").then(comp => {
      setComplete(comp.filter(x => !x.inactive))
    })
    api.getList(currentUser.userID, "Decisions").then(dec => {
      setDecisions(dec.filter(x => !x.inactive))
    })
    api.getList(currentUser.userID, "CPICPhase").then(cpp => {
      setCPICPhases(cpp.filter(x => !x.inactive))
    })
    loadScenarioData(chartToEdit.scenarioID)
  }

  const loadScenarioData = scenarioId => {
    // if (scenarioId > 0) {
    api.getScenarioList(scenarioId, "LDOList").then(ld => {
      setLdoList(ld.filter(x => !x.inactive))
    })
    api.getScenarioList(scenarioId, "DeliveryPOCList").then(poc => {
      setPocList(poc.filter(x => !x.inactive))
    })
    api.getScenarioList(scenarioId, "BRMList").then(brm => {
      setBrmList(brm.filter(x => !x.inactive))
    })
    api.getScenarioList(scenarioId, "PMList").then(pm => {
      setPmList(pm.filter(x => !x.inactive))
    })
    api.getScenarioList(scenarioId, "SponsorList").then(sp => {
      setSponsorList(sp.filter(x => !x.inactive))
    })
    api.getScenarioList(scenarioId, "SubmitterList").then(sub => {
      setSubmitterList(sub.filter(x => !x.inactive))
    })
    api.getScenarioList(scenarioId, "GovernanceBoard").then(gov => {
      setGovernanceBoards(gov.filter(x => !x.inactive))
    })
    api.getScenarioList(scenarioId, "WorkflowStatus").then(d => {
      setWorkflows(d.filter(x => !x.inactive))
    })
    api.getScenarioList(scenarioId, "AdvancedPlanning").then(d => {
      setAdvancedPlannings(d.filter(x => !x.inactive))
    })
    api.getScenarioList(scenarioId, "InvestmentCategory").then(d => {
      setInvestmentCategories(d.filter(x => !x.inactive))
    })
    api.getScenarioList(scenarioId, "PlanningCategory").then(d => {
      setPlanningCategories(d.filter(x => !x.inactive))
    })
    api.getYears(currentUser.userID).then(y => {
      setYears(
        y.map(x => {
          x.listItemID = x.listItemName
          return x
        })
      )
    })
    // } else {
    //   setPlanningCategories([])
    //   setInvestmentCategories([])
    //   setWorkflows([])
    //   setAdvancedPlannings([])
    //   setGovernanceBoards([])
    //   setSubmitterList([])
    //   setSponsorList([])
    //   setPmList([])
    //   setBrmList([])
    //   setPocList([])
    //   setLdoList([])
    // }
  }

  const changeChartProp = (prop, value) => {
    setChartToEdit({
      ...chartToEdit,
      [prop]: value,
    })
  }

  const handleMultiChange = e => {
    const value = e.target.value
    const name = e.target.name

    callSetter(name, value)
  }

  const getValues = (values, selectedValues) => {
    return values.indexOf("Any") > -1 && selectedValues.indexOf("Any") == -1
      ? ["Any"]
      : values.indexOf("Any") > -1 && values.length > 1
      ? values.filter(x => x !== "Any")
      : values
  }

  const callSetter = (name, values) => {
    switch (name) {
      case "years":
        setSelectedYears(getValues(values, selectedYears))
        break
      case "bcstatus":
        setSelectedBcStatus(getValues(values, selectedBcStatus))
        break
      case "priority":
        setSelectedPriorities(getValues(values, selectedPriorities))
        break
      case "brm":
        setSelectedBrm(getValues(values, selectedBrm))
        break
      case "comp":
        setSelectedComplete(getValues(values, selectedComplete))
        break
      case "decision":
        setSelectedDecision(getValues(values, selectedDecision))
        break
      case "gov":
        setSelectedGovernanceBoard(getValues(values, selectedGovernanceBoard))
        break
      case "invcat":
        setSelectedInvestment(getValues(values, selectedInvestment))
        break
      case "ldo":
        setSelectedLdo(getValues(values, selectedLdo))
        break
      case "ldopoc":
        setSelectedPoc(getValues(values, selectedPoc))
        break
      case "planningcat":
        setSelectedPlanningCategory(getValues(values, selectedPlanningCategory))
        break
      case "pm":
        setSelectedPm(getValues(values, selectedPm))
        break
      case "sponsor":
        setSelectedSponsor(getValues(values, selectedSponsor))
        break
      case "submitter":
        setSelectedSubmitter(getValues(values, selectedSubmitter))
        break
      case "workflow":
        setSelectedWorkflow(getValues(values, selectedWorkflow))
        break
      case "advancedplanning":
        setSelectedAdvancedPlanning(getValues(values, selectedAdvancedPlanning))
        break
      case "cpicphase":
        setSelectedCPICPhase(getValues(values, selectedCPICPhase))
        break
      case "status":
        setSelectedProjectStatus(getValues(values, selectedProjectStatus))
        break
      case "excluded":
        setSelectedExclude(getValues(values, selectedExclude))
        break
    }
  }

  const renderSelectedName = (selected, list) => {
    try {
      return selected
        .map(id =>
          id == "Any"
            ? "Any"
            : list.find(l => l.listItemID === id)?.listItemName
        )
        .filter(x => x != undefined)
        .join(", ")
    } catch (err) {
      console.log(err)
    }
  }

  // const save = async () => {
  //   let chartFilters = getFilters()

  //   if (chartToEdit.userChartID > -1) {
  //     await api.updateUserChart(currentUser.userID, {
  //       ...chartToEdit,
  //       chartFilters: chartFilters,
  //     })
  //   } else {
  //     await api.createUserChart(currentUser.userID, {
  //       ...chartToEdit,
  //       chartFilters: chartFilters,
  //     })
  //   }
  //   reloadData && reloadData()
  //   toggleModal()
  // }

  const saveAndReload = async () => {
    setErrorFields([])
    let errors = []
    if (
      (chartGroupItems.length > 0 && chartToEdit.groupField == undefined) ||
      chartToEdit.groupField == "-1" ||
      chartToEdit.groupField == null
    ) {
      errors.push("groupField")
    }
    if (
      chartToEdit.valueItem == undefined ||
      chartToEdit.valueItem == null ||
      chartToEdit.valueItem == "-1"
    ) {
      errors.push("valueItem")
    }
    if (
      chartToEdit.chartType == undefined ||
      chartToEdit.chartType == null ||
      chartToEdit.chartType == "-1"
    ) {
      errors.push("chartType")
    }
    if (
      chartToEdit.chartName == undefined ||
      chartToEdit.chartName == null ||
      chartToEdit.chartName == ""
    ) {
      errors.push("chartName")
    }
    if (errors.length > 0) {
      setErrorFields(errors)
      return
    }

    let chartFilters = getFilters()
    let userChartId = chartToEdit.userChartID
    if (chartToEdit.userChartID > -1) {
      await api.updateUserChart(currentUser.userID, {
        ...chartToEdit,
        chartFilters: chartFilters,
      })
    } else {
      userChartId = await api.createUserChart(currentUser.userID, {
        ...chartToEdit,
        chartFilters: chartFilters,
      })
    }
    toast.success("Chart saved successfully.")
    let c = await api.getUserChart(userChartId)
    setChartToEdit(c)
    renderUserChart(c, "chartdiv", onChartPointClick)
    reloadData && reloadData(c)
    // renderUserChart(chartToEdit, "chartdiv")
  }

  const copyChart = async () => {
    await api.copyUserChart(currentUser.userID, chartToEdit.userChartID)
    reloadData && reloadData()
    toggleModal()
  }

  const deleteChart = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this chart?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteUserChart(currentUser.userID, chartToEdit.userChartID)
          reloadData && reloadData()
          toggleModal()
        }
      })
  }

  const getFilters = () => {
    let chartFilters = []
    selectedBcStatus
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "A.BCState",
        })
      })
    selectedPriorities
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "B.BusinessPriority",
        })
      })
    selectedBrm
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "A.BRMUserID",
        })
      })
    selectedComplete
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "A.Inactive",
        })
      })
    selectedDecision
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "A.DecisionID",
        })
      })
    selectedGovernanceBoard
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "B.GovernanceBoardID",
        })
      })
    selectedExclude
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "A.Exclude",
        })
      })
    selectedInvestment
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "B.InvestmentCategoryID",
        })
      })
    selectedYears
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "F.YearID",
        })
      })
    selectedLdo
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "B.LeadDeliveryOrganizationID",
        })
      })
    selectedPoc
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "B.DeliveryPOCID",
        })
      })
    selectedPlanningCategory
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "B.ProjectCategoryID",
        })
      })
    selectedPm
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "A.PMUserID",
        })
      })
    selectedSponsor
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "B.SponsoringUnitID",
        })
      })
    selectedSubmitter
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "B.CreatedByID",
        })
      })
    selectedWorkflow
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "A.WorkflowStatusID",
        })
      })
    selectedAdvancedPlanning
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "A.AdvancedPlanningID",
        })
      })
    selectedCPICPhase
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "A.CPICPhaseID",
        })
      })
    selectedProjectStatus
      .filter(x => x != "Any")
      .forEach(f => {
        chartFilters.push({
          filterValue: f,
          filterName: "A.ProjectState",
        })
      })
    console.log(chartFilters)
    return chartFilters
  }

  const resetScenarioFilters = () => {
    setSelectedAdvancedPlanning(["Any"])
    setSelectedBrm(["Any"])
    setSelectedCPICPhase(["Any"])
    setSelectedPlanningCategory(["Any"])
    setSelectedLdo(["Any"])
    setSelectedPoc(["Any"])
    setSelectedPlanningCategory(["Any"])
    setSelectedPm(["Any"])
    setSelectedSponsor(["Any"])
    setSelectedGovernanceBoard(["Any"])
    setSelectedWorkflow(["Any"])
  }

  return (
    <>
      <Modal
        backdrop="static"
        isOpen={isOpen}
        // fullscreen={true}
        size="xl"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Chart
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {chartToEdit != null && (
          <div className="modal-body">
            <Row>
              <Col md="7">
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label className="form-label">Chart Name</Label>
                      <Input
                        name="chartName"
                        type="text"
                        onChange={e =>
                          changeChartProp("chartName", e.target.value)
                        }
                        value={chartToEdit.chartName}
                      />
                      {errorFields.find(x => x == "chartName") && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          Required
                        </div>
                      )}
                    </div>
                    <div
                      className="mb-3"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Input
                        type="checkbox"
                        checked={chartToEdit.publicChart}
                        onClick={() => {
                          changeChartProp(
                            "publicChart",
                            !chartToEdit.publicChart
                          )
                        }}
                      />
                      <Label style={{ marginLeft: "10px" }}>Public</Label>
                      <Input
                        type="checkbox"
                        checked={chartToEdit.inactive}
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          changeChartProp("inactive", !chartToEdit.inactive)
                        }}
                      />
                      <Label style={{ marginLeft: "10px" }}>Inactive</Label>
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Description</Label>
                      <TextareaAutosize
                        minRows={2}
                        className="form-control"
                        onChange={e =>
                          changeChartProp("description", e.target.value)
                        }
                        value={chartToEdit.description}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label className="form-label">Group By</Label>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={chartToEdit.groupField}
                        onChange={e => {
                          changeChartProp("groupField", e.target.value)
                        }}
                      >
                        <option value="-1">Select...</option>
                        {chartGroupItems.map((x, idx) => (
                          <option key={idx} value={x.listItemID}>
                            {x.listItemName}
                          </option>
                        ))}
                      </select>
                      {errorFields.find(x => x == "groupField") && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          Required
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Chart Type</Label>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={chartToEdit.chartType}
                        onChange={e => {
                          changeChartProp("chartType", e.target.value)
                        }}
                      >
                        <option value="-1">Select...</option>
                        {chartTypes.map((x, idx) => (
                          <option key={idx} value={x.listItemID}>
                            {x.listItemName}
                          </option>
                        ))}
                      </select>
                      {errorFields.find(x => x == "chartType") && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          Required
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <Label className="form-label">Value</Label>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={chartToEdit.valueItem}
                        onChange={e => {
                          changeChartProp("valueItem", e.target.value)
                        }}
                      >
                        <option value="-1">Select...</option>
                        {chartValues.map((x, idx) => (
                          <option key={idx} value={x.listItemName}>
                            {x.listItemName}
                          </option>
                        ))}
                      </select>
                      {errorFields.find(x => x == "valueItem") && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          Required
                        </div>
                      )}
                    </div>
                    <div>
                      <CustomColorPicker
                        label="Base Color"
                        color={chartToEdit.baseColor}
                        handleColorChange={colorObj => {
                          changeChartProp("baseColor", colorObj.hex)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="divider"></div>
                    <p className="smallHelp">
                      <ul>
                        <li>
                          If you want to create a chart based on more than one
                          portfolio, but not all of them, ask the decision
                          manager to create a portfolio group.
                        </li>
                        <li>
                          If you want to create a chart based on specific
                          alternatives within a portfolio or portfolio group,
                          create a list and set the filters to use the list.
                        </li>
                      </ul>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Filter the chart data by...</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label className="form-label">Scope</Label>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={chartToEdit.generalChartScope}
                        onChange={e => {
                          if (
                            (chartToEdit.generalChartScope == "Decision" ||
                              chartToEdit.generalChartScope == "Scenario") &&
                            e.target.value != "Scenario" &&
                            e.target.value != "Decision"
                          ) {
                            resetScenarioFilters()
                          }

                          if (e.target.value == "Scenario") {
                            setChartToEdit({
                              ...chartToEdit,
                              scenarioID: 0,
                              generalChartScope: e.target.value,
                            })
                            loadScenarioData(0)
                          } else if (e.target.value == "Roadmap") {
                            setChartToEdit({
                              ...chartToEdit,
                              scenarioID:
                                roadmaps.length > 0 ? roadmaps[0].roadmapID : 0,
                              generalChartScope: e.target.value,
                            })
                            loadScenarioData(0)
                          } else if (e.target.value == "List") {
                            setChartToEdit({
                              ...chartToEdit,
                              scenarioID:
                                alternativeLists.length > 0
                                  ? alternativeLists[0].alternativeListID
                                  : 0,
                              generalChartScope: e.target.value,
                            })
                            if (alternativeLists.length > 0) {
                              loadScenarioData(alternativeLists[0].scenarioID)
                            }
                          }
                        }}
                      >
                        {chartScopes.map((x, idx) => (
                          <option key={idx} value={x.listItemID}>
                            {x.listItemName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {chartToEdit.generalChartScope == "Scenario" && (
                      <div className="mb-3">
                        <Label className="form-label">
                          Decision (Portfolio)
                        </Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={chartToEdit.scenarioID}
                          onChange={e => {
                            changeChartProp("scenarioID", e.target.value)
                            loadScenarioData(e.target.value)
                            resetScenarioFilters()
                          }}
                        >
                          <option value="0">Any</option>
                          {scenarios.map((x, idx) => (
                            <option key={idx} value={x.scenarioID}>
                              {x.scenarioName}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {chartToEdit.generalChartScope == "Roadmap" && (
                      <div className="mb-3">
                        <Label className="form-label">Roadmap</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={chartToEdit.scenarioID}
                          onChange={e => {
                            changeChartProp("scenarioID", e.target.value)
                          }}
                        >
                          {roadmaps.map((x, idx) => (
                            <option key={idx} value={x.roadmapID}>
                              {x.roadmapName}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {chartToEdit.generalChartScope == "List" && (
                      <div className="mb-3">
                        <Label className="form-label">List</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={chartToEdit.scenarioID}
                          onChange={e => {
                            changeChartProp("scenarioID", e.target.value)
                            let lst = alternativeLists.find(
                              x => x.alternativeListID == e.target.value
                            )
                            if (lst != undefined) {
                              loadScenarioData(lst.scenarioID)
                            }
                          }}
                        >
                          {alternativeLists.map((x, idx) => (
                            <option key={idx} value={x.alternativeListID}>
                              {x.alternativeListName}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label className="form-label">Version Logic</Label>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={chartToEdit.alternativeLogic}
                        onChange={e => {
                          changeChartProp("alternativeLogic", e.target.value)
                        }}
                      >
                        <option value="B">
                          (B) Baselines not under revision
                        </option>
                        <option value="B*">
                          (B*) Baselines currently under revision
                        </option>
                        <option value="R">(R) Revisions</option>
                        <option value="BR">
                          (B and R) Baselines not under revision AND revisions
                        </option>
                        <option value="BB*">(B and B*) All Baselines</option>
                        <option value="All">All</option>
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Advanced Planning
                      </Label>
                      <Select
                        labelId="mcb-AdvancedPlanning"
                        id="mcb-AdvancedPlanning"
                        name="advancedplanning"
                        disabled={
                          chartToEdit.generalChartScope != "Scenario" ||
                          chartToEdit.scenarioID == 0
                        }
                        multiple
                        value={selectedAdvancedPlanning}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, AdvancedPlannings)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={
                              selectedAdvancedPlanning.indexOf("Any") > -1
                            }
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {AdvancedPlannings.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={
                                selectedAdvancedPlanning.indexOf(s.listItemID) >
                                -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Business Case Status
                      </Label>
                      <Select
                        labelId="mcb-bcstatus"
                        id="mcb-bcstatus"
                        name="bcstatus"
                        multiple
                        value={selectedBcStatus}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected => selected.join(", ")}
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedBcStatus.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {bcStatuses.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemName}>
                            <Checkbox
                              checked={
                                selectedBcStatus.indexOf(s.listItemName) > -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Business Priority
                      </Label>
                      <Select
                        labelId="mcb-priority"
                        id="mcb-priority"
                        name="priority"
                        multiple
                        value={selectedPriorities}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected => selected.join(", ")}
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedPriorities.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {priorities.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemName}>
                            <Checkbox
                              checked={
                                selectedPriorities.indexOf(s.listItemName) > -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Business Relationship Manager
                      </Label>
                      <Select
                        labelId="mcb-brm"
                        disabled={
                          chartToEdit.generalChartScope != "Scenario" ||
                          chartToEdit.scenarioID == 0
                        }
                        id="mcb-brm"
                        name="brm"
                        multiple
                        value={selectedBrm}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, brmList)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedBrm.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {brmList.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={selectedBrm.indexOf(s.listItemID) > -1}
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        CPIC Phase
                      </Label>
                      <Select
                        labelId="mcb-CPICPhase"
                        id="mcb-CPICPhase"
                        name="cpicphase"
                        disabled={
                          chartToEdit.generalChartScope != "Scenario" ||
                          chartToEdit.scenarioID == 0
                        }
                        multiple
                        value={selectedCPICPhase}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, CPICPhases)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedCPICPhase.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {CPICPhases.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={
                                selectedCPICPhase.indexOf(s.listItemID) > -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Completed
                      </Label>
                      <Select
                        labelId="mcb-comp"
                        id="mcb-comp"
                        name="comp"
                        multiple
                        value={selectedComplete}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, complete)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedComplete.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {complete.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={
                                selectedComplete.indexOf(s.listItemID) > -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Decision (Field)
                      </Label>
                      <Select
                        labelId="mcb-decision"
                        id="mcb-decision"
                        name="decision"
                        multiple
                        value={selectedDecision}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, decisions)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedDecision.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {decisions.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={
                                selectedDecision.indexOf(s.listItemID) > -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Excluded from Decision
                      </Label>
                      <Select
                        labelId="mcb-excluded"
                        id="mcb-excluded"
                        name="excluded"
                        multiple
                        value={selectedExclude}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, excludeList)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedExclude.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {excludeList.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={
                                selectedExclude.indexOf(s.listItemID) > -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Governance Board
                      </Label>
                      <Select
                        labelId="mcb-gov"
                        id="mcb-gov"
                        name="gov"
                        disabled={
                          chartToEdit.generalChartScope != "Scenario" ||
                          chartToEdit.scenarioID == 0
                        }
                        multiple
                        value={selectedGovernanceBoard}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, governanceBoards)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={
                              selectedGovernanceBoard.indexOf("Any") > -1
                            }
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {governanceBoards.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={
                                selectedGovernanceBoard.indexOf(s.listItemID) >
                                -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Years
                      </Label>
                      <Select
                        labelId="mcb-years"
                        id="mcb-years"
                        name="years"
                        // disabled={
                        //   chartToEdit.generalChartScope != "Scenario" ||
                        //   chartToEdit.scenarioID == 0
                        // }
                        multiple
                        value={selectedYears}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, years)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedYears.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {years.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={selectedYears.indexOf(s.listItemID) > -1}
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Investment Category
                      </Label>
                      <Select
                        labelId="mcb-invcat"
                        id="mcb-invcat"
                        name="invcat"
                        multiple
                        value={selectedInvestment}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, investmentCategories)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedInvestment.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {investmentCategories.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={
                                selectedInvestment.indexOf(s.listItemID) > -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Lead Delivery Organization
                      </Label>
                      <Select
                        labelId="mcb-ldo"
                        id="mcb-ldo"
                        name="ldo"
                        multiple
                        value={selectedLdo}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, ldoList)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedLdo.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {ldoList.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={selectedLdo.indexOf(s.listItemID) > -1}
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Lead Delivery Organization POC
                      </Label>
                      <Select
                        labelId="mcb-ldopoc"
                        id="mcb-ldopoc"
                        name="ldopoc"
                        disabled={
                          chartToEdit.generalChartScope != "Scenario" ||
                          chartToEdit.scenarioID == 0
                        }
                        multiple
                        value={selectedPoc}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, pocList)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedPoc.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {pocList.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={selectedPoc.indexOf(s.listItemID) > -1}
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Planning Category
                      </Label>
                      <Select
                        labelId="mcb-planningcat"
                        id="mcb-planningcat"
                        name="planningcat"
                        disabled={
                          chartToEdit.generalChartScope != "Scenario" ||
                          chartToEdit.scenarioID == 0
                        }
                        multiple
                        value={selectedPlanningCategory}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, planningCategories)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={
                              selectedPlanningCategory.indexOf("Any") > -1
                            }
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {planningCategories.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={
                                selectedPlanningCategory.indexOf(s.listItemID) >
                                -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Project Manager
                      </Label>
                      <Select
                        labelId="mcb-pm"
                        id="mcb-pm"
                        name="pm"
                        disabled={
                          chartToEdit.generalChartScope != "Scenario" ||
                          chartToEdit.scenarioID == 0
                        }
                        multiple
                        value={selectedPm}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, pmList)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedPm.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {pmList.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={selectedPm.indexOf(s.listItemID) > -1}
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Project Status
                      </Label>
                      <Select
                        labelId="mcb-status"
                        id="mcb-status"
                        name="status"
                        multiple
                        value={selectedProjectStatus}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected => selected.join(", ")}
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedProjectStatus.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {projectStatuses.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemName}>
                            <Checkbox
                              checked={
                                selectedProjectStatus.indexOf(s.listItemName) >
                                -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Sponsoring Business Unit
                      </Label>
                      <Select
                        labelId="mcb-sponsor"
                        id="mcb-sponsor"
                        name="sponsor"
                        disabled={
                          chartToEdit.generalChartScope != "Scenario" ||
                          chartToEdit.scenarioID == 0
                        }
                        multiple
                        value={selectedSponsor}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, sponsorList)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedSponsor.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {sponsorList.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={
                                selectedSponsor.indexOf(s.listItemID) > -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Submitter
                      </Label>
                      <Select
                        labelId="mcb-submitter"
                        id="mcb-submitter"
                        name="submitter"
                        multiple
                        value={selectedSubmitter}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, submitterList)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedSubmitter.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {submitterList.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={
                                selectedSubmitter.indexOf(s.listItemID) > -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        style={{ marginBottom: "10px" }}
                      >
                        Workflow Status
                      </Label>
                      <Select
                        labelId="mcb-workflow"
                        id="mcb-workflow"
                        name="workflow"
                        disabled={
                          chartToEdit.generalChartScope != "Scenario" ||
                          chartToEdit.scenarioID == 0
                        }
                        multiple
                        value={selectedWorkflow}
                        onChange={handleMultiChange}
                        input={<OutlinedInput />}
                        renderValue={selected =>
                          renderSelectedName(selected, workflows)
                        }
                      >
                        <MenuItem value={"Any"}>
                          <Checkbox
                            checked={selectedWorkflow.indexOf("Any") > -1}
                            color="primary"
                          />
                          <ListItemText primary={"Any"} />
                        </MenuItem>
                        {workflows.map(s => (
                          <MenuItem key={s.listItemName} value={s.listItemID}>
                            <Checkbox
                              checked={
                                selectedWorkflow.indexOf(s.listItemID) > -1
                              }
                              color="primary"
                            />
                            <ListItemText primary={s.listItemName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md="5">
                {chartToEdit.chartType == "Table" && (
                  <table
                    className="table table-bordered low-padding-table"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "80%" }}>
                          {chartToEdit.chartName}
                        </th>
                        <th style={{ width: "20%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {chartToEdit.chartTable.map((c, idx) => (
                        <tr key={idx}>
                          <td>{c.itemName}</td>
                          <td>
                            {!isNullOrEmpty(c.itemValue) &&
                            !isNaN(c.itemValue) ? (
                              currencyFormatter.format(c.itemValue)
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: c.itemValue,
                                }}
                              ></div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <div
                  id="chartdiv"
                  style={{ width: "100%", height: "600px" }}
                ></div>
              </Col>
            </Row>
          </div>
        )}
        {chartToEdit != null && (
          <div
            className="modal-footer"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {chartToEdit.userChartID > -1 && (
                <button className="btn btn-primary" onClick={copyChart}>
                  Copy Chart
                </button>
              )}
              {chartToEdit.userChartID > -1 && (
                <button
                  onClick={() => {
                    deleteChart()
                  }}
                  className="btn btn-danger save-user"
                  data-dismiss="modal"
                >
                  Delete Chart
                </button>
              )}
            </div>
            <div>
              <button
                className="btn btn-primary save-user"
                onClick={saveAndReload}
              >
                Save and Refresh Chart
              </button>
              &nbsp;
              <button
                onClick={() => {
                  toggleModal()
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </Modal>
      <ChartDrilldownModal
        isOpen={showDrilldownModal}
        toggleModal={toggleDrilldownModal}
        dataTable={drilldownDataTable}
      />
    </>
  )
}

export default EditUserChartModal
