import React, { useEffect, useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import Loader from "react-loaders"
import { ReactTabulator } from "react-tabulator"
import colors from "utils/colors"
import * as ReactDOMClient from "react-dom/client"
import api from "services/api.service"
import { userService } from "services/user.service"
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap"
import { toast } from "react-toastify"
import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import NameAvatar from "components/custom/nameAvatar"
import { getSelectHtmlFilterValues } from "utils/tabulatorFilter"

const ModelsGrid = ({ models, props, reloadData, scenarioTypes }) => {
  var tabulatorRef = useRef(null)
  const currentUser = userService.getLoggedInUser()
  let [showFilters, setShowFilters] = useState(false)
  const [showActionsDropdown, setShowActionsDropdown] = useState(false)

  const handleModelPress = (e, model) => {
    e && e.preventDefault()
    if (currentUser.roleID < 10) {
      props.history.push(`/projects/${model.scenarioID}`)
    } else {
      props.history.push(`/editModel/${model.scenarioID}`)
    }
  }

  const handleAltPress = (e, model) => {
    e && e.preventDefault()
    props.history.push(`/projects/${model.scenarioID}`)
  }

  const handleParticipantsPress = (e, model) => {
    e && e.preventDefault()
    props.history.push(`/participants/${model.scenarioID}`)
  }

  const toggleFilters = () => {
    tabulatorRef.current.element.classList.remove("show-filters")
    tabulatorRef.current.element.classList.remove("hide-filters")
    tabulatorRef.current.element.classList.add(
      !showFilters ? "show-filters" : "hide-filters"
    )
    setShowFilters(!showFilters)
  }

  const columns = [
    {
      // title: "Select",
      formatter: "rowSelection",
      titleFormatter: "rowSelection",
      // titleFormatter: function (cell, formatter) {
      //   cell.getElement().classList.add("text-center")
      //   return cell.getValue()
      // },
      hozAlign: "center",
      headerSort: false,
      cellClick: function (e, cell) {
        cell.getRow().toggleSelect()
      },
      width: 50,
    },
    {
      title: "Decision Type",
      field: "scenarioType",
      width: 150,
      formatter: (cell, formatterParams, onRendered) => {
        return cell.getValue()
      },
    },
    {
      title: "Decision Manager",
      field: "portfolioManager",
      //headerFilter: "input",
      headerFilter: "select",
      headerFilterFunc: "in",
      headerFilterParams: {
        // values: [...new Set(users.map(u => u.role))],
        values: getSelectHtmlFilterValues(
          new Set(models.map(m => m.portfolioManager))
        ),
        sortValuesList: "asc",
        multiselect: true,
      },
      width: 200,
      widthGrow: 2,
      formatter: (cell, formatterParams, onRendered) => {
        const renderFn = () => {
          const val = cell.getValue()
          const cellEl = cell.getElement()
          const base64Image = cell.getRow().getData().portfolioManagerPhotoBase64
          if (cellEl) {
            const formatterCell = cellEl.querySelector(".formatterCell")
            if (formatterCell) {
              const CompWithMoreProps = React.cloneElement(
                <NameAvatar fullName={val} showName={true} base64Image={base64Image} />,
                { cell }
              )
              createRoot(cellEl.querySelector(".formatterCell")).render(
                CompWithMoreProps
              )
            }
          }
        }

        onRendered(renderFn)

        setTimeout(() => {
          renderFn()
        }, 0)
        return '<div class="formatterCell"></div>'
      },
    },
    {
      title: "Decision",
      field: "scenarioName",
      headerFilter: "input",
      minWidth: 200,
      widthGrow: 3,
      formatter: (cell, formatterParams, onRendered) => {
        const renderFn = () => {
          const val = cell.getValue()
          const cellEl = cell.getElement()
          if (cellEl) {
            const formatterCell = cellEl.querySelector(".formatterCell")
            if (formatterCell) {
              const CompWithMoreProps = React.cloneElement(
                <a
                  href="#"
                  onClick={e => handleModelPress(e, cell.getRow().getData())}
                >
                  {val}
                </a>,
                { cell }
              )
              let root = ReactDOMClient.createRoot(
                cellEl.querySelector(".formatterCell")
              )
              root.render(CompWithMoreProps)
            }
          }
        }

        onRendered(renderFn) // initial render only.

        setTimeout(() => {
          renderFn() // render every time cell value changed.
        }, 0)
        return '<div class="formatterCell"></div>'
      },
    },

    {
      title: `View`,
      field: "scenarioID",
      widthGrow: 3,
      minWidth: 150,
      headerSort: false,
      formatter: (cell, formatterParams, onRendered) => {
        const renderFn = () => {
          const data = cell.getData()
          const cellEl = cell.getElement()
          if (cellEl) {
            const formatterCell = cellEl.querySelector(".formatterCell")
            if (formatterCell) {
              const CompWithMoreProps = React.cloneElement(
                <>
                  <a
                    href="#"
                    onClick={e => handleAltPress(e, cell.getRow().getData())}
                  >
                    {data.alternativeName}s;
                  </a>
                  <a
                    href="#"
                    onClick={e =>
                      handleParticipantsPress(e, cell.getRow().getData())
                    }
                    style={{ marginLeft: "10px" }}
                  >
                    Team Members
                  </a>
                </>,
                { cell }
              )
              let root = ReactDOMClient.createRoot(
                cellEl.querySelector(".formatterCell")
              )
              root.render(CompWithMoreProps)
            }
          }
        }

        onRendered(renderFn) // initial render only.

        setTimeout(() => {
          renderFn() // render every time cell value changed.
        }, 0)
        return '<div class="formatterCell"></div>'
      },
    },
    {
      title: "Locked",
      field: "lockBit",
      hozAlign: "center",
      minWidth: 100,
      headerHozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() == null
          ? ""
          : cell.getValue() == 1
          ? '<i class="fas fa-lock" style="color: red;"></i>'
          : ""
      },
      widthGrow: 1,
    },
    {
      title: "Backup",
      field: "backupBit",
      hozAlign: "center",
      minWidth: 100,
      headerHozAlign: "center",
      headerFilter: "select",
      headerFilterParams: {
        values: {
          true: "Yes",
          false: "No",
        },
      },
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() == 0
          ? ""
          : "<span style='color:red'><b>Backup</b></span>"
      },
      // headerFilter: "select",
      // headerFilterParams: {
      //   values: {
      //     false: "Yes",
      //     true: "No",
      //   },
      // },
      widthGrow: 1,
    },
    {
      title: "Active",
      field: "inactive",
      hozAlign: "center",
      minWidth: 100,
      headerHozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() == 0
          ? "<span style='color:green'>Yes</span>"
          : "<span style='color:red'><b>No</b></span>"
      },
      // headerFilter: "select",
      // headerFilterParams: {
      //   values: {
      //     false: "Yes",
      //     true: "No",
      //   },
      // },
      widthGrow: 1,
    },
    {
      title: "Last modified",
      field: "lastUpdateDT",
      widthGrow: 2,
      minWidth: 100,
      formatter: function (cell, fp, or) {
        let date = new Date(cell.getValue())
        return (
          date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
        )
      },
    },
  ]

  const deleteSelected = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete the selected projects?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          let selected = tabulatorRef.current.getSelectedData()
          if (selected.length > 0) {
            let copy = [...selected]
            let tasks = copy.map(x => async () => {
              return await api.deleteScenario(currentUser.userID, x.scenarioID)
            })
            await Promise.all(tasks.map(t => t()))
            reloadData()
            toast.success("Projects deleted successfuly")
          }
        }
      })
  }

  const updateSelected = async (prop, value) => {
    let selected = tabulatorRef.current.getSelectedData()
    if (selected.length > 0) {
      let copy = selected.map(x => {
        x[prop] = value
        return x
      })
      let tasks = copy.map(x => async () => {
        return await api.updateScenario(currentUser.userID, x)
      })
      await Promise.all(tasks.map(t => t()))
      reloadData()
      toast.success("Projects saved successfuly")
    }
  }

  return (
    <Card>
      <CardBody>
        {models.length > 0 && (
          <>
            <Row>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {currentUser.roleID >= 10 && (
                  <div>
                    <Dropdown
                      isOpen={showActionsDropdown}
                      toggle={() => {
                        if (!showActionsDropdown) {
                          let selected = tabulatorRef.current.getSelectedData()
                          if (selected.length > 0) {
                            setShowActionsDropdown(!showActionsDropdown)
                          } else {
                            swalWithConfirmButton.fire({
                              title: `You must select at least one model first`,
                              icon: "warning",
                              showCancelButton: false,
                              confirmButtonText: "Ok",
                            })
                          }
                        } else {
                          setShowActionsDropdown(!showActionsDropdown)
                        }
                      }}
                    >
                      <DropdownToggle
                        tag="button"
                        className="btn btn-sm btn-outline-primary"
                      >
                        Batch Actions <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => updateSelected("inactive", false)}
                        >
                          <i className="mdi mdi-arrow-right-drop-circle" />{" "}
                          Activate
                        </DropdownItem>
                        
                        <DropdownItem onClick={() => deleteSelected()}>
                          <i className="mdi mdi-trash-can-outline" /> Delete
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => updateSelected("backupBit", true)}
                        >
                          <i className="mdi mdi-database-arrow-down-outline" />{" "}
                          Declare as Backup
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => updateSelected("inactive", true)}
                        >
                          <i className="mdi mdi-arrow-down-drop-circle-outline" />{" "}
                          De-activate
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => updateSelected("lockBit", true)}
                        >
                          <i className="mdi mdi-lock" /> Lock
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => updateSelected("backupBit", false)}
                        >
                          <i className="mdi mdi-database-export" /> Undeclare as
                          Backup
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => updateSelected("lockBit", false)}
                        >
                          <i className="mdi mdi-lock-open-outline" /> Unlock
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    onClick={toggleFilters}
                    className="btn btn-sm btn-outline-primary"
                  >
                    {showFilters ? "Hide" : "Show"}{" "}
                    <i className="fas fa-search"></i>{" "}
                    <i className="fas fa-filter"></i>
                  </button>
                </div>
              </Col>
            </Row>
            <div className={showFilters ? "show-filters" : "hide-filters"}>
              <ReactTabulator
                onRef={r => (tabulatorRef.current = r.current)}
                columns={columns}
                data={models}
                options={{
                  pagination: "local",
                  paginationSize: 30,
                  paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
                  layout: "fitColumns",
                  cssClass: "table-borderless",
                }}
              />
            </div>
          </>
        )}
      </CardBody>
      
    </Card>
  )
}

export default ModelsGrid
