import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import React, { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { Col, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import moment from "moment"
import CurrencyInput from "react-currency-input-field"

const BusinessCaseSection = ({
  activeTab,
  scenario,
  setScenario,
  saveScenario,
  triggerSave,
  setTriggerSave,
}) => {
  const currentUser = userService.getLoggedInUser()

  let [businessNeeds, setBusinessNeeds] = useState([])
  let [resourcePlans, setResourcePlans] = useState([])
  let [resourceBudgets, setResourceBudgets] = useState([])
  let [contracts, setContracts] = useState([])
  let [governances, setGovernances] = useState([])
  let [projectPlans, setProjectPlans] = useState([])
  let [results, setResults] = useState([])
  let [scoringResults, setScoringResults] = useState([])
  let [navigations, setNavigations] = useState([])
  let [solutionApproaches, setSolutionApproaches] = useState([])
  let [forecasts, setForecasts] = useState([])
  let [locations, setLocations] = useState([])
  let [documents, setDocuments] = useState([])
  let [lookbacks, setLookbacks] = useState([])
  let [costPoolTemplates, setCostPoolTemplates] = useState([])

  const [scenarioTabSections, setScenarioTabSections] = useState([])
  const [tabSectionForms, setTabSectionForms] = useState([])

  const [tabForms, setTabForms] = useState([])
  const [fiscalYears, setFiscalYears] = useState([])
  const months = [
    {
      text: "January",
      value: 1,
    },
    {
      text: "February",
      value: 2,
    },
    {
      text: "March",
      value: 3,
    },
    {
      text: "April",
      value: 4,
    },
    {
      text: "May",
      value: 5,
    },
    {
      text: "June",
      value: 6,
    },
    {
      text: "July",
      value: 7,
    },
    {
      text: "August",
      value: 8,
    },
    {
      text: "September",
      value: 9,
    },
    {
      text: "October",
      value: 10,
    },
    {
      text: "November",
      value: 11,
    },
    {
      text: "December",
      value: 12,
    },
  ]

  useEffect(() => {
    if (activeTab == "2") {
      constructFiscalYears()
      loadData()
    }
  }, [activeTab])

  useEffect(() => {
    if (triggerSave) {
      saveScenarioTabSections()
    }
  }, [triggerSave])

  const saveScenarioTabSections = async () => {
    let newOrUpdatedTasks = scenarioTabSections
      .filter(x => x.isDirty && x.formID != -1)
      .map(x => async () => {
        return await api.createOrUpdateScenarioTabSection(
          scenario.scenarioID,
          x.formID,
          x.tabName
        )
      })
    let deletedTasks = scenarioTabSections
      .filter(x => x.scenarioTabSectionID > -1 && x.isDirty && x.formID == -1)
      .map(x => async () => {
        return await api.deleteScenarioTabSection(x.scenarioTabSectionID)
      })
    let tasks = [...newOrUpdatedTasks, ...deletedTasks].filter(
      x => x != undefined && x != null
    )
    if (tasks.length > 0) {
      await Promise.all(tasks.map(t => t()))
    }
    setTriggerSave(false)
  }

  const constructFiscalYears = () => {
    let years = []
    let dt = new Date()
    let dtYearStart = dt.getFullYear() - 10
    for (let i = 0; i <= 25; i++) {
      years.push(dtYearStart + i)
    }
    setFiscalYears(years)
  }

  const loadData = async () => {
    let f = await api.getForms(scenario.enterpriseID)
    setTabForms(f.filter(x => x.formTypeId == 10))
    setTabSectionForms(f.filter(x => x.formTypeId == 20))

    api.getScenarioTabSections(scenario.scenarioID).then(sts => {
      setScenarioTabSections(sts)
    })
    api.getBcTabOptions(scenario.scenarioID, "Business Need").then(b => {
      setBusinessNeeds(b)
    })
    api.getBcTabOptions(scenario.scenarioID, "Solution Approach").then(sol => {
      setSolutionApproaches(sol)
    })
    api.getBcTabOptions(scenario.scenarioID, "Resource Planning").then(rsp => {
      setResourcePlans(rsp)
    })
    api.getBcTabOptions(scenario.scenarioID, "Forecast").then(fo => {
      setForecasts(fo)
    })

    api.getBcTabOptions(scenario.scenarioID, "Resource Budget").then(rb => {
      setResourceBudgets(rb)
    })
    api.getBcTabOptions(scenario.scenarioID, "Location").then(l => {
      setLocations(l)
    })
    api.getBcTabOptions(scenario.scenarioID, "Contracts").then(c => {
      setContracts(c)
    })
    api.getBcTabOptions(scenario.scenarioID, "Documents").then(doc => {
      setDocuments(doc)
    })
    api.getBcTabOptions(scenario.scenarioID, "Governance").then(gov => {
      setGovernances(gov)
    })
    api.getBcTabOptions(scenario.scenarioID, "ProjectPlan").then(gov => {
      setProjectPlans(gov)
    })
    api.getBcTabOptions(scenario.scenarioID, "Lookbacks").then(lbs => {
      setLookbacks(lbs)
    })
    api.getBcTabOptions(scenario.scenarioID, "Results").then(res => {
      setResults(res)
    })
    api.getBcTabOptions(scenario.scenarioID, "Results").then(sres => {
      setScoringResults(sres)
    })
    api.getList(currentUser.userID, "CostPoolTemplate").then(cpt => {
      setCostPoolTemplates(cpt)
    })
    api.getList(currentUser.userID, "NavOptions").then(nav => {
      setNavigations(nav)
    })
  }

  const changeScenarioProp = (scenarioProp, value) => {
    setScenario({
      ...scenario,
      [scenarioProp]: value,
    })

    toast.success(
      "You have unsaved changes, don't forget to save them using the button at the top of the page.",
      { toastId: "unsavedChanges", autoClose: false }
    )
  }

  const applyTemplateToExistingBusinessCases = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Caution: New Business Cases will automatically use the forcast template selected.  Performing this function will remove any forecast data from existing Business Cases and will apply the selected template to them.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await saveScenario()
          await api.scenarioApplyForecastTemplate(scenario.scenarioID)
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <p>Business Case Tabs and Navigation</p>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Business Need Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.businessNeedTabID}
              onChange={e => {
                changeScenarioProp("businessNeedTabID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {businessNeeds.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="mb-3">
            <Label className="form-label">Solution Approach Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.solutionTabID}
              onChange={e => {
                changeScenarioProp("solutionTabID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {solutionApproaches.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="mb-3">
            <Label className="form-label">Resource Estimate Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.rBudgetTabID}
              onChange={e => {
                changeScenarioProp("rBudgetTabID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {resourceBudgets.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="mb-3">
            <Label className="form-label">Forecast Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.forecastTabID}
              onChange={e => {
                changeScenarioProp("forecastTabID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {forecasts.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="mb-3">
            <Label className="form-label">Governance Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.governanceTabID}
              onChange={e => {
                changeScenarioProp("governanceTabID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {governances.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="mb-3">
            <Label className="form-label">Project Plan Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.projectPlanTabID}
              onChange={e => {
                changeScenarioProp("projectPlanTabID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {projectPlans.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="mb-3">
            <Label className="form-label">Location Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.locationTabID}
              onChange={e => {
                changeScenarioProp("locationTabID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {locations.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="mb-3">
            <Label className="form-label">Results Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.resultsTabID}
              onChange={e => {
                changeScenarioProp("resultsTabID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {results.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Benefit Realization Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.benefitRealizationTabID}
              onChange={e => {
                changeScenarioProp("benefitRealizationTabID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {results.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="mb-3">
            <Label className="form-label">Lookbacks Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.lookbacksTabID}
              onChange={e => {
                changeScenarioProp("lookbacksTabID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {lookbacks.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
        <Col sm="6">
          {tabForms.length > 0 && (
            <div className="mb-3">
              <Label className="form-label">Business Need Form</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={scenario.businessNeedTabFormID}
                onChange={e => {
                  changeScenarioProp("businessNeedTabFormID", e.target.value)
                }}
              >
                <option value={0}>Standard</option>
                {tabForms.map((a, idx) => {
                  return (
                    <option key={idx} value={a.formId}>
                      {a.formName}
                    </option>
                  )
                })}
              </select>
            </div>
          )}

          {scenario != null && (
            <TabSectionFormInput
              scenarioId={scenario.scenarioID}
              tabSections={scenarioTabSections}
              setTabSections={setScenarioTabSections}
              forms={tabSectionForms}
              tabName={"Solution Approach"}
            />
          )}

          <div className="mb-3">
            <Label className="form-label">Resource Planning Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.resourceTabID}
              onChange={e => {
                changeScenarioProp("resourceTabID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {resourcePlans.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="mb-3">
            <Label className="form-label">Contracts Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.contractsTabID}
              onChange={e => {
                changeScenarioProp("contractsTabID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {contracts.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="mb-3">
            {scenario != null && (
              <TabSectionFormInput
                scenarioId={scenario.scenarioID}
                tabSections={scenarioTabSections}
                setTabSections={setScenarioTabSections}
                forms={tabSectionForms}
                tabName={"Governance"}
              />
            )}
          </div>

          <div className="mb-3">
            {scenario != null && (
              <TabSectionFormInput
                scenarioId={scenario.scenarioID}
                tabSections={scenarioTabSections}
                setTabSections={setScenarioTabSections}
                forms={tabSectionForms}
                tabName={"Location"}
              />
            )}
          </div>
          <div className="mb-3">
            <Label className="form-label">Documents Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.documentsTabID}
              onChange={e => {
                changeScenarioProp("documentsTabID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {documents.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="mb-3">
            {scenario != null && (
              <TabSectionFormInput
                scenarioId={scenario.scenarioID}
                tabSections={scenarioTabSections}
                setTabSections={setScenarioTabSections}
                forms={tabSectionForms}
                tabName={"Benefit Realization"}
              />
            )}
          </div>

          <div className="mb-3">
            <Label className="form-label">Scoring Results Tab</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.scoringResultsTabID}
              onChange={e => {
                changeScenarioProp(
                  "scoringResultsTabID",
                  parseInt(e.target.value)
                )
              }}
            >
              <option value={-1}>Select...</option>
              {scoringResults.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Navigation</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.navSettingID}
              onChange={e => {
                changeScenarioProp("navSettingID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {navigations.map((a, idx) => {
                return (
                  <option key={idx} value={a.listItemID}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          {/*           <div className="mb-3">
            <Label className="form-label">Change Management</Label>
            <div>
              <div className="form-check" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="chmngmnt1"
                  id="chmngmnt1"
                  value={false}
                  checked={!scenario.crEnabled}
                  onClick={e => changeScenarioProp("crEnabled", false)}
                />
                <label
                  className="form-check-label"
                  htmlFor="chmngmnt1"
                  style={{ marginLeft: "10px" }}
                >
                  Disabled
                </label>
              </div>
              <div className="form-check" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="chmngmnt2"
                  id="chmngmnt2"
                  value={true}
                  style={{ marginLeft: "20px" }}
                  checked={scenario.crEnabled}
                  onClick={e => changeScenarioProp("crEnabled", true)}
                />
                <label
                  className="form-check-label"
                  htmlFor="chmngmnt2"
                  style={{ marginLeft: "10px" }}
                >
                  Enabled
                </label>
              </div>
            </div>
          </div> */}
        </Col>
      </Row>

      <div className="divider"></div>
      <Row>
        <p>Business Case Defaults</p>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Default Start Date</Label>
            <Input
              name="defaultStart"
              type="date"
              onChange={e =>
                changeScenarioProp(
                  "defaultStart",
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }
              value={moment(scenario.defaultStart).format("YYYY-MM-DD")}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Planned Resource Start Date</Label>
            <Input
              name="plannedStartDate"
              type="date"
              onChange={e =>
                changeScenarioProp(
                  "resourceStartDT",
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }
              value={moment(scenario.resourceStartDT).format("YYYY-MM-DD")}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Starting Fiscal Year</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.fyYear}
              onChange={e => {
                changeScenarioProp("fyYear", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {fiscalYears.map((a, idx) => {
                return (
                  <option key={idx} value={`${a}`}>
                    {a}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Redline Fiscal Year</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.redlineFY}
              onChange={e => {
                changeScenarioProp("redlineFY", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {fiscalYears.map((a, idx) => {
                return (
                  <option key={idx} value={`${a}`}>
                    {a}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Default End Date</Label>
            <Input
              name="defaultFinish"
              type="date"
              onChange={e => {
                changeScenarioProp(
                  "defaultFinish",
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }}
              value={moment(scenario.defaultFinish).format("YYYY-MM-DD")}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Planned Resource Finish Date</Label>
            <Input
              name="resourceEndDT"
              type="date"
              onChange={e => {
                changeScenarioProp(
                  "resourceEndDT",
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }}
              value={moment(scenario.resourceEndDT).format("YYYY-MM-DD")}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Fiscal Year Start</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.fyMonth}
              onChange={e => {
                changeScenarioProp("fyMonth", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {months.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.value}`}>
                    {a.text}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Cost Pool Template</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={scenario.bcTemplateID}
              onChange={e => {
                changeScenarioProp("bcTemplateID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {costPoolTemplates.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={applyTemplateToExistingBusinessCases}
            >
              <i className="fas fa-save"></i>&nbsp; Apply Template to Existing
              Business Cases
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          {/*   <div className="mb-3">
            <Label className="form-label">
              Internal Staff Labor Blended Rate $
            </Label>
            <CurrencyInput
              id="intStaffRate"
              name="intStaffRate"
              className="form-control"
              value={scenario.internalStaffRate}
              onValueChange={(value, name, values) => {
                changeScenarioProp("internalStaffRate", value)
              }}
              placeholder=""
              prefix={"$"}
              decimalScale={2}
              decimalsLimit={2}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">
              External Staff Labor Blended Rate $
            </Label>
            <CurrencyInput
              id="extStaffRate"
              name="extStaffRate"
              className="form-control"
              value={scenario.externalStaffRate}
              onValueChange={(value, name, values) => {
                changeScenarioProp("externalStaffRate", value)
              }}
              placeholder=""
              prefix={"$"}
              decimalScale={2}
              decimalsLimit={2}
            />
          </div> */}
          <div className="mb-3">
            <Label className="form-label">Lookback Management</Label>
            <div>
              <div className="form-check" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="1"
                  checked={scenario.lookbackManagementID == 1}
                  onClick={e => changeScenarioProp("lookbackManagementID", 1)}
                />
                <label
                  className="form-check-label"
                  htmlFor="exampleRadios1"
                  style={{ marginLeft: "10px" }}
                >
                  Decision Manager
                </label>
              </div>
              <div className="form-check" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadio2"
                  id="exampleRadios2"
                  value="2"
                  style={{ marginLeft: "20px" }}
                  checked={scenario.lookbackManagementID == 2}
                  onClick={e => changeScenarioProp("lookbackManagementID", 2)}
                />
                <label
                  className="form-check-label"
                  htmlFor="exampleRadios2"
                  style={{ marginLeft: "10px" }}
                >
                  Team Member
                </label>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="6">
          {/* <div className="mb-3">
            <Label className="form-label">
              Internal Contractor Labor Blended Rate $
            </Label>
            <CurrencyInput
              id="intContRate"
              name="intContRate"
              className="form-control"
              value={scenario.internalContractorRate}
              onValueChange={(value, name, values) => {
                changeScenarioProp("internalContractorRate", value)
              }}
              placeholder=""
              prefix={"$"}
              decimalScale={2}
              decimalsLimit={2}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">
              External Contractor Labor Blended Rate $
            </Label>
            <CurrencyInput
              id="extContRate"
              name="extContRate"
              className="form-control"
              value={scenario.externalContractorRate}
              onValueChange={(value, name, values) => {
                changeScenarioProp("externalContractorRate", value)
              }}
              placeholder=""
              prefix={"$"}
              decimalScale={2}
              decimalsLimit={2}
            />
          </div> */}
        </Col>
      </Row>
      <div className="divider"></div>
      <Row>
        <p>Business Case Hurdle Rates</p>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Return ($)</Label>
            <CurrencyInput
              id="scenHurRet"
              name="scenHurRet"
              className="form-control"
              value={scenario.hurdleReturn}
              onValueChange={(value, name, values) => {
                console.log(value)
                changeScenarioProp("hurdleReturn", value)
              }}
              placeholder=""
              prefix={"$"}
              decimalScale={0}
              decimalsLimit={0}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">IRR (%)</Label>
            <Input
              name="scenHurIrr"
              type="number"
              onChange={e => changeScenarioProp("hurdleIRR", e.target.value)}
              value={scenario.hurdleIRR}
            />
          </div>
          <div className="mb-3">
            <Input
              type="checkbox"
              className="form-check-input"
              id="customCheckIndi"
              defaultChecked={scenario.showIndicators}
              onChange={e => {
                changeScenarioProp("showIndicators", !scenario.showIndicators)
              }}
            />
            <Label
              className="form-check-label"
              for="customCheckIndi"
              style={{ marginLeft: "10px" }}
            >
              Show Indicators
            </Label>
          </div>
        </Col>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">ROI (%)</Label>
            <Input
              name="scenHurRoi"
              type="number"
              onChange={e => changeScenarioProp("hurdleROI", e.target.value)}
              value={scenario.hurdleROI}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Payback Period (years)</Label>
            <Input
              name="hurdlePaybackPeriod"
              type="number"
              onChange={e =>
                changeScenarioProp("hurdlePaybackPeriod", e.target.value)
              }
              value={scenario.hurdlePaybackPeriod}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className="mb-3">
            <Label className="form-label">Business Case Guidance</Label>
            <Input
              name="bcGuidance"
              type="textarea"
              rows={3}
              onChange={e => changeScenarioProp("bcGuidance", e.target.value)}
              value={scenario.bcGuidance}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const TabSectionFormInput = ({
  scenarioId,
  forms,
  tabSections,
  setTabSections,
  tabName,
}) => {
  const selectedValue = tabSections.find(x => x.tabName == tabName)?.formID ?? 0

  const onChange = formId => {
    let copy = [...tabSections]
    let item = copy.find(x => x.tabName == tabName)
    if (item == undefined) {
      copy.push({
        scenarioTabSectionID: -1,
        scenarioID: scenarioId,
        formID: formId,
        tabName: tabName,
        isDirty: true,
      })
    } else {
      item.formID = formId
      item.isDirty = true
    }
    setTabSections(copy)
  }

  return (
    <div className="mb-3">
      <Label className="form-label">{tabName} Tab Section</Label>
      <select
        className="form-control form-select select2 mb-xxl-0"
        value={selectedValue}
        onChange={e => {
          onChange(e.target.value)
        }}
      >
        <option value={-1}>None</option>
        {forms.map((a, idx) => {
          return (
            <option key={idx} value={a.formId}>
              {a.formName}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default BusinessCaseSection
